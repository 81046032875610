import React, { useEffect, useState, useMemo } from "react";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const EditProduct = () => {
  const history = useHistory();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [showTopMessage, setShowTopMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [distributorProductType, setDistributorProductType] = useState([]);
  const [distributorSubProductType, setDistributorSubProductType] = useState([]);
  const [distributorUserData, setDistributorUserData] = useState([]);
  const [totalAddBrandRows, setTotalAddBrandRows] = useState([]);
  const [userBrands, setUserBrands] = useState([]);
  const [productStatus, setProductStatus] = useState(false);
  const [prodCatId, setProdCatId] = useState();
  const [distProdObject, setDistProdObject] = useState();
  const [deletedProgramBrandIds, setDeletedProgramBrandIds] = useState([]);
  const [sizeData, setSizeData] = useState([]);
  const [colorData, setColorData] = useState([]);

  const [formData, setFormData] = useState({
    productId: "",
    productName: "",
    brand: "",
    brandId: "",
    productCategory: "",
    productCategoryId: "",
    subCategory: '',
    productSubCategoryId: "",
    size: "",
    sizeId: "",
    color: "",
    colorId: "",
    mostPurchasedProduct: "",
    filePath1: "",
    filePath2: "",
    filePath3: "",
    description: "",
    userId: "1",
    isActive: 1,
    distrubutorProductObject: [{ id: '', distributorId: '', mrp: '', listingPrice: '', discountToMerchant: "", discountToDaqi: "", isDistActive: 1, distributorProductId: -1 }],
    deleteDistributorProductObject: [],
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const statusOptions = [
    { label: "Published", value: 1 },
    { label: "Not Published", value: 0 },
  ]

  const statusDistOptions = [
    { label: "Published", value: 1 },
    { label: "Not Published", value: 0 },
  ]

  const numberFields = [
  ];

  const parseToNumber = (formData) => {
    const parsedData = { ...formData };
    numberFields.forEach((field) => {
      if (parsedData[field] !== "") {
        parsedData[field] = parseFloat(parsedData[field]);
      }
    });
    return parsedData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const filteredData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== null)
    );
    const updatedDistrubutorProductObject = formData.distrubutorProductObject.map((brand) => {
      return {
        distributorId: brand.distributorId,
        mrp: brand.mrp,
        listingPrice: brand.listingPrice,
        discountToDaqi: brand.discountToDaqi,
        discountToMerchant: brand.discountToMerchant,
        isDistActive: brand.isActive,
        distributorProductId: brand.distributorProductId == -1 ? brand.distributorProductId : brand.id,
      };
    });

    const updatedFilePaths = {};
    ["filePath1", "filePath2", "filePath3"].forEach((key) => {
      if (formData[key] && formData[key].startsWith("data:image/")) {
        updatedFilePaths[key] = formData[key];
      }
    });

    const updatedFormData = {
      ...filteredData,
      ...updatedFilePaths,
      distrubutorProductObject: updatedDistrubutorProductObject,
    };


    console.log("ON Update-----", updatedFormData);
    setIsLoadingOn(true);
    const res = await postData(`electrician/updateProductCatalog`, updatedFormData);
    setIsLoadingOn(false);
    if (res.statusCode === 200) {
      if (res.data.errorObject && res.data.errorObject.length > 0) {
        const errorMessages = res.data.errorObject.map((error) => {
          if (error.type === "MappingError") {
            if (error.distributorId && error.productName) {
              const distributor = distributorUserData.find(
                (d) => d.value === parseInt(error.distributorId)
              );
              const distributorName = distributor ? distributor.label : "Unknown Distributor";
              return `Mapping already exists for Distributor: ${distributorName} (ID: ${error.distributorId}) and Product: ${error.productName} (ID: ${error.productId})`;
            } else if (error.productName && error.productCategory) {
              return `Mapping already exists for Product: ${error.productName} in Category: ${error.productCategory}`;
            }
          }

          return error.message || "An error occurred.";
        });

        Swal.fire({
          icon: "error",
          title: "Error!",
          text: errorMessages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data updated successfully!",
        });
        history.push("/ProductList");
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Something went wrong!",
      });
    }
  };

  const handleUpdateChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle the change event of dropdowns
const handleDropdownChange = (selectedOption, actionMeta) => {
  const { name } = actionMeta; // Get the name of the dropdown field (size or color)
  setFormData((prevData) => ({
    ...prevData,
    [name]: selectedOption ? selectedOption.value : null, // Update with only the value
  }));
};

  const loadFormDataForEditing = async (id) => {
    try {
      setIsLoadingOn(true);
      const programDataResponse = await getData(`electrician/getDistributorsByProduct/1?productId=${id}`);

      if (programDataResponse.statusCode === 200) {
        const prodData = programDataResponse.data.productResult[0];
        const distProdData = programDataResponse.data.distributorproductResult;
        setFormData({
          ...prodData,
          distrubutorProductObject: distProdData,
        });
        setDistProdObject(distProdData);
        setProdCatId(prodData.productCategoryId);
      } else {
        console.error("Error loading data:", programDataResponse.message);
      }
    } catch (error) {
      console.error("Error loading program data:", error);
    } finally {
      setIsLoadingOn(false);
    }
  };

  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      loadFormDataForEditing(id);
    }
  }, [id]);

  const getBrands = async () => {
    try {
      setIsLoadingOn(true);
      const tempData = await getData(`users/getBrands/1`);

      if (tempData.statusCode === 200 && tempData.data.records) {
        const tempDistData = tempData.data.records.map((element) => ({
          value: element.id,
          label: element.name,
        }));
        setUserBrands(tempDistData);
      } else {
        console.error("Error fetching User brands.");
        Swal.fire({
          title: "Error!",
          text: tempData?.data?.errors?.msg || "Something went wrong!!!",
        });
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    } finally {
      setIsLoadingOn(false);
    }
  };

  useEffect(() => {
    getBrands();
    getProductType();
    if (prodCatId) {
      getSubProductType(prodCatId)
    }
  }, [prodCatId]);

  async function getProductType() {
    try {
      setIsLoadingOn(true);
      const tempData = await getData(`users/getProductCategory/nothing`);
      setIsLoadingOn(false);
      if (tempData.statusCode === 200 && tempData.data.records) {
        const tempDistData = tempData.data.records.map(element => ({
          value: element.id,
          label: element.name,
        }));
        setDistributorProductType(tempDistData);
      } else {
        console.error("Error fetching distributor Product Type");
        Swal.fire({
          title: "Error!",
          text: tempData?.data?.errors.msg || "Something went wrong!!!"
        })
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    } finally {
      setIsLoadingOn(false);
    }
  }

  async function getSubProductType(productCategoryId) {
    try {
      setIsLoadingOn(true);
      let tempData = await getData(`users/getProductSubCategory/1?productCategoryId=${productCategoryId}`);
      setIsLoadingOn(false);
      if (tempData.statusCode === 200 && tempData.data.records) {
        const tempDistData = tempData.data.records.map(element => ({
          value: element.id,
          label: element.name,
        }));
        setDistributorSubProductType(tempDistData);
      } else {
        console.error("Error fetching distributor Sub Product Type");
        Swal.fire({
          title: "Error!",
          text: tempData?.data?.errors.msg || "Something went wrong!!!"
        });
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    } finally {
      setIsLoadingOn(false);
    }
  }

  const handleAddProductChange = (selectedOption) => {
    if (selectedOption) {
      const selectedProductCategoryId = selectedOption.value;

      setFormData((prevFormData) => ({
        ...prevFormData,
        productCategory: selectedOption.label,
        productCategoryId: selectedOption.value,
        subCategory: "",
        productSubCategoryId: "",
      }));

      setDistributorSubProductType([]);
      getSubProductType(selectedProductCategoryId);
    } else {
      setDistributorSubProductType([]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        productCategory: "",
        subCategory: "",
        productSubCategoryId: "",
      }));
    }
  };

  const handleAddSubProductChange = (selectedOption) => {
    if (selectedOption) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        subCategory: selectedOption.label,
        productSubCategoryId: selectedOption.value,
      }));
    }
  };

  const handleAddBrandChange = (selectedOption) => {
    if (selectedOption) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        brand: selectedOption.label,
        brandId: selectedOption.value,
      }));
    }
  };

  const handleAddSizeChange = (selectedOption) => {
    if (selectedOption) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        size: selectedOption.label,
        sizeId: selectedOption.value,
      }));
    }
  };

  const handleAddColorChange = (selectedOption) => {
    if (selectedOption) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        color: selectedOption.label,
        colorId: selectedOption.value,
      }));
    }
  };


  const handleStatusChange = (selectedOption) => {
    if (selectedOption) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        isActive: selectedOption.value,
      }));
    }
  };

  async function DistributorGetData() {
    setIsLoadingOn(true);
    try {
      const tempData = await getData("users/distributorUser/1", {});
      setIsLoadingOn(false);

      if (tempData.statusCode === 200) {
        const tempDistData = tempData.data.records.map((element) => ({
          value: element.id,
          label: element.name
        }));
        setDistributorUserData(tempDistData);
      } else {
        Swal.fire({
          title: "Error!",
          text: tempData?.data?.errors?.msg || "Something went wrong!"
        });
      }
    } catch (error) {
      setIsLoadingOn(false);
      Swal.fire({
        title: "Error!",
        text: "Failed to fetch distributor data."
      });
    }
  }

  async function SizeGetData() {
      setIsLoadingOn(true);
      const tempData = await getData("sizeMaster")
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        const tempDistData = tempData?.data?.map((element) => {
          return { value: element.id, label: element.name };
        });
        setSizeData(tempDistData);
      }
      else {
        Swal.fire({
          title: "Error!",
          text: tempData?.data?.errors.msg || "Something went wrong!!!"
        })
      }
    }
  
    async function ColorGetData() {
      setIsLoadingOn(true);
      const tempData = await getData("colorMaster")
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        const tempDistData = tempData?.data?.map((element) => {
          return { value: element.id, label: element.name };
        });
        setColorData(tempDistData);
      }
      else {
        Swal.fire({
          title: "Error!",
          text: tempData?.data?.errors.msg || "Something went wrong!!!"
        })
      }
    }

  useEffect(() => {
    DistributorGetData();
    SizeGetData();
    ColorGetData();
  }, []);

  const handleFileInputChange = (event, key) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          [key]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = (key) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: "",
    }));
  };

  const handleDistributorProductStatusChange = (brandId, selectedOption, key) => {
    if (totalAddBrandRows.some(row => row.id === brandId)) {
      setTotalAddBrandRows(prevRows =>
        prevRows.map(row =>
          row.id === brandId ? { ...row, selectedOption } : row
        )
      );
    } else {
      const newRow = { id: totalAddBrandRows.length + 1, selectedOption };
      setTotalAddBrandRows(prevRows => [...prevRows, newRow]);
    }

    setFormData((prevState) => ({
      ...prevState,
      [key]: prevState[key].map((row) => {
        if (row.id === brandId) {
          return { ...row, isActive: selectedOption.value };
        }
        return row;
      }),
    }));
  };

  const handleUpdateRowChange = (event, rowIndex, arrayName) => {
    const { name, value } = event.target;
    const numericValue = value.match(/^-?\d*\.?\d*$/);
    setFormData(prevState => {
      const updatedArray = [...prevState[arrayName]];
      updatedArray[rowIndex] = {
        ...updatedArray[rowIndex],
        [name]: value
      };
      return {
        ...prevState,
        [arrayName]: updatedArray
      };
    });
  };

  const handleDeleteBrandRow = (brand) => {
    const rowId = brand.id; // Extract the row ID
    setFormData((prevState) => ({
      ...prevState,
      deleteDistributorProductObject: [
        ...(prevState.deleteDistributorProductObject || []),
        rowId,
      ],
      distrubutorProductObject: prevState.distrubutorProductObject.filter(
        (row) => row.id !== rowId
      ),
    }));

    setTotalAddBrandRows((prevRows) => prevRows.filter((row) => row.id !== rowId));
  };

  const addBrandAddRow = () => {
    const newBrandRow = { id: totalAddBrandRows.length + 1 };
    setTotalAddBrandRows([...totalAddBrandRows, newBrandRow]);
    setFormData(prevState => ({
      ...prevState,
      distrubutorProductObject: [
        ...prevState.distrubutorProductObject,
        { distributorId: '', mrp: '', listingPrice: '',  discountToDaqi: '', discountToMerchant: '', isDistActive: 1 }
      ]
    }));
  };

  console.log("formData ===", formData);
  return (
    <>
      {isLoadingOn && <Loader />}
      <div className="productData">
        <h4 className="mb-3"><b>{isEditMode ? "Update Data" : "Add Product"}</b></h4>
        <form onSubmit={handleSubmit} className="formsec">
          <div className="row">
            <h5>Product Details</h5>
            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="productName" className="form-label">
                  Product Name<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Product Name"
                  className="form-control"
                  id="productName"
                  name="productName"
                  value={formData.productName}
                  onChange={handleUpdateChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="creditLimit" className="form-label">
                  Brand<span>*</span>
                </label>
                <Select
                  placeholder="Select"
                  name="brand"
                  options={userBrands}
                  value={userBrands.find((brand) => brand.label === formData.brand) || null}
                  onChange={handleAddBrandChange}
                  required
                />
                {errors.brand && <div className="text-danger">{errors.brand}</div>}
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="creditLimit" className="form-label">
                  Product Category<span>*</span>
                </label>
                <Select
                  placeholder="Select"
                  name="productCategory"
                  options={distributorProductType}
                  value={distributorProductType.find((productCategory) => productCategory.value === formData.productCategoryId) || null}
                  onChange={handleAddProductChange}
                  required
                />
                {errors.productCategory && <div className="text-danger">{errors.productCategory}</div>}
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="creditLimit" className="form-label">
                  Product Sub Category
                </label>
                <Select
                  placeholder="Select"
                  name="subCategory"
                  options={distributorSubProductType}
                  isDisabled={!distributorSubProductType || distributorSubProductType.length === 0}
                  value={distributorSubProductType.find((productCategory) => productCategory.value === Number(formData.productSubCategoryId)) || null}
                  onChange={handleAddSubProductChange}
                />  
                {errors.subCategory && <div className="text-danger">{errors.subCategory}</div>}
              </div>
            </div>
          </div>

          <div className="row">
          <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="size" className="form-label">
                  Size
                </label>
                <Select
              placeholder="Select"
              name="size" // Make sure the name corresponds to the state key
              options={sizeData}
              value={sizeData.find((brand) => brand.label === formData.size) || null}
              onChange={handleAddSizeChange} // Handle change event
            />
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="color" className="form-label">
                  Color
                </label>
                <Select
              placeholder="Select"
              name="color" // Make sure the name corresponds to the state key
              options={colorData}
              value={colorData.find(option => option.label === formData.color) || null}
              onChange={handleAddColorChange} // Handle change event
            />
              </div>
            </div>

            <div className="col-md-6 changeProductPro">
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter description"
                  className="form-control"
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleUpdateChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="row">
            {["filePath1", "filePath2", "filePath3"].map((key, index) => (
              <div className="col-md-3 changeProductPro" key={key}>
                <div className="mb-3">
                  <label htmlFor={key} className="form-label">
                    Image {index + 1}
                  </label>
                  {formData[key] ? (
                    <div className="image-preview-wrapper" style={{ position: "relative" }}>
                      <img
                        src={formData[key]}
                        alt={`Preview ${index + 1}`}
                        className="img-thumbnail mb-2"
                        style={{ maxHeight: "150px", cursor: "pointer" }}
                        onClick={() => window.open(formData[key], "_blank")}
                      />
                      <button
                        type="button"
                        className="btn btn-danger btn-sm rounded-circle"
                        style={{ position: "absolute", top: "10px", right: "10px" }}
                        onClick={() => handleDeleteImage(key)}
                      >
                        &times;
                      </button>
                    </div>
                  ) : (
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      id={key}
                      name={key}
                      onChange={(event) => handleFileInputChange(event, key)}
                      aria-describedby="textHelp"
                    />
                  )}
                </div>
              </div>
            ))}

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="isActive" className="form-label">
                  Product status<span>*</span>
                </label>
                <Select
                  placeholder="Select"
                  defaultValue={{ label: "Published", value: 1 }}
                  name="isActive"
                  options={statusOptions}
                  value={
                    statusOptions.find(
                      (status) => status.value === formData.isActive
                    ) || { label: "Published", value: 1 }
                  }
                  onChange={handleStatusChange}
                />
                {errors.isActive && <div className="text-danger">{errors.isActive}</div>}
              </div>
            </div>
          </div>

          {/* ------------------------------------- Add Brand ------------------------------------ */}
          <>
            <hr className="orgbrd" />
            <h5 className="mt-3">Add Distributors</h5>
            {formData.distrubutorProductObject.map((brand, index) => {
              return (
                <div key={brand.id} className="row align-items-center">
                  <div className="col-md-2 changeProductPro">
                    <div className="mb-3">
                      <label htmlFor={`distributorId-${brand.id}`} className="form-label">
                        Distributors
                      </label>
                      <Select
                        placeholder="Select"
                        name={`distributorId-${brand.id}`}
                        options={distributorUserData}
                        value={
                          distributorUserData.find(
                            (option) => option.value == brand.distributorId
                          ) || null
                        }
                        onChange={(selectedOption) =>
                          setFormData((prevState) => ({
                            ...prevState,
                            distrubutorProductObject: prevState.distrubutorProductObject.map((row) =>
                              row.id === brand.id
                                ? { ...row, distributorId: selectedOption.value }
                                : row
                            ),
                          }))
                        }
                      />
                      {errors.distributorId && (
                        <div className="text-danger">{errors.distributorId}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-1 changeProductPro">
                    <div className="mb-3">
                      <label htmlFor="mrp" className="form-label">MRP</label>
                      <input
                        type="number"
                        step="any"
                        placeholder="Enter MRP"
                        className="form-control"
                        id="mrp"
                        name="mrp"
                        value={brand.mrp || ''}
                        onChange={(event) => handleUpdateRowChange(event, index, "distrubutorProductObject")}
                        aria-describedby="textHelp"
                      />
                      {errors.mrp && (
                        <div className="text-danger">{errors.mrp}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-1 changeProductPro">
                    <div className="mb-3">
                      <label htmlFor="listingPrice" className="form-label">Listing Price</label>
                      <input
                        type="number"
                        step="any"
                        placeholder="Enter Listing Price"
                        className="form-control"
                        id="listingPrice"
                        name="listingPrice"
                        value={brand.listingPrice || ''}
                        onChange={(event) => handleUpdateRowChange(event, index, "distrubutorProductObject")}
                        aria-describedby="textHelp"
                      />
                      {errors.listingPrice && (
                        <div className="text-danger">{errors.listingPrice}</div>
                      )}
                    </div>
                  </div>

                  {/* Discount to Daqi */}
                  <div className="col-md-2 changeProductPro">
                    <div className="mb-3">
                      <label htmlFor="discountToDaqi" className="form-label">Discount To Daqi %</label>
                      <input
                        type="number"
                        step="any"
                        placeholder="Enter Listing Price"
                        className="form-control"
                        id="discountToDaqi"
                        name="discountToDaqi"
                        value={brand.discountToDaqi || ''}
                        onChange={(event) => handleUpdateRowChange(event, index, "distrubutorProductObject")}
                        aria-describedby="textHelp"
                      />
                      {errors.discountToDaqi && (
                        <div className="text-danger">{errors.discountToDaqi}</div>
                      )}
                    </div>
                  </div>

                  {/* Discount to Merchant */}
                  <div className="col-md-2 changeProductPro">
                    <div className="mb-3">
                      <label htmlFor="discountToMerchant" className="form-label">Discount To Merchant %</label>
                      <input
                        type="number"
                        step="any"
                        placeholder="Enter Discount To Merchant"
                        className="form-control"
                        id="discountToMerchant"
                        name="discountToMerchant"
                        value={brand.discountToMerchant || ''}
                        onChange={(event) => handleUpdateRowChange(event, index, "distrubutorProductObject")}
                        aria-describedby="textHelp"
                      />
                      {errors.discountToMerchant && (
                        <div className="text-danger">{errors.discountToMerchant}</div>
                      )}
                    </div>
                  </div>

                  {/* Distributor Product Status */}
                  <div className="col-md-2 changeProductPro">
                    <div className="mb-3">
                      <label htmlFor={`isDistActive-${brand.id}`} className="form-label">
                        Distributor Product Status
                      </label>
                      <Select
                        placeholder="Select"
                        name={`isDistActive-${brand.id}`}
                        options={statusDistOptions}
                        value={
                          statusDistOptions.find((option) => option.value == brand.isActive) || null
                        }
                        onChange={(selectedOption) =>
                          handleDistributorProductStatusChange(
                            brand.id,
                            selectedOption,
                            'distrubutorProductObject'
                          )
                        }
                      />
                      {errors.isDistActive && (
                        <div className="text-danger">{errors.isDistActive}</div>
                      )}
                    </div>
                  </div>


                  {/* Add/Delete Buttons */}
                  <div className="col-md-2 changeProductPro">
                    {index === 0 ? (
                      <div className="col-md-2">
                        <div
                          className="proPlusIconGreen"
                          onClick={() => {
                            const newRowId = totalAddBrandRows.length + 1; // Generate a new ID
                            const newBrandRow = {
                              id: newRowId,
                              distributorId: "",
                              programId: "",
                              mrp: "",
                              listingPrice: "",
                              isActive: 1,
                              distributorProductId: -1, // Use -1 for unsaved rows
                            };

                            // Add the new row to both state variables
                            setTotalAddBrandRows((prevRows) => [...prevRows, newBrandRow]);
                            setFormData((prevState) => ({
                              ...prevState,
                              distrubutorProductObject: [
                                ...prevState.distrubutorProductObject,
                                newBrandRow,
                              ],
                            }));
                          }}
                        >
                          <FaPlusCircle />
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-2">
                        <div
                          className="deletedIcon"
                          onClick={() => handleDeleteBrandRow(brand)}
                        >
                          <MdDelete />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </>

          {/* ------------------------------------------------------------------------------------------- */}

          <div className="text-left">
            <button type="submit" className="btn btn-success">
              {isEditMode ? "Update" : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );

};

export default EditProduct;