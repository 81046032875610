import React, { useEffect, useState, useRef, useMemo } from "react";
import Loader from "../component/Loader";
import OrderDetails from "./Order/OrderDetails.js";
import OrderInfo from "./Order/OrderInfo.js";

function OrderDetailsPage() {
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [activeTab, setActiveTab] = useState("orderDetails");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };


  return (
    <div className="noMarginSection" style={{ marginTop: 0 }}>
      {isLoadingOn ? <Loader /> : ""}

      <div className="container-fluid">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "orderDetails" ? "active" : ""}`}
              id="orderDetails-tab"
              data-bs-toggle="tab"
              data-bs-target="#orderDetails"
              type="button"
              role="tab"
              aria-controls="orderDetails"
              aria-selected={activeTab === "orderDetails"}
              onClick={() => handleTabClick("orderDetails")}
            >
              Order Details
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "orderInfo" ? "active" : ""}`}
              id="orderInfo-tab"
              data-bs-toggle="tab"
              data-bs-target="#orderInfo"
              type="button"
              role="tab"
              aria-controls="orderInfo"
              aria-selected={activeTab === "orderInfo"}
              onClick={() => handleTabClick("orderInfo")}
            >
              Shipment Info
            </button>
          </li>
        </ul>
        <div>
          <p></p>
        </div>
        <div className="tab-content" id="myTabContent">
          {activeTab === "orderDetails" && (
            <div className="tab-pane fade show active" id="orderDetails" role="tabpanel" aria-labelledby="orderDetails-tab">
              <OrderDetails />
            </div>
          )}
          {activeTab === "orderInfo" && (
            <div className="tab-pane fade show active" id="orderInfo" role="tabpanel" aria-labelledby="orderInfo-tab">
              <OrderInfo />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderDetailsPage;
