import React from 'react';
import { useEffect, useState } from 'react'
import { getData, postData } from '../../store/utils.js';
import Swal from 'sweetalert2';
import axios from 'axios';
import { BarChart } from '@mui/x-charts/BarChart';
import Loader from '../../component/Loader.js';

function Evaluation() {
  const [tableData, setTableData] = useState([])
  const [labels, setLabels] = useState([])
  const [BRData, setBRData] = useState([])
  const [CCData, setCCData] = useState([])
  const [SDData, setSDData] = useState([])
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [creditScore, setCreditScore] = useState('')
  const [riskClassification, setriskClassification] = useState('')
  const [totalRiskPoints, settotalRiskPoints] = useState('')
  const [totalEstimatedIncome, setTotalEstimatedIncome] = useState('')
  const [limitAssigned, setLimitAssigned] = useState('')
  const [incomeLogic, setIncomeLogic] = useState('')
  const [limitLogic, setLimitLogic] = useState('')
  const [gstestimatedIncome, setEstestimatedIncome] = useState('')
  const [digidIncome, setdigidIncome] = useState('')
  const [bureauestimatedIncome, setBureauestimatedIncome] = useState('')

  const [bureauIncomeDeatils, setBureauIncomeDeatils] = useState([])
  const [digiIncomeDeatils, setDigiIncomeDeatils] = useState([])
  const [limitlogicDetails, setLimitlogicDetails] = useState([])
  const [gstIncomeDetails, setGstIncomeDetails] = useState('')
  const [negativeIndicators, setNegativeIndicators] = useState([])
  const [possitiveIndicators, setPossitiveIndicators] = useState([])
  const [eligibilityCriteria, seteligibilityCriteria] = useState([])
  const [evaluationMsg, setEvaluationMsg] = useState('')
  const urlSearchParams = new URLSearchParams(window.location.search);
  const userId = urlSearchParams.get("userId");

  var tempData;
  var tempDatanew;

  const bureaCall = async () => {
    // setIsLoadingOn(true);
    // tempData = await getData(`bureau/evaluation/${userId}`);
    tempData = await getData(`bureau/getEvaluationCredit/${userId}`);
    // tempDatanew = await getData(`bureau/bureauEvaluationNew/${userId}`);
    // setIsLoadingOn(false);
    fetchBureaEvaluation();
    // fetchBureaEvaluationNew();

  }

  const handleRefreshEvaluation = async () => {
    let filterData = {
      userId : userId
    }
    setIsLoadingOn(true);
    tempData = await postData(`bureau/evaluationCredit`, filterData);
    setIsLoadingOn(false);
    // fetchBureaEvaluation();
    bureaCall();
  }

  const fetchBureaEvaluation = () => {
    if (tempData?.data == "Consumer not found in bureau") {
      setEvaluationMsg(tempData?.data || []);
    } else if (tempData?.statusCode == 200 && tempData?.data != "Consumer not found in bureau") {
      setTableData(tempData?.data || []);
      const loanLabel = tempData?.data?.data?.map((value, ind) => {
        return value?.loanName
      })
      setLabels(loanLabel)
      setBRData(tempData?.data?.data?.map(item => item?.details?.badRepayment));
      setCCData(tempData?.data?.data?.map(item => item?.details?.completelyClean));
      setSDData(tempData?.data?.data?.map(item => item?.details?.smallDelinquencies));
      
      setCreditScore(tempData?.data?.fullData?.credit_score)
      setriskClassification(tempData?.data?.fullData?.risk_classification)
      settotalRiskPoints(tempData?.data?.fullData?.total_risk_points)
      setNegativeIndicators(tempData.data?.negative_indicators)
      setPossitiveIndicators(tempData?.data?.fullData?.positive_indicators)
      seteligibilityCriteria(tempData?.data?.fullData?.eligibility_criteria_evaluation)
      setLimitAssigned(tempData?.data?.fullData?.limit_assigned)
      setTotalEstimatedIncome(tempData?.data?.fullData?.total_estimated_income)
      setBureauestimatedIncome(tempData?.data?.fullData?.bureau_estimated_income)
      setdigidIncome(tempData?.data?.fullData?.digi_estimated_income)
      setBureauIncomeDeatils(tempData?.data?.fullData?.bureau_income_logic_details?.map(item => item));
      setDigiIncomeDeatils(tempData?.data?.fullData?.digi_income_logic_details?.map(item => item));
      setGstIncomeDetails(tempData?.data?.fullData?.gst_income_details?.map(item => item))
      
      const limitLogicData = tempData?.data?.fullData?.limit_logic;
      if (Array.isArray(limitLogicData)) {
          setLimitlogicDetails(limitLogicData.map(item => item));
      } else if (typeof limitLogicData === "string") {
          setLimitlogicDetails([limitLogicData]); // Convert string to array
      } else {
          console.error("Unexpected format for limit_logic:", limitLogicData);
          setLimitlogicDetails([]); // Fallback to an empty array
      }
    }
    // else if(tempData?.response?.data?.statusCode == 400 && tempData?.response?.data?.errors.msg == "Consumer not found in bureau"){
    //   setEvaluationMsg("No Data Found")
    // }
    else {
      Swal.fire({
        title: "Error!",
        text: tempData?.response?.data?.errors.msg || "Something went wrong!!!"
      })
    }
  }

  const fetchBureaEvaluationNew = () => {
    if (tempDatanew?.data == "Consumer not found in bureau") {
      setEvaluationMsg(tempDatanew?.data || []);
    } else if (tempDatanew?.statusCode == 200 && tempDatanew?.data != "Consumer not found in bureau") {
      setTotalEstimatedIncome(tempDatanew?.fullData?.total_estimated_income)
      setLimitAssigned(tempDatanew?.fullData?.limit_assigned)
      setIncomeLogic(tempDatanew?.fullData?.income_logic)
      setLimitLogic(tempDatanew?.fullData?.limit_logic)
      setEstestimatedIncome(tempDatanew?.fullData?.gst_estimated_income)
      setBureauestimatedIncome(tempDatanew?.fullData?.bureau_estimated_income)
      setdigidIncome(tempDatanew?.fullData?.digi_estimated_income)
      setBureauIncomeDeatils(tempDatanew?.fullData?.bureau_income_logic_details?.map(item => item));
      setDigiIncomeDeatils(tempDatanew?.fullData?.digi_income_logic_details?.map(item => item));
      setGstIncomeDetails(tempDatanew?.fullData?.gst_income_details?.map(item => item))
      setLimitlogicDetails(tempDatanew?.fullData?.limit_logic?.map(item => item));
    }
    else {
      Swal.fire({
        title: "Error!",
        text: tempData?.response?.data?.errors.msg || "Something went wrong!!!"
      })
    }
  }

  useEffect(() => {
    bureaCall();
  }, [])

  return (
    <div className='evaluationSec mt-5'>
    {isLoadingOn ? <Loader /> : ""}
      <div className=''>
        {
          evaluationMsg ? (
            <div className='row'>
              <div className='col-sm-12'>
                <p className=""
                  style={{
                    lineHeight: 1.8,
                    fontSize: 16,
                    color: "red",
                    fontWeight: 600,
                  }}
                ><san>*</san> {evaluationMsg}</p>
              </div>
            </div>
          )
            : (
              <>
                <div className='row'>
                  <div className='col-sm-2'>
                    <div className='card p-2 text-center'>
                      <h3>{creditScore ? creditScore : "0"}</h3>
                      <h5>Credit score</h5>
                    </div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='card p-2 text-center'>
                      <h3>{totalRiskPoints ? totalRiskPoints : 0}</h3>
                      <h5>Total Risk Points</h5>
                    </div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='card p-2 text-center'>
                      <h3>{riskClassification ? riskClassification : "-"}</h3>
                      <h5>Risk Classification</h5>
                    </div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='card p-2 text-center'>
                      <h3>{totalEstimatedIncome && Number(totalEstimatedIncome?.toFixed(2)) ? totalEstimatedIncome && Number(totalEstimatedIncome?.toFixed(2)) : "0"}</h3>
                      <h5>Total Estimated Income</h5>
                    </div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='card p-2 text-center'>
                      <h3>{limitAssigned ? limitAssigned : "0"}</h3>
                      <h5>Limit Assigned</h5>
                    </div>
                  </div>
                  <div className='col-sm-2 text-end'>
                    <button
                      onClick={handleRefreshEvaluation}
                      className="btn btn-success"
                    >
                      Refresh Data
                    </button>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col'>
                    {/* <div className='loantypeStatistics bankTableDetailsData'>
                  <h5>Repayment Count</h5>
                  {
                    tableData?.length > 0 ?
                      <div className='loantypeStatistics bankTableDetailsData'>
                        <table cellPadding={0} cellSpacing={0}>
                          <thead>
                            <tr>
                              <th>Sr. No</th>
                              <th>Loan Type</th>
                              <th>Bad Repayment</th>
                              <th>Completely Clean</th>
                              <th>Small Delinquencies</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.map((val, ind) => {
                              return <tr key={ind}>
                                <td>{ind + 1}</td>
                                <td>{val?.loanName}</td>
                                <td>{val?.details?.badRepayment}</td>
                                <td>{val?.details?.completelyClean}</td>
                                <td>{val?.details?.smallDelinquencies}</td>
                              </tr>
                            })}

                          </tbody>
                        </table>
                      </div>
                      : <div className='repaymentCount p-3'>No data available</div>
                  }
                </div> */}
                    <div className='loantypeStatistics bankTableDetailsData mt-4'>
                      <h5>Eligibility Criteria</h5>
                      {
                        eligibilityCriteria?.length > 0 ?
                          <div className='eligibilityTable'>
                            <table cellPadding={0} cellSpacing={0}>
                              <thead>
                                <tr>
                                  <th>Sr. No</th>
                                  <th>Criteria</th>
                                  <th>Result</th>
                                  <th>Value</th>
                                  <th>Treshold</th>
                                </tr>
                              </thead>
                              <tbody>
                                {eligibilityCriteria?.map((val, ind) => {
                                  return <tr key={ind}>
                                    <td>{ind + 1}</td>
                                    <td>{val?.Criterion}</td>
                                    <td>{val?.Result}</td>
                                    <td>{val?.Value}</td>
                                    <td>{val?.Threshold}</td>
                                  </tr>
                                })}

                              </tbody>
                            </table>
                          </div>
                          : <div className='repaymentCount p-3'>No data available</div>
                      }
                    </div>
                  </div>
                  <div className='col mrtop'>

                    <BarChart
                      style={{ width: "100%", height: "380px", border: "1px solid #ccc" }}
                      series={[
                        { data: BRData || [], label: 'Bad Repayment', id: 'brId', color: '#ea5455' },
                        { data: CCData || [], label: 'Completely Clean', id: 'ccId', color: '#16a34a' },
                        { data: SDData || [], label: 'Small Delinquencies', id: 'sdId', color: '#ff9f43' },
                      ]}
                      xAxis={[
                        {
                          scaleType: 'band',
                          data: labels || [],
                          categoryGapRatio: 0.2,
                          barGapRatio: 0.2,
                        }]}
                      // legend={{
                      //   position: 'top-right',
                      //   labelStyle: { fontSize: 12, color: '#333', marginRight: "20px" },
                      // }}
                    />
                  </div>
                </div>
                <div className='row mt-5'>
                  <div className='col-sm-6'>
                    <h5>Positive Indicators</h5>
                    <div className='indicators border p-3'>
                      <div>
                        {possitiveIndicators?.length > 0 ?
                          <ul>
                            {possitiveIndicators?.map((val, index) => (
                              <li className='mt-3' key={index}>{val}</li>
                            ))}
                          </ul>
                          :
                          <div>No data available</div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <h5>Negative Indicators</h5>
                    <div className='indicators border p-3'>
                      <div>
                        {negativeIndicators?.length > 0 ?
                          <ul>
                            {negativeIndicators?.map((val, index) => (
                              <li className='mb-3' key={index}>{val}</li>
                            ))}
                          </ul>
                          :
                          <div>No data available</div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-sm-3'> 
                    <div className='indicators border p-3'>
                      <h6>Bureau Estimated Income - {bureauestimatedIncome}</h6>
                      <div>
                        {bureauIncomeDeatils?.length > 0 ?
                          <ul>
                            {bureauIncomeDeatils?.map((val, index) => (
                              <li className='mb-3' key={index}>{val}</li>
                            ))}
                          </ul>
                          :
                          <div>No data available</div>
                        }
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-3'> 
                    <div className='indicators border p-3'>
                      <h6>Digi Estimated Income - {digidIncome}</h6>
                      <div>
                        {digiIncomeDeatils?.length > 0 ?
                          <ul>
                            {digiIncomeDeatils?.map((val, index) => (
                              <li className='mb-3' key={index}>{val}</li>
                            ))}
                          </ul>
                          :
                          <div>No data available</div>
                        }
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-3'> 
                    <div className='indicators border p-3'>
                      <h6>Limit Assigned - {limitAssigned}</h6>
                      <div>
                        {limitlogicDetails?.length > 0 ?
                          <ul>
                            {limitlogicDetails?.map((val, index) => (
                              <li className='mb-3' key={index}>{val}</li>
                            ))}
                          </ul>
                          :
                          <div>No data available</div>
                        }
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-3'> 
                    <div className='indicators border p-3'>
                      <h6>GST Estimated Income - {gstestimatedIncome && Number(gstestimatedIncome?.toFixed(2))}</h6>
                      <div>
                        {gstIncomeDetails?.length > 0 ?
                          <ul>
                            {gstIncomeDetails?.map((val, index) => (
                              <li className='mb-3' key={index}>{val}</li>
                            ))}
                          </ul>
                          :
                          <div>No data available</div>
                        }
                      </div>
                    </div>
                  </div>
                  {/* <ul>
                      <li className='mt-2 d-flex'>
                        <div>GST Estimated Income - {gstestimatedIncome}</div> <div className='ms-5'>Bureau Estimated Income - {bureauestimatedIncome}</div></li>
                      <li>{incomeLogic} </li>
                      <li className='mt-2'>{limitLogic} </li>
                    </ul> */}
                </div>
              </>
            )
        }

      </div>

    </div>
  )
}

export default Evaluation