import React, { useEffect, useState, useRef, useMemo } from "react";
import { FaFileCsv } from "react-icons/fa6";
import "../css/makeTable.css";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";

import TopShowMessage from "../component/TopShowMessage";
import Modal from "react-modal";
import {
  FaRegTimesCircle,
  FaRegEye,
  FaSave,
  FaRegFilePdf,
} from "react-icons/fa";
import Swal from "sweetalert2";
import { 
  formatDateString, 
  formatDateTimeSecond,
  formatNumberWithCommasAndDecimals 
} from "../component/common.js";

const customStylesDistributor = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    minWidth: "40%",
    padding: 20,
  },
};

const customStylesDisbursement = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    minWidth: "25%",
    padding: 20,
  },
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
  },
  control: (provided) => ({
    ...provided,
    border: "none", // Remove border
    boxShadow: "none", // Remove the box shadow
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none", // Remove the dropdown arrow
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none", // Remove the separator line
  }),
  menu: (provided, state) => ({
    ...provided,
    width: 150, // Adjust as needed
  }),
};

function FundInvoiceDetails(props) {
  const userRole = localStorage.getItem("USER_ROLE");
  const searchParams = new URLSearchParams(window.location.search);
  const referenceId = searchParams.get("referenceId");
  const invoiceId = searchParams.get("invoiceId");
  const distributorId = searchParams.get("distributorId");
  const [merchantUserData, setMerchantUserData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalIsDisbursement, setModalIsDisbursement] = useState(false);
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [invoiceDetailsData, setInvoiceDetailsData] = useState();
  const [invoiceDistributorData, setInvoiceDistributorData] = useState([]);
  const [disbursementInvoiceId, setDisbursementInvoiceId] = useState();
  const [showTopMessage, setShowTopMessage] = useState(false);
  const [disbursedDate, setDisbursedDate] = useState();
  const [modalIsDisbursementApproval, setModalIsDisbursementApproval] = React.useState(false);
  const [disbursementApproval, setDisbursementApproval] = useState();
  const [approvalStatus, setApprovalStatus] = useState("Pending");
  const [invoiceUserData,setInvoiceUserData] = useState();
  const [disbusApproval, setDisbusApproval] = useState([]);
  const [formDistributorData, setFormDistributorData] = useState({
    invoiceNumber: "",
    invoiceAmount: "",
    invoiceDate: "",
    invoiceFile: "",
    invoiceFileBase64: "",
  });
  const [formDisbursementData, setFormDisbursementData] = useState({
    disbursedDate: "",
    utr: "",
    disbursementAmount: "",
    disbursementInvoiceId: "",
    disbursementDistributor: "",
    toDistributorDisbursementAmount: "",
    totalDisbursementAmount: "",
    lenderName: "",
  });
  const [agreementUpdateModal, setAgreementUpdateModal] = useState(false);
  const [agreementFileBase64, setAgreementFileBase64] = useState("");

  //   console.log("formDisbursementData+++++", formDisbursementData);

  const handleChange = (e) => {
    const { name, files } = e.target;
    if (name === "invoiceFile" && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result.replace(
          "data:application/pdf;base64,",
          ""
        );
        if (base64String) {
          getFileInvoiceData(base64String);
        }
        setFormDistributorData({
          ...formDistributorData,
          invoiceFileBase64: base64String,
        });
      };
    } else {
      setFormDistributorData({
        ...formDistributorData,
        [name]: e.target.value,
      });
    }
  };

  const handleDisbursedChange = (e) => {
    const { name } = e.target;
    setFormDisbursementData({
      ...formDisbursementData,
      [name]: e.target.value,
    });
  };

  const handleUpdateDisbursedInvoice = async (e) => {
    e.preventDefault();
    if (formDisbursementData) {
      let tempDataSend = {
        invoiceId: disbursementInvoiceId,
        utr: formDisbursementData.utr,
        disbursedDate: formDisbursementData.disbursedDate,
        tableReference: "MERCHANT",
      };
      setIsLoadingOn(true);
      const tempData = await postData(`loan/setDisbursement`, tempDataSend);
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        getDetailsDataDistributor(invoiceId);
        setModalIsDisbursement(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data Updated successfully!",
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formDistributorData) {
      let tempDataSend = {
        userId: referenceId,
        referenceId: referenceId,
        referenceTable: "MERCHANT",
        distributorId: invoiceDetailsData.distributorId,
        invoiceAmount: formDistributorData.invoiceAmount,
        invoiceDate: formDistributorData.invoiceDate,
        invoiceId: invoiceId,
        invoiceNumber: formDistributorData.invoiceNumber,
        file: formDistributorData.invoiceFileBase64,
      };
      setIsLoadingOn(true);
      const tempData = await postData(
        `documents/insertInvoiceWithoutExtraction`,
        tempDataSend
      );
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        setActionModalOpen(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data Updated successfully!",
        });
        getDetailsDataDistributor(invoiceId);
      }
      else{
        Swal.fire({
          icon: "error",
          title: "Error!",
          text:  tempData?.data?.errors?.msg|| "Somthing went wrong!",
        });
      }
    }
  };

  const closeModal = () => {
    setActionModalOpen(false);
    setModalIsDisbursement(false);
    setDisbursementApproval(false);
    setModalIsDisbursementApproval(false);
    setAgreementUpdateModal(false);
  };

  const handleOpenModalDetails = (item) => {
    if (item) {
      setFormDistributorData({
        invoiceNumber: item ? item.invoiceNumber : "",
        invoiceAmount: item ? item.invoiceAmount : "",
        invoiceDate: item ? item.invoiceDate : "",
        invoiceFile: "",
        invoiceFileBase64: "",
      });
      setInvoiceDetailsData(item);
    }
    setActionModalOpen(true);
  };

  const handleShowDisbursementData = (item) => {
    setFormDisbursementData({
      disbursementDistributor: item ? item.distributorName : "",
      disbursementInvoiceId: item ? item.invoiceId : "",
      disbursementAmount: item ? item.invoiceAmount : "",
      toDistributorDisbursementAmount: item
        ? item.toDistributorDisbursementAmount
        : "",
      totalDisbursementAmount: item ? item.totalDisbursementAmount : "",
      lenderName: item ? item.lenderName : "",
    });
    setModalIsDisbursement(true);
  };

  const getDetailsDataDistributor = async (invoiceId) => {
    setIsLoadingOn(true);
    const res = await getData(
      `distributor/getOrdersDistributors/params?referenceTable=MERCHANT&invoiceId=${invoiceId}`
    );
    setIsLoadingOn(false);
    if (res.statusCode === 200) {
      setInvoiceDistributorData(res.data);
      setInvoiceUserData(res.data[0].invoicePath);
      setDisbursedDate(res.data[0].disbursedDate);
      setDisbusApproval(res.data[0].isDisbursementApproved != null ? res.data[0].isDisbursementApproved : "Pending");
    }
  };

  const updateDisbursementApproval = async () => {
    let filterData = {
      invoiceId: invoiceId,
      userId: referenceId,
      isDisbursementApproved: approvalStatus,
    };
    setIsLoadingOn(true);
    const tempData = await postData("invoice/updateDisbursementApproval", filterData);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setDisbursementApproval(false);
      setShowTopMessage(false);
      closeModal();
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong!!!"
      })
    }
  };

  useEffect(() => {
    getDetailsDataDistributor(invoiceId);
  }, [invoiceId]);

  const getFileInvoiceData = async (fileData) => {
    let tempData = {
      file: fileData,
    };
    setIsLoadingOn(true);
    const res = await postData(`documents/invoiceData`, tempData);
    console.log("filterData Resposne", res);
    setIsLoadingOn(false);
    if (res.statusCode === 200) {
      let invoiceDetails = res.data[0];
      let formattedDate = new Date(invoiceDetails.invoiceDate)
        .toISOString()
        .split("T")[0];
      setFormDistributorData((prevData) => ({
        ...prevData,
        invoiceDate: formattedDate,
        invoiceNumber: invoiceDetails.invoiceNumber
          ? invoiceDetails.invoiceNumber
          : invoiceDetailsData.invoiceNumber,
      }));
    }
  };

  const data = useMemo(
    () => [
      {
        distId: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
        data1: "9988776786",
        action: "9988776786",
      },
    ],
    []
  );

  const openDisbursementApproval = () => {
    setApprovalStatus(disbusApproval);
    setModalIsDisbursementApproval(true);
  };

  const handleAgreementUpdateDetails = (item) => {
    setAgreementUpdateModal(true);
  };

  const handleAgreementUpdateSubmit = async (e) => {
    e.preventDefault();
    console.log(" disbursementInvoiceId ", disbursementInvoiceId);
    
    // if (formDistributorData) {
      let tempDataSend = {
        orderId: `${disbursementInvoiceId}`,
        orderType:"FUNDMYINVOICE", 
        file: agreementFileBase64,
      };
      setIsLoadingOn(true);
      const tempData = await postData(
        `documents/updateAgreement`,
        tempDataSend
      );
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        setActionModalOpen(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data Updated successfully!",
        });
      }
      else{
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: tempData?.data?.errors.msg || "Somthing went wrong",
        });
      }
    // }
  };

  const handleAgreementFileInputChange = (event, key) => {
    const file = event.target.files[0];
  
    if (file) {
      const validFileType = "application/pdf";
      if (file.type !== validFileType) {
        Swal.fire({
          icon: "error",
          title: "Invalid File!",
          text: "Please upload a valid PDF file.",
        });
        event.target.value = "";
        return;
      }
  
      const reader = new FileReader();
      reader.onload = () => {
        const base64URL = reader.result;
        const pdfBase64 = base64URL.split(",")[1];
        setAgreementFileBase64(pdfBase64);
        
      };
      reader.readAsDataURL(file);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Distributor Name",
        accessor: "distributorName",
      },
      {
        Header: "Invoice Number",
        accessor: "invoiceNumber",
      },
      {
        Header: "Invoice Amount",
        accessor: "invoiceAmount",
        Cell: ({ value }) => {
          if (typeof value === 'number') {
              return formatNumberWithCommasAndDecimals(value);
          } else {
              return value; 
          }
      },
      },
      {
        Header: "Invoice Date",
        accessor: "invoiceDate",
        Cell: ({ cell }) => {
          const invoiceDate = cell.row.original.invoiceDate;
          return <span>{invoiceDate && formatDateString(invoiceDate)}</span>;
        },
      },
      {
        Header: "Disbursement Date",
        accessor: "disbursedDate",
        Cell: ({ cell }) => {
          const disbursedDate = cell.row.original.disbursedDate;
          return <span>{disbursedDate && formatDateTimeSecond(disbursedDate)}</span>;
        },
      },
      {
        Header: "Document",
        accessor: "invoicePath",
        Cell: ({ row }) => {
          let invoiceData = row.original.invoicePath;
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {invoiceData && (
                <button
                  style={{
                    fontSize: 22,
                    color: "#6E12F9",
                    border: "none",
                    background: "none",
                    marginRight: "10px",
                  }}
                  onClick={() =>
                    window.open(row.original.invoicePath, "_blank")
                  }
                >
                  <FaRegFilePdf />
                </button>
              )}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "filePath",
        Cell: ({ cell }) => {
          const file = cell.row.original.invoicePath;
          const disbursedDate = cell.row.original.disbursedDate;
          let invoiceData = cell.row.original.invoicePath;
          return (
            <>
              {disbursedDate ? (
                <div className="dibsursedTagShow">Disbursed</div>
              ) : (
                <div
                  className="loanIdDownloadBtnDiv text-center"
                  style={{ padding: "5px !important;" }}
                >
                  {file ? (
                    <>
                      {invoiceData && invoiceData.length > 0 ? (
                        <button
                          className="btn btn-primary mb-1"
                          onClick={() => {
                            handleShowDisbursementData(cell.row.original);
                            setDisbursementInvoiceId(
                              cell.row.original.invoiceId
                            );
                          }}
                        >
                          <span style={{ fontSize: 14 }}>Add Disbursement</span>
                        </button>
                      ) : null}
                      <button
                        className="btn-sm btn-warning"
                        onClick={() => {
                          if (
                            userRole === "Admin" ||
                            userRole === "Operation"
                          ) {
                            handleOpenModalDetails(cell.row.original);
                          }
                        }}
                      >
                        <span>Update Invoice</span>
                      </button>
                    </>
                  ) : (
                    <button
                      className={`btn-sm btn-primary`}
                      onClick={() => {
                        if (userRole === "Admin" || userRole === "Operation") {
                          handleOpenModalDetails(cell.row.original);
                        }
                      }}
                    >
                      <span style={{ fontWeight: "500" }}>Add Invoice</span>
                    </button>
                  )}
                  {(userRole === "Admin" || userRole === "Operation") && cell?.row?.original?.agreement?.length > 0 && (
                    <button
                      className="btn-sm btn-warning"
                      onClick={() => {
                        if (
                          userRole === "Admin" ||
                          userRole === "Operation"
                        ) {
                          setDisbursementInvoiceId(cell.row.original.invoiceId);
                          handleAgreementUpdateDetails(cell.row.original.invoiceId);
                        }
                      }}
                    >
                      <span>Update Agreement</span>
                    </button>
                    )}
                </div>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <div className="noMarginSection">
      {isLoadingOn ? <Loader /> : ""}
      <section className="orderDetailsSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      aria-expanded="true"
                    >
                      Fund Invoice Details
                    </button>
                  </h2>
                  <div>
                    {(invoiceUserData == null) ? "" : (
                      <div className="d-flex justify-content-end align-items-end" style={{ margin: "5px" }}>
                        <button
                          style={{ fontSize: 12, marginRight: "15px", marginTop: "15px" }}
                          className="btn btnAddCredit"
                          onClick={() => {
                            openDisbursementApproval();
                          }}
                        >
                          Disbursement Approval
                        </button>
                    </div>
                    ) }
                  </div>                  
                  <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse collapse show"
                  >
                    {invoiceDistributorData &&
                    invoiceDistributorData.length > 0 ? (
                      <div className="accordion-body">
                        <Table
                          columns={columns}
                          data={invoiceDistributorData}
                        />
                      </div>
                    ) : (
                      <h5 style={{ padding: 10 }}>No Data Found</h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ------------- image View Modal ---------------------- */}

      <Modal
        isOpen={actionModalOpen}
        onRequestClose={closeModal}
        style={customStylesDistributor}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        {isLoadingOn ? <Loader /> : ""}
        <div className="dataImageViewAction">
          <form onSubmit={handleSubmit}>
            <div className="form-group mt-2">
              <label htmlFor="invoiceFile">Select File</label>
              <input
                type="file"
                // value={formDistributorData.invoiceFile}
                onChange={handleChange}
                accept="application/pdf"
                name="invoiceFile"
                className="form-control"
                id="invoiceFile"
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="invoiceNumber">Invoice Number</label>
              <input
                type="text"
                value={formDistributorData.invoiceNumber}
                onChange={handleChange}
                name="invoiceNumber"
                className="form-control"
                id="invoiceNumber"
                placeholder="Enter Number"
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="invoiceAmount">Invoice Amount</label>
              <input
                disabled
                type="text"
                value={formDistributorData.invoiceAmount}
                onChange={handleChange}
                name="invoiceAmount"
                className="form-control"
                id="invoiceAmount"
                placeholder="Enter Amount"
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="invoiceDate">Invoice Date</label>
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                value={formDistributorData.invoiceDate}
                onChange={handleChange}
                name="invoiceDate"
                className="form-control"
                id="invoiceDate"
              />
            </div>
            <div className="text-center mt-4">
              <button type="submit" className="btn btn-success">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {/* -------------------- Set Disbursement -------------------- */}
      <Modal
        isOpen={modalIsDisbursement}
        onRequestClose={closeModal}
        style={customStylesDisbursement}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        {isLoadingOn ? <Loader /> : ""}
        <div className="dataImageViewAction">
          <form onSubmit={handleUpdateDisbursedInvoice}>
            <div className="form-group mt-2">
              <label htmlFor="disbursementDistributor">Distributor Name</label>
              <input
                type="text"
                disabled
                value={formDisbursementData.disbursementDistributor}
                onChange={handleDisbursedChange}
                name="disbursementDistributor"
                className="form-control"
                id="disbursementDistributor"
                placeholder=""
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="disbursementInvoiceId">Invoice Number</label>
              <input
                type="number"
                disabled
                value={formDisbursementData.disbursementInvoiceId}
                onChange={handleDisbursedChange}
                name="disbursementInvoiceId"
                className="form-control"
                id="disbursementInvoiceId"
                placeholder=""
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="lenderName">Lender Name</label>
              <input
                type="text"
                disabled
                value={formDisbursementData.lenderName}
                onChange={handleDisbursedChange}
                name="lenderName"
                className="form-control"
                id="lenderName"
                placeholder=""
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="disbursementAmount">Distributor Disbursement Amount</label>
              <input
                type="number"
                disabled
                value={formDisbursementData.toDistributorDisbursementAmount}
                onChange={handleDisbursedChange}
                name="disbursementAmount"
                className="form-control"
                id="disbursementAmount"
                placeholder=""
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="totalDisbursementAmount">
               Total Disbursement Amount
              </label>
              <input
                disabled
                type="text"
                value={formDisbursementData.totalDisbursementAmount}
                onChange={handleDisbursedChange}
                name="totalDisbursementAmount"
                className="form-control"
                id="totalDisbursementAmount"
                placeholder=""
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="disbursedDate">Disbursed Date</label>
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                value={formDisbursementData.disbursedDate}
                onChange={handleDisbursedChange}
                name="disbursedDate"
                className="form-control"
                id="disbursedDate"
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="utr">UTR Number</label>
              <input
                type="text"
                value={formDisbursementData.utr}
                onChange={handleDisbursedChange}
                name="utr"
                className="form-control"
                id="utr"
                placeholder="Enter Number"
              />
            </div>
            <div className="text-center mt-4">
              <button type="submit" className="btn btn-success">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsDisbursementApproval}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Disbursement Approval Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Please Select Disbursement Approval</h4>
        <div className="modalUserStatus">
          <div className="confirmationbtn mb-8">
            <div className="wrapper_btnselt">

              <button
                onClick={() => setApprovalStatus("Pending")}
                className={approvalStatus === "Pending" ? "selected option" : "option"}
                style={{ backgroundColor: approvalStatus === "Pending" ? "#a2c11c" : "initial", color: "Black" }}
              >
                Pending
              </button>

              <button
                onClick={() => setApprovalStatus("Yes")}
                className={approvalStatus === "Yes" ? "selected option" : "option"}
                style={{ backgroundColor: approvalStatus === "Yes" ? "#a2c11c" : "initial", color: "Black" }}
              >
                Yes
              </button>

              <button
                onClick={() => setApprovalStatus("No")}
                className={approvalStatus === "No" ? "selected option" : "option"}
                style={{ backgroundColor: approvalStatus === "No" ? "#a2c11c" : "initial", color: "Black" }}
              >
                No
              </button>
            </div>
          </div>

          <div style={{ textAlign: "center", marginTop: 20 }}>
            <button onClick={updateDisbursementApproval} className="btn btn-success">
              Submit
            </button>
          </div>
        </div>
      </Modal>

      {/* ------------- agreement update Modal ---------------------- */}
      <Modal
        isOpen={agreementUpdateModal}
        onRequestClose={closeModal}
        style={customStylesDistributor}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        {isLoadingOn ? <Loader /> : ""}
        <div className="dataImageViewAction">
          <form onSubmit={handleAgreementUpdateSubmit}>
            <div className="form-group mt-2">
              <label htmlFor="invoiceFile">Select File</label>
              <input
                type="file"
                onChange={(event) => handleAgreementFileInputChange(event, 'file')}
                accept="application/pdf"
                name="invoiceFile"
                className="form-control"
                id="invoiceFile"
              />
            </div>
            
            <div className="text-center mt-4">
              <button type="submit" className="btn btn-success">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>

    </div>
  );
}

export default FundInvoiceDetails;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>
        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            {/* <tr>
                  <th
                    colSpan={visibleColumns.length}
                    style={{
                      textAlign: 'left',
                    }}
                  >
                  </th>
                </tr> */}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {/* <tfoot>
                {footerGroups.map(group => (
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map(column => (
                      <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                    ))}
                  </tr>
                ))}
              </tfoot> */}
        </table>
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
