
import React, { useEffect, useState, useRef, useMemo } from "react";
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    usePagination,
    useSortBy,
} from "react-table";
import { FaSearch } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import "../css/makeTable.css";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import TopShowMessage from "../component/TopShowMessage";
import Swal from "sweetalert2";

function ProgramMasterList() {
    const [merchantUserData, setMerchantUserData] = useState();
    const [selectedOptionOneStatus, setSelectedOptionOneStatus] = useState(null);
    const [selectedOptionTwoStatus, setSelectedOptionTwoStatus] = useState(null);
    const [selectedOptionThreeStatus, setSelectedOptionThreeStatus] = useState(null);
    const [selectedOptionFourthStatus, setSelectedOptionFourthStatus] = useState(null);
    const [distributorUserId, setDistributorUserId] = useState();
    const [brandId, setBrandId] = useState();
    const [programId, setProgramId] = useState();
    const [categoryId, setCategoryId] = useState();
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [isLoadingOn, setIsLoadingOn] = useState(false);
    const [loanStatusData, setLoanStatusData] = useState();
    const [distributorUserData, setDistributorUserData] = useState([]);
    const [distributorUserBrands, setDistributorUserBrands] = useState([]);
    const [programListDrop, setProgramListDrop] = useState([]);
    const [categoryListDrop, setCategoryListDrop] = useState([]);

    const userRole = localStorage.getItem("USER_ROLE");

    const inputRef = useRef();

    const resetFilters = () => {
        setDistributorUserId("");
        setBrandId("");
        setProgramId("");
        setCategoryId("");
        updateOrderStatus();
        setSelectedOptionOneStatus(null);
        setSelectedOptionTwoStatus(null);
        setSelectedOptionThreeStatus(null);
        setSelectedOptionFourthStatus(null);
    };

    const data = useMemo(
        () => [
            {
                id: 1,
                name: "John Doe",
                age: 25,
                city: "New York",
                mobileNo: "9988776786",
            },
        ],
        []
    );

    const searchManualData = async () => {
        let filterData = {};
        if (distributorUserId && brandId && programId && categoryId) {
            filterData = {
                distributorId: distributorUserId,
                brandId: brandId,
                programId: programId,
                productCategoryId: categoryId,
            };
        } else if (distributorUserId && brandId) {
            filterData = {
                distributorId: distributorUserId,
                brandId: brandId,
            };
        } else if (distributorUserId && programId) {
            filterData = {
                distributorId: distributorUserId,
                programId: programId,
            };
        } else if (distributorUserId && programId && categoryId) {
            filterData = {
                distributorId: distributorUserId,
                programId: programId,
                productCategoryId: categoryId,
            };
        } else if (brandId && programId) {
            filterData = {
                brandId: brandId,
                programId: programId,
            };
        } else if (brandId && programId && categoryId) {
            filterData = {
                brandId: brandId,
                programId: programId,
                productCategoryId: categoryId,
            };
        } else if (distributorUserId) {
            filterData = {
                distributorId: distributorUserId,
            };
        } else if (brandId) {
            filterData = {
                brandId: brandId,
            };
        } else if (programId) {
            filterData = {
                programId: programId,
            };
        } else if (categoryId) {
            filterData = {
                productCategoryId: categoryId,
            };
        } else {
            alert("Please select any one option");
        }

        setIsLoadingOn(true);

        const queryParams = new URLSearchParams(filterData).toString();

        const tempData = await getData(`users/getProgram/1?${queryParams}`);

        setIsLoadingOn(false);

        if (tempData.statusCode === 200) {
            setMerchantUserData(tempData.data);
        }
        else {
            Swal.fire({
                title: "Error!",
                text: tempData?.data?.errors.msg || "Something went wrong!!!"
            })
        }
    };

    const history = useHistory();

    const updateOrderStatus = async () => {
        setIsLoadingOn(true);
        const tempData = await getData("users/getMasterProgram/1");
        console.log(tempData, "tempdata")
        setIsLoadingOn(false);
        if (tempData.statusCode == 200) {
            setMerchantUserData(tempData.data);
        }
    };

    useEffect(() => {
        updateOrderStatus();
    }, []);

    const statusModalOpen = (item) => {
        setLoanStatusData(item);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const columns = useMemo(

        () => [
            {
                Header: "Sr. No.",
                accessor: (row, i) => i + 1,
                Cell: ({ cell }) => (
                    <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>{cell.row.index + 1}.</span>
                ),
            },
            {
                Header: 'Program Name',
                accessor: 'programName',

            },
            {
                Header: 'Distributor Name',
                accessor: "distributorNames",
            },
            {
                Header: 'Discount to Merchant',
                accessor: 'discountToMerchant'
            },
            {
                Header: 'Discount to Daqi',
                accessor: 'discountToDaqi',

            },

            {
                Header: 'Action',
                accessor: '',
                Cell: ({ cell }) => {


                    return (

                        <>
                            <div
                                className="loanIdDownloadBtnDiv d-flex justify-content-center align-items-center text-center"
                                style={{ padding: "5px !important" }}
                            >
                                {/* <button
                  className={`btn-sm btn-success`}
                  onClick={() => {
                    statusModalOpen(cell.row.original);
                  }}
                >
                  <span style={{ fontWeight: "500" }}>View</span>
                </button> */}


                                {/* { userRole === "Admin" || userRole === "Operation" && ( */}
                                <button
                                    style={{ border: "none" }}
                                    className={`btn-sm btn-success`}
                                    onClick={() => {
                                        if (userRole === "Admin" || userRole === "Operation") {
                                            const { id } = cell.row.original;
                                            window.open(
                                                `/EditProgramMaster?id=${id}`,
                                                "_blank"
                                            );
                                        }
                                    }}
                                >
                                    <span>View</span>
                                </button>
                                {/* )} */}

                            </div>
                        </>
                    );
                },

            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination
    );

    async function DistributorGetData() {
        setIsLoadingOn(true);
        const tempData = await getData("users/distributorUser/1", {});
        setIsLoadingOn(false);
        const tempDistData = [];
        if (tempData.statusCode == 200) {
            for (let i = 0; i < tempData.data.records.length; i++) {
                const element = tempData.data.records[i];
                tempDistData.push({
                    value: element.id,
                    label: `${element.name}-${element.id}`,
                });
            }
            setDistributorUserData(tempDistData);
        }
        else {
            Swal.fire({
                title: "Error!",
                text: tempData?.data?.errors.msg || "Something went wrong!!!"
            })
        }
    }

    async function getProgramListData() {
        setIsLoadingOn(true);
        const tempData = await getData("users/getProgramList/nothing");
        setIsLoadingOn(false);
        if (tempData.statusCode === 200 && tempData.data) {
            const tempDistData = tempData.data.records.map((element) => ({
                value: element.programId,
                label: element.programName,
            }));
            setProgramListDrop(tempDistData);
        } else {
            console.error("Error fetching distributor Program list.");
            Swal.fire({
                title: "Error!",
                text: tempData?.data?.errors.msg || "Something went wrong!!!"
            })
        }
    }

    useEffect(() => {
        DistributorGetData();
        getProgramListData();
    }, []);

    return (
        <div>
            {isLoadingOn ? <Loader /> : ""}
            <h3 className="tableHeaderMsg">Program Master List</h3>
            <div className="customHeaderTable">
                <div className="container-fluid">
                    <div className="row alignmentTable">


                        <div className="col-md-2">
                            <label style={{ marginBottom: 4, fontWeight: "500" }}>
                                Select Program
                            </label>
                            <Select
                                placeholder="Select Program"
                                name="role"
                                options={programListDrop}
                                value={selectedOptionThreeStatus}
                                onChange={(e) => {
                                    setProgramId(e.value);
                                    setSelectedOptionThreeStatus(e);
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <label style={{ marginBottom: 4, fontWeight: "500" }}>
                                Select Distributor
                            </label>
                            <Select
                                placeholder="Select"
                                name="role"
                                options={distributorUserData}
                                value={selectedOptionOneStatus}
                                onChange={(e) => {
                                    setDistributorUserId(e.value);
                                    setSelectedOptionOneStatus(e);
                                }}
                            />
                        </div>
                        <div className="col-md-4 buttonAlignmentProgram mt-4">
                            <button className="searchTableData" onClick={searchManualData}>
                                <FaSearch /> Search
                            </button>
                            <button onClick={resetFilters} className="resetTableData">
                                <HiRefresh /> Reset
                            </button>


                        </div>
                        <div className="col-md-4 text-end">
                            {/* {userRole === "Admin" || userRole === "Operation" && ( */}
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    window.open("/ProgramMaster", "_blank");
                                }}
                            >
                                Add Program Master
                            </button>
                            {/* )} */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="orderListDataFooter">
                {merchantUserData && (
                    <Table columns={columns} data={merchantUserData} />
                )}
            </div>

        </div>
    );
}

export default ProgramMasterList;

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length;

    return (
        <div>
            <input
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
                }}
                // placeholder={`Search ${count} records...`}
                className="searchFilter"
            />
            <i className="flaticon-search searchIcon"></i>
        </div>
    );
}


function fuzzyTextFilterFn(rows, id, filterValue) {
    // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        //state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy, //sort data
        usePagination //use pagination
    );

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    //const firstPageRows = rows.slice(0, 10);

    return (
        <>
            <div>
                <table {...getTableProps()} className="dashboardTable">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className="tableHeader"
                            >
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("Header")}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        {/* <tr>
                    <th
                      colSpan={visibleColumns.length}
                      style={{
                        textAlign: 'left',
                      }}
                    >
                    </th>
                  </tr> */}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()} className="tableTd">
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot className="orderTableFooter">
                        {footerGroups.map((group) => (
                            <tr {...group.getFooterGroupProps()}>
                                {group.headers.map((column) => (
                                    <td {...column.getFooterProps()}>
                                        {column.render("Footer")}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tfoot>
                </table>
                <div className="row m-0">
                    <div className="pagination col-lg-12">
                        <div className="col-lg-2">
                            <button
                                className="defaultPaginationButton"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {"<<"}
                            </button>{" "}
                            <button
                                className="defaultPaginationButton"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                {"<"}
                            </button>{" "}
                            <button
                                className="defaultPaginationButton"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                {">"}
                            </button>{" "}
                            <button
                                className="defaultPaginationButton"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {">>"}
                            </button>{" "}
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Total Records <strong>{rows.length}</strong>{" "}
                            </span>
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Page{" "}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{" "}
                            </span>
                        </div>
                        <div className="col-lg-3">
                            <span>
                                Go to page{" "}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const page = e.target.value
                                            ? Number(e.target.value) - 1
                                            : 0;
                                        gotoPage(page);
                                    }}
                                    className="gotoPage"
                                />
                            </span>{" "}
                        </div>
                        <div className="col-lg-3">
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                                className="selectDisplayNoOfRecords"
                            >
                                {[10, 20, 30, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <br />
            </div>
        </>
    );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
