import React, { useState } from 'react';
import { IoCloudDownload } from "react-icons/io5";
import { getData, postData } from '../store/utils.js';
import Swal from 'sweetalert2';
import FileUploadView from '../component/FileUploadView.js';
import { saveAs } from 'file-saver';

function BulkDisbursement() {
    const [file, setFile] = useState(null); // State to store the uploaded file
    const [error, setError] = useState(''); // State to manage error messages
    const [base64Data, setBase64Data] = useState(''); // State to store Base64 data
    const [isLoadingOn, setIsLoadingOn] = useState(false);



    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if (!selectedFile) {
            setError('No file selected. Please upload a file.');
            setFile(null);
            return;
        }

        // Validate file type (ensure it's .csv)
        const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
        if (fileExtension !== 'csv') {
            setError('Only .csv files are allowed.');
            setFile(null); // Reset the file state
            return;
        } else {
            setError(''); // Clear any previous error
        }

        // Read the file as Base64
        const reader = new FileReader();

        reader.onload = () => {
            const base64String = reader.result.split(',')[1]; // Extract Base64 string
            setFile({ name: selectedFile.name, base64: base64String }); // Store the file and Base64 data
            console.log('Base64 CSV Data:', base64String); // Log Base64 data
        };

        reader.onerror = () => {
            setError('Error reading file. Please try again.');
        };

        reader.readAsDataURL(selectedFile); // Read the file as a data URL (Base64)
    };

    const handleDownload = async () => {
        setIsLoadingOn(true);
        const tempData = await getData("userShop/getExcelUserShop/nothing");
        setIsLoadingOn(false);
        if (tempData.statusCode === 200) {
            const downloadLink = document.createElement("a");
            downloadLink.href = tempData.data.Location;
            downloadLink.target = "_blank";
            downloadLink.rel = "noopener noreferrer";
            downloadLink.click();
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            setError('Please upload a valid .csv file.');
            return;
        }

        let tempDataSend = {
            file: file.base64,
        };
        setIsLoadingOn(true);
        const tempData = await postData(
            `repayment/bulkDisbursement`,
            tempDataSend
        );
        setIsLoadingOn(false);
        if (tempData.statusCode == 200) {
            Swal.fire({
                icon: "success",
                title: "Success!",
                text: "CSV file successfully uploaded!",
            });
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: tempData?.data?.errors.msg || "Somthing went wrong",
            });
        }
        resetFile();
    }

    const resetFile = () => {
        setFile(null); // Reset the file state
        setError('');  // Clear any error messages
        document.getElementById('fileInput').value = ''; // Reset file input field
    }

    const downloadCSV = () => {
        saveAs(
            "/assets/disbursement-bulk-template.csv",
            "disbursement-bulk-template.csv"
        );
    };

    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='bulkUploadSec p-3 border'>
                    <div className='d-flex'>
                        <h5>Disbursement Bulk Upload</h5>
                        <div className='ms-auto'>
                            {/* <IoCloudDownload style={{ fontSize: "35px", color: "#0d6efd", cursor: "pointer" }} onClick={handleDownload} /> */}
                            <a
                                onClick={downloadCSV}
                                className="btn btn-link"
                            >
                                Sample File
                            </a>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3 col-sm-4">
                            <label className="form-label">Upload Disbursement File</label>
                            <input className="form-control" type="file" id='fileInput'
                                onChange={handleFileChange}
                                accept=".csv" // Optional: restrict file types

                            />
                        </div>
                        {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
                        <div><button type='submit' className='btn btn-primary'>Submit</button></div>
                    </form>
                </div>
            </div>
            <div className='col-sm-12'>
                <div className='mt-5'>
                    <FileUploadView />
                </div>
            </div>
        </div>
    )
}


export default BulkDisbursement;