import React, { useState, useMemo, useEffect } from "react";
import FileUploadView from "../component/FileUploadView.js";

const FileUpload = () => {

  return (
    <>
      <FileUploadView />
    </>
  );
};

export default FileUpload;


