import React, { useMemo, useState, useEffect } from "react";
import "../../css/makeTable.css";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { getData, postData } from "../../store/utils";
import Loader from "../../component/Loader";
import Moment from "react-moment";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import { formatDateTimeSecond } from "../../component/common";
import Swal from "sweetalert2";
import ApiResponseDummy from "../../component/DummyResponse";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
  },
};

const BankStatementReport = () => {
  const [merchantPersonalAccountData, setMerchantPersonalAccountData] =
    useState([]);

  const [merchantBankData, setMerchantBankData] = useState([]);
  const [merchantUserData, setMerchantUserData] = useState([]);
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [errorRes, setErrorRes] = useState('')
  const [summarySheetFilepath, setSummarySheetFilepath]= useState('');
  const urlSearchParams = new URLSearchParams(window.location.search);
  const userId = urlSearchParams.get("userId");

  async function merchantGetData(userId) {
    if (userId) {
      setIsLoadingOn(true);
      const tempData = await getData(`documents/bank_summary_data/${userId}`);
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        setMerchantUserData(tempData?.data);
        if (tempData?.data) {
          let tempMonthwiseData = [];
          let tempBankAccountData = [];
          let consolidatedMonthwiseData = [];
          let consolidatedBankData = [];
          for (let jindex = 0; jindex < tempData?.data?.length; jindex++) {
            tempMonthwiseData = [];
            tempBankAccountData = [];
            // const monthData = tempData?.data[jindex];
            tempData?.data[jindex]?.summaryData.forEach((element) => {
              switch (element.section) {
                case 1001:
                  if (element.setId == 2) {
                    tempBankAccountData.push(element?.sectionValues);
                  }
                  break;
                case 1002:
                  tempMonthwiseData.push(element);
                  break;
                default:
                  break;
              }
            })
            consolidatedBankData.push(tempBankAccountData)
            consolidatedMonthwiseData.push(tempMonthwiseData)
          }
          setMerchantBankData(consolidatedBankData);
          setMerchantPersonalAccountData(consolidatedMonthwiseData);
        }
      } else {
        Swal.fire({
          title: "Error!",
          text: tempData?.data?.errors?.msg || "Something went wrong",
        });
      }
    }
  }

  const handleRefreshDigiData = async () => {
    setIsLoadingOn(true);

    let filterData = {
      userId: userId,
    };
    const tempData = await postData(`digiTap/updateSummery`, filterData);

    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantUserData(tempData?.data);
      if (tempData?.data) {
        let tempMonthwiseData = [];
        let tempBankAccountData = [];
        let consolidatedMonthwiseData = [];
        let consolidatedBankData = [];
        for (let jindex = 0; jindex < tempData?.data?.length; jindex++) {
          tempMonthwiseData = [];
          tempBankAccountData = [];
          for (let index = 0; index < tempData?.data[jindex]?.length; index++) {
            const element = tempData?.data[jindex][index];
            switch (element.section) {
              case 1001:
                if (element.setId == 2) {
                  tempBankAccountData.push(element?.sectionValues);
                }
                break;
              case 1002:
                tempMonthwiseData.push(element);
                break;
              default:
                break;
            }
          }
          consolidatedBankData.push(tempBankAccountData)
          consolidatedMonthwiseData.push(tempMonthwiseData)
        }
        setMerchantBankData(consolidatedBankData);
        setMerchantPersonalAccountData(consolidatedMonthwiseData);
      } else {
        setErrorRes(tempData?.message)
      }
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors?.msg || "Something went wrong",
      });
    }
  }

  useEffect(() => {
    if (userId) {
      merchantGetData(userId);
    }
  }, [userId]);

  const handleBankStatementSummarySheetDownload = async (id) => {
    setIsLoadingOn(true);
    
    try {
      const tempData = await getData(`/documents/getBankStatementExcelSheet/${id}`);
      setIsLoadingOn(false);
  
      if (tempData.statusCode === 200) {
        console.log("tempData == ", tempData);
        const filePath = tempData?.filePath;
  
        if (filePath) {
          const link = document.createElement("a");
          link.href = filePath;
          link.target = "_blank";
          link.download = filePath.split("/").pop();
          link.click();
        }
      } else {
        Swal.fire({
          title: "Error!",
          text: tempData?.data?.message || "Something went wrong",
        });
      }
    } catch (error) {
      setIsLoadingOn(false);
      console.error("Error in handleBankStatementSummarySheetDownload:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to download the file. Please try again.",
      });
    }
  };
  
  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
      {
        id: 2,
        name: "Jane Smith",
        age: 30,
        city: "London",
        mobileNo: "9988776786",
      },
      {
        id: 3,
        name: "Bob Johnson",
        age: 35,
        city: "Paris",
        mobileNo: "9988776786",
      },
      // Add more data as needed
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: "userShopId",
        Cell: ({ cell }) => {
          return (
            <>
              <span style={{ fontWeight: "600" }}>{cell.row.index + 1}</span>
            </>
          );
        },
      },
      {
        Header: "Document Category",
        accessor: "tableReference",
      },
      {
        Header: "Document Type",
        accessor: "documentName",
      },
      {
        Header: "Image",
        accessor: "filePath",
        Cell: ({ cell }) => {
          const fileType = cell.row.original.fileType;
          return (
            <>
              {fileType == "image" ? (
                <div style={{ textAlign: "center", cursor: "pointer" }}>
                  <img
                    style={{ maxHeight: 50 }}
                    src={cell.row.original.filePath}
                  />
                </div>
              ) : (
                <a href={cell.row.original.filePath} download>
                  <img style={{ maxHeight: 50 }} src="../assets/img/pdf.png" />
                </a>
              )}
            </>
          );
        },
      },
      {
        Header: "Document Face",
        accessor: "mobileNo",
      },

      {
        Header: "Uploaded Date",
        accessor: "createdAt",
        Cell: ({ cell }) => {
          return <>{cell.row.original.createdAt}</>;
        },
      },

      {
        Header: "Approval Status",
        accessor: "statusName",
        Cell: ({ cell }) => {
          const verified = cell.row.original.statusName;
          return (
            <>
              {verified == "Pending" ? (
                <button className="btn btn-sm btn-danger">Pending</button>
              ) : verified == "Process" ? (
                <button className="btn btn-sm btn-success">Approved</button>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        Header: "Previous Status",
        accessor: "previousStatusName",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="orders-tab">
        <div className="accordion" id="accordionPanelsStayOpenExample">
          <div style={{ marginTop: 20 }}>
            {/* {merchantUserData?.length <= 0 && ( */}
              <div className="row">
                <div className="col-md-12 text-end mb-2 mt-2">
                  <button onClick={handleRefreshDigiData} className="btn btn-success">
                    Refresh Data
                  </button>
                </div>
              </div>
            {/* )} */}
  
            {merchantUserData &&
              merchantUserData?.map((data, globalIndex) => (
                <div key={globalIndex} className="accordion-item">
                    <h2 className="accordion-header d-flex justify-content-between align-items-center">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#panelsStayOpen-collapse-${globalIndex}`}
                      aria-expanded="true"
                      aria-controls={`panelsStayOpen-collapse-${globalIndex}`}
                    >
                      Bank Statement {globalIndex + 1}
                    </button>
                    
                  </h2>
                  <div id={`panelsStayOpen-collapse-${globalIndex}`} className={`accordion-collapse collapse ${globalIndex === 0 ? "show" : ""}`}>
                    <div className="accordion-body">
                    <button
                      className="searchTableData ms-auto d-flex mb-3"
                      onClick={() => handleBankStatementSummarySheetDownload(data?.id)}
                      >
                      <i className="fas fa-download" style={{ marginRight: "8px" }}></i>
                      Download Summary Report
                    </button>
                      <div className="bankDetailsMerchant">
                        {merchantBankData?.length > 0 ? (
                          merchantBankData[globalIndex].map((section, sectionIndex) => (
                            <div className="bankReportDataInfoValue" key={sectionIndex}>
                              {section?.map((data, dataIndex) => (
                                <table key={dataIndex}>
                                  <tbody>
                                    <tr>
                                      <th>{data?.key}</th>
                                      <td>{data?.value}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              ))}
                            </div>
                          ))
                        ) : (
                          <p style={{ fontSize: 22, marginTop: 20, fontWeight: "600" }}>
                            No Data Found
                          </p>
                        )}
                      </div>
                      <h5 style={{ margin: "20px 0px", fontWeight: "600" }}>Monthwise Details</h5>
                      <div>
                        <div className="bankReportData">
                          {merchantPersonalAccountData && merchantPersonalAccountData?.length > 0 ? (
                            <table>
                              <thead>
                                <tr>
                                  {merchantPersonalAccountData[globalIndex].map((data, index) => (
                                    <th key={index}>{data?.setValue}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {merchantPersonalAccountData[globalIndex]?.map((_, idx) => (
                                  <tr key={idx}>
                                    {merchantPersonalAccountData[globalIndex].map((data, dataIdx) => {
                                      const value = data?.sectionValues[idx]?.value;
                                      return (
                                        <td
                                          key={dataIdx}
                                          style={{
                                            color: value && parseFloat(value.replace(/[₹,]/g, "")) < 0 ? "red" : "inherit",
                                          }}
                                        >
                                          {value}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <p style={{ fontSize: 22, marginTop: 20, fontWeight: "600" }}>
                              No Data Found
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {merchantUserData && merchantUserData?.length === 0 && (
              <p style={{ fontSize: "16px", color: `${errorRes ? "red" : ""}`, fontWeight: "600" }}>
                {errorRes ? errorRes : "No Data Found"}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BankStatementReport;
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows?.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows?.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows?.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows?.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows?.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows?.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>
        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            {/* <tr>
                        <th
                          colSpan={visibleColumns.length}
                          style={{
                            textAlign: 'left',
                          }}
                        >
                        </th>
                      </tr> */}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {/* <tfoot>
                      {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}>
                          {group.headers.map(column => (
                            <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                          ))}
                        </tr>
                      ))}
                    </tfoot> */}
        </table>
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows?.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions?.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
