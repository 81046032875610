import React, { useEffect, useState } from "react";
import { getData, postData } from "../store/utils";
import { FaDownload } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import Loader from "../component/Loader";
import { format } from "date-fns";
import Select from "react-select";

const RevenueFile = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const handleFromDateChange = (date) => {
    const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
    setFromDate(formattedDate);
  };

  const handleToDateChange = (date) => {
    const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
    setToDate(formattedDate);
  };

  const fetchData = async () => {
    try {
      if (fromDate !== null || toDate !== null) {
        const sendTempData = {
          fromDate: fromDate,
          toDate: toDate,
        };
        setIsLoadingOn(true);
        const response = await postData(`repayment/revenueFile`, sendTempData);
        setIsLoadingOn(false);
        if (response.statusCode == 200) {
          if (response?.data?.Location) {
            const link = document.createElement("a");
            link.href = response.data.Location;
            link.download = "data.csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          if (response?.data?.Location) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "File Download successfully!",
            });
          } else {
            Swal.fire({
              icon: "Error",
              title: "Error!",
              text: "Error In File Download!",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            text: `${response.data.errors.msg}` || "Somthing went wrong",
          });
        }
      } else {
        alert("Please Select Valid Date");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      {/* <h3 className="tableHeaderMsg">Loan Tape</h3> */}
      <div className="customHeaderTable distable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <h6>
              <strong>Select Date :</strong>
            </h6>
            <div className="col-md-12">
              <div className="loanTapeChanges" style={{ alignItems: "center" }}>
                <div style={{ marginRight: 10 }}>
                  <label>From :</label>&nbsp;
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={fromDate ? new Date(fromDate) : null}
                    onChange={handleFromDateChange}
                    placeholderText="Select Start Date"
                    maxDate={new Date()}
                  />
                </div>
                <div style={{ marginRight: 30 }}>
                  <label>To :</label>&nbsp;
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={toDate ? new Date(toDate) : null}
                    onChange={handleToDateChange}
                    placeholderText="Select End Date"
                    maxDate={new Date()}
                  />
                </div>
                <button
                  className="searchTableData"
                  style={{ marginRight: 15 }}
                  onClick={fetchData}
                >
                  <FaDownload /> Download File
                </button>

                <button
                  className="resetTableData"
                  onClick={() => {
                    setFromDate(null);
                    setToDate(null);
                  }}
                >
                  <HiRefresh /> Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {isLoadingOn ? <Loader /> : null} */}
      {/* Display your table or other components here */}
    </>
  );
};

export default RevenueFile;
