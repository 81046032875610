import React, { useEffect, useState, useRef } from 'react'
import Loader from "../component/Loader";
// import Select from "react-select";
import { default as ReactSelect, components } from "react-select";
import { getData, postData } from '../store/utils.js';
import Swal from 'sweetalert2';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();


// Custom option component to include checkboxes
const Option = (props) => {

    const { data, isSelected, selectProps } = props;
    const isSelectAll = data.value === "select_all";
    const allSelected = selectProps?.formData?.distributorName?.length === selectProps?.options?.length - 1;

    const isChecked = isSelectAll ? allSelected : isSelected;

    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const ValueContainer = ({ children, ...props }) => {
    const selectedOptions = props.getValue();
    const count = selectedOptions.length;

    let displayText;
    if (count > 2) {
        displayText = `${count} Selected`; // Show count if more than 2 are selected
    } else {
        displayText = children; // Use default rendering for 2 or fewer options
    }
    return (
        <components.ValueContainer {...props}>
            {displayText}
        </components.ValueContainer>
    );
};

const ProgramMaster = () => {
    const [isLoadingOn, setIsLoadingOn] = useState(false);
    const [options, setOptions] = useState([]);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const initialFormData = {
        distributorName: [],
        programName: '',
        discountDaqi: '',
        discountMerchant: ''
    };
    const [formData, setFormData] = useState(initialFormData);

    const [errors, setErrors] = useState({});

    // const validateForm = () => {
    //     const newErrors = {};
    //     let isValid = true;

    //     if (formData.distributorName.length === 0) {
    //         newErrors.distributorName = 'At least one distributor must be selected';
    //         isValid = false;
    //     }

    //     if (!formData.programName) {
    //         newErrors.programName = 'Program Name is required';
    //         isValid = false;
    //     }

    //     if (!formData.discountDaqi || isNaN(formData.discountDaqi) || formData.discountDaqi < 0) {
    //         newErrors.discountDaqi = 'Please enter a valid Discount to Daqi %';
    //         isValid = false;
    //     }

    //     if (!formData.discountMerchant || isNaN(formData.discountMerchant) || formData.discountMerchant < 0) {
    //         newErrors.discountMerchant = 'Please enter a valid Discount to Merchant %';
    //         isValid = false;
    //     }

    //     setErrors(newErrors);
    //     return isValid;
    // };

    const validateForm = () => {
        const newErrors = {
            distributorName: formData.distributorName.length === 0 ? "At least one distributor must be selected" : "",
            programName: !formData.programName ? "Program Name is required" : "",
            discountDaqi: !formData.discountDaqi || isNaN(formData.discountDaqi) || formData.discountDaqi < 0
                ? "Please enter a valid Discount to Daqi %"
                : "",
            discountMerchant: !formData.discountMerchant || isNaN(formData.discountMerchant) || formData.discountMerchant < 0
                ? "Please enter a valid Discount to Merchant %"
                : "",
        };

        setErrors(newErrors);
        return Object.values(newErrors).every((error) => error === "");
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const tempDataSend = {
                distributorId: formData.distributorName.map((item) => item.value), // Send only values
                programName: formData.programName,
                discountToDaqi: formData.discountDaqi,
                discountToMerchant: formData.discountMerchant,
                userId: '',
            };
            console.log(tempDataSend, "tempDataSendtempDataSend")
            setIsLoadingOn(true);
            const tempData = await postData("programMaster/save", tempDataSend);
            setIsLoadingOn(false);

            if (tempData.statusCode === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Data Updated successfully!",
                });
                setFormData({
                    distributorName: [],
                    programName: '',
                    discountDaqi: '',
                    discountMerchant: ''
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: tempData?.data?.errors?.msg || "Something went wrong",
                });
            }
        } else {
            console.log('Form has errors');
        }
    };



    const getDistributorOption = async () => {
        setIsLoadingOn(true);
        const tempData = await getData("users/getDistributorList/1");
        setIsLoadingOn(false);

        if (tempData.statusCode === 200) {
            const options = tempData.data.records.map((item) => ({
                value: item.userId,
                label: item.name
            }));
            setOptions([{ value: "select_all", label: "Select All" }, ...options]);
        } else {
            console.error("Failed to fetch distributor options");
        }
    };

    // Handle selection change for multi-select dropdown
    // const handleChange = (selected) => {
    //     if (selected && selected.some((option) => option.value === "select_all")) {
    //         if (formData.distributorName.length === options.length - 1) {
    //             // Deselect all if all options are already selected
    //             setFormData((prevData) => ({
    //                 ...prevData,
    //                 distributorName: []
    //             }));
    //         } else {
    //             // Select all options except "Select All"
    //             setFormData((prevData) => ({
    //                 ...prevData,
    //                 distributorName: options.slice(1)
    //             }));
    //         }
    //     } else {
    //         setFormData((prevData) => ({
    //             ...prevData,
    //             distributorName: selected || []
    //         }));
    //     }
    // };

    const handleChange = (selected) => {
        if (selected && selected?.some((option) => option?.value === "select_all")) {
            if (formData.distributorName?.length === options?.length - 1) {
                setFormData((prevData) => ({ ...prevData, distributorName: [] }));
            } else {
                setFormData((prevData) => ({ ...prevData, distributorName: options.slice(1) }));
            }
        } else {
            setFormData((prevData) => ({ ...prevData, distributorName: selected || [] }));
        }
    };


    // Handle other input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    useEffect(() => {
        getDistributorOption()
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setMenuIsOpen(false); // Close the dropdown
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <div className='productData'>
            {isLoadingOn ? <Loader /> : ""}
            <h4><b>Program Master</b></h4>
            <div className='programFormsec'>
                <form onSubmit={handleSubmit} className='formsec'>
                    <div className='row mb-3 changeProductPro'>
                        <div className='col-sm-3' ref={dropdownRef}>
                            <label htmlFor="distributorName" className="form-label">
                                Distributor Name<span>*</span>
                            </label>
                            <ReactSelect
                                options={options}
                                isMulti
                                placeholder="Select Distributor"
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{ animatedComponents, Option, ValueContainer }}
                                onChange={handleChange}
                                value={formData?.distributorName}
                                menuIsOpen={menuIsOpen}
                                onMenuOpen={() => setMenuIsOpen(true)}
                                onMenuClose={() => setMenuIsOpen(false)}
                                formData={formData}
                            />
                            {errors.distributorName && (
                                <div className="form-text text-danger">{errors.distributorName}</div>
                            )}
                        </div>
                        <div className='col-sm-3'>
                            <label htmlFor="programName" className="form-label">
                                Program Name<span>*</span>
                            </label>
                            <input
                                type="text"
                                name="programName"
                                id="programName"
                                className="form-control"
                                placeholder='Enter Program Name'
                                value={formData.programName}
                                onChange={handleInputChange} />
                            {errors.programName && <div className="form-text text-danger">{errors.programName}</div>}
                        </div>
                        <div className='col-sm-3'>
                            <label htmlFor="discountDaqi" className="form-label">
                                Discount to Daqi<span>*</span>
                            </label>
                            <input
                                type="text"
                                name="discountDaqi"
                                id='discountDaqi'
                                className="form-control"
                                placeholder='Enter Discount to Daqi %'
                                value={formData.discountDaqi}
                                onChange={handleInputChange}
                            />
                            {errors.discountDaqi && <div className="form-text text-danger">{errors.discountDaqi}</div>}
                        </div>
                        <div className='col-sm-3'>
                        <label htmlFor="discountMerchant" className="form-label">
                                Discount to Merchant<span>*</span>
                            </label>
                            <input
                                type="text"
                                name="discountMerchant"
                                id="discountMerchant"
                                className="form-control"
                                placeholder='Enter Discount to Merchant %'
                                value={formData.discountMerchant}
                                onChange={handleInputChange}
                            />
                            {errors.discountMerchant && <div className="form-text text-danger">{errors.discountMerchant}</div>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <button type='submit' className='btn btn-primary'>Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ProgramMaster
