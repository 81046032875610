import React, { useEffect, useState } from "react";
import Loader from "../component/Loader";
import { getData } from "../store/utils.js";
import Swal from "sweetalert2";

function OrderStatus() {
    const [isLoadingOn, setIsLoadingOn] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [fundData, setFundData] = useState([]);
    const [orderTodayCount, setOrderTodayCount] = useState('');
    const [orderMonthlyCount, setOrderMonthlyCount] = useState('');
    const [fundTodayCount, setFundTodayCount] = useState('');
    const [fundMonthlyCount, setFundMonthlyCount] = useState('');

    // Filter criteria for statuses to display
    const allowedStatuses = ["Merchant Approval Pending", "Invoice Upload Pending", "Disbursement list not cleared", "Quotation Pending", "Disbursement Pending", "Order Disbursed", "Pending", "Processing", "Approved"];

    const fetchOrderStatus = async () => {
        setIsLoadingOn(true);
        const tempData = await getData(`orderReport/orderStatusData`);
        setIsLoadingOn(false);
        if (tempData.statusCode === 200) {
            const orderData = tempData?.data[0]?.orderData || [];
            const fundData = tempData?.data[0]?.fundData || [];

            
            // Filter data based on allowed statuses
            const filteredOrderData = orderData.filter((item) => allowedStatuses.includes(item.statusName));
            const filteredFundData = fundData.filter((item) => allowedStatuses.includes(item.statusName));
            
            const orderTodayCount = filteredOrderData.reduce((sum, item) => {
                return sum + Number(item.todayCount);
            }, 0);

            const orderMonthlyCount = filteredOrderData.reduce((sum, item) => {
                return sum + Number(item.currentMonthCount);
            }, 0);

            const fundTodayCount = filteredFundData.reduce((sum, item) => {
                return sum + Number(item.dailyCount);
            }, 0);

            const fundMonthlyCount = filteredFundData.reduce((sum, item) => {
                return sum + Number(item.monthlyCount);
            }, 0);

            setOrderData(filteredOrderData);
            setFundData(filteredFundData);

            setOrderTodayCount(orderTodayCount)
            setOrderMonthlyCount(orderMonthlyCount)
            setFundTodayCount(fundTodayCount)
            setFundMonthlyCount(fundMonthlyCount)
        } else {
            Swal.fire({
                title: "Error!",
                text: tempData?.data?.errors?.msg || "Something went wrong!!!",
            });
        }
    };

    useEffect(() => {
        fetchOrderStatus();
    }, []);

    return (
        <div>
            {isLoadingOn && <Loader />}
            <div className="row alignmentTable">
                <div className="col-md-12">
                    <div className="centre">
                        <h4 style={{ padding: '16px', textAlign: 'center' }}>Order Status</h4>
                    </div>
                </div>
                <div className="col-md-12">
                    <table className="dashboardTable orderStatusTable">
                        <thead>
                            <tr>
                                <th className="text-center">Status</th>
                                <th className="text-center">Order</th>
                                <th className="text-center">Fund My Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <table className="dashboardTable">
                                        <thead>
                                            <tr>
                                                <td colSpan={2}><b>Status</b></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderData.map((val) => (
                                                <tr key={val.status}>
                                                    <td colSpan={2}>{val?.statusName}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table className="dashboardTable">
                                        <thead>
                                            <tr>
                                                <td className="text-end" width="50%"><b>Today Count</b></td>
                                                <td className="text-end" width="50%"><b>Monthly Count</b></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderData.map((val) => (
                                                <tr key={val.status}>
                                                    <td className="text-end">{val?.todayCount}</td>
                                                    <td className="text-end">{val?.currentMonthCount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table className="dashboardTable">
                                        <thead>
                                            <tr>
                                                <td className="text-end" width="50%"><b>Today Count</b></td>
                                                <td className="text-end" width="50%"><b>Monthly Count</b></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fundData.map((val) => (
                                                <tr key={val.status}>
                                                    <td className="text-end">{val?.dailyCount}</td>
                                                    <td className="text-end">{val?.monthlyCount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td><b>Grand Total</b></td>
                                <td>
                                    <table width="100%" className="dashboardTable">
                                        <tbody>
                                            <tr>
                                                <td className="text-end" width="50%"><b>{orderTodayCount}</b></td>
                                                <td className="text-end" width="50%"><b>{orderMonthlyCount}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table width="100%" className="dashboardTable">
                                        <tbody>
                                            <tr>
                                                <td className="text-end" width="50%"><b>{fundTodayCount}</b></td>
                                                <td className="text-end" width="50%"><b>{fundMonthlyCount}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default OrderStatus;
