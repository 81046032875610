import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { getData, postData } from '../store/utils';
import Loader from '../component/Loader';
import TopShowMessage from '../component/TopShowMessage';
import { useHistory } from 'react-router-dom';
import Swal from "sweetalert2";

const ProgramsAddData = () => {
    const history = useHistory();
    const [isLoadingOn, setIsLoadingOn] = useState(false);
    const [showTopMessage, setShowTopMessage] = useState(false)
    const [totalRoiRows, setTotalRoiRows] = useState([{ id: 1 }]);
    const [totalTaxesRows, setTotalTaxesRows] = useState([{ id: 1 }]);
    const [totalAddBrandRows, setTotalAddBrandRows] = useState([{ id: 1 }]);
    const [brandDetailsRows, setbrandDetailsRows] = useState([{ id: 1 }]);
    const [distributorUserData, setDistributorUserData] = useState([]);
    const [selectedDistributor, setSelectedDistributor] = useState();
    const [selectedProductType, setSelectedProductType] = useState();
    const [selectedBrand, setSelectedBrand] = useState();
    const [errors, setErrors] = useState({});
    const [distributorUserBrands, setDistributorUserBrands] = useState([]);
    const [distributorProductType, setDistributorProductType] = useState([]);
    const [formData, setFormData] = useState({

        name: null,
        distributorId: null,
        productType: null,
        productCode: null,
        segment: null,
        eligibility: null,
        totalProgramLimit: null,
        approvedDate: null,
        limitExpiryDate: null,
        maxLimitPerAccount: null,
        requestAutoFinance: null,
        staleInvoicePeriod: null,
        stopSupply: null,
        FLDG: null,
        defaultPaymentTerms: null,
        invoiceAttachmentMandatory: null,
        partner: null,
        consolidateUTR: null,
        recourse: null,
        status: null,
        initiationFees: 0,
        valueAddedService: 0,
        facilitationFees: 0,
        interestFreeDays: 30,
        tenure: 1,
        processingFees: null,
        rateOfInterest: 0,
        transactionFees: 0,
        CDForFundMyInvoice: null,
        companyBoardResolutionAttachment: null,
        interestDetails: [],
        feeDetails: [],
        emailMobileDetails: [],
        programBrands: [{ brandId: '', productCategoryId: '', discountToDaqi: '', discountToMerchant: '' }],
        benchmarkTitle: null,
        currentBenchmarkRate: null,
        resetFrequency: null,
        penalInterestOnPrincipal: null,
        InterestOnPostedInterest: null,
        gracePeroid: 0,
        nameAsPerBank: null,
        bankAccountNumber: null,
        bankName: null,
        bankBranch: null,
        bankIfscCode: null,
        userId: 1,
    });

    console.log("formData::::", formData && formData.programBrands)

    useEffect(() => {
        const parsedApprovedDate = new Date(formData && formData.approvedDate);
        if (formData && formData.approvedDate) {
            const dateAfter12Months = new Date(
                parsedApprovedDate.setMonth(parsedApprovedDate.getMonth() + 12)
            ).toISOString().slice(0, 10);
            setFormData((prevData) => ({
                ...prevData,
                limitExpiryDate: dateAfter12Months,
            }));
        }
    }, [formData.approvedDate]);


    const numberFields = [
        'eligibility',
        'cashDiscount',
        'discountToMerchant',
        'discountToDaqi',
        'totalProgramLimit',
        'maxLimitPerAccount',
        'staleInvoicePeriod',
        'stopSupply',
        'FLDG',
        'defaultPaymentTerms',
        'status',
        'userId',
        'dealerBearing',
        'brandMobileNo',
        'resetFrequency',
        'creditSpread',
        'businessStrategySpread',
        'totalSpread',
        'penalInterestOnPrincipal',
        'totalROI',
        'InterestOnPostedInterest',
        'gracePeroid',
        'benchmarkTitle',
        'currentBenchmarkRate',
        'processingFees',
        'CDForFundMyInvoice'
    ];

    const parseToNumber = (formData) => {
        const parsedData = { ...formData };
        numberFields.forEach(field => {
            if (parsedData[field] !== "") {
                parsedData[field] = parseFloat(parsedData[field]);
            }
        });
        return parsedData;
    };

    const sendData = () => {
        const parsedFormData = parseToNumber(formData);
        console.log("parsedFormData++++", parsedFormData)
    };



    const handleSelectChange = (key, selectedOption) => {
        console.log("select---+++", key, selectedOption)
        const value = selectedOption && selectedOption.value !== undefined ? selectedOption.value : selectedOption;
        setFormData(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const totalRoiAddRow = () => {
        const newRow = { id: totalRoiRows.length + 1 };
        setTotalRoiRows([...totalRoiRows, newRow]);
        setFormData(prevState => ({
            ...prevState,
            interestDetails: [
                ...prevState.interestDetails,
                { fromDay: '', toDay: '', creditSpread: '', businessStrategySpread: '', totalSpread: '', totalRoi: '' }
            ]
        }));
    };

    const totalRoiDeleteRow = (id, index) => {
        const updatedRows = totalRoiRows.filter(row => row.id !== id);
        setTotalRoiRows(updatedRows);

        setFormData(prevState => ({
            ...prevState,
            interestDetails: prevState.interestDetails.filter((row, innerIndex) => innerIndex !== index)
        }));
    };


    const totalTaxesAddRow = () => {
        const newTaxesRow = { id: totalTaxesRows.length + 1 };
        setTotalTaxesRows([...totalTaxesRows, newTaxesRow]);
        setFormData(prevState => ({
            ...prevState,
            feeDetails: [
                ...prevState.feeDetails,
                { feeName: '', feeType: '', feeValue: '', feeDealerBearing: '', feeTaxes: '' }
            ]
        }));
    };

    const totalTaxesDeleteRow = (id, index) => {
        const updatedTaxesRows = totalTaxesRows.filter(row => row.id !== id);
        setTotalTaxesRows(updatedTaxesRows);
        setFormData(prevState => ({
            ...prevState,
            feeDetails: prevState.feeDetails.filter((row, innerIndex) => innerIndex !== index)
        }));
    };

    const handleAddBrandChange = (rowId, selectedOption) => {
        if (totalAddBrandRows.some(row => row.id === rowId)) {
            setTotalAddBrandRows(prevRows =>
                prevRows.map(row =>
                    row.id === rowId ? { ...row, selectedOption } : row
                )
            );
        } else {
            const newRow = { id: totalAddBrandRows.length + 1, selectedOption };
            setTotalAddBrandRows(prevRows => [...prevRows, newRow]);
        }
        setFormData(prevState => ({
            ...prevState,
            programBrands: prevState.programBrands.map((row, index) =>
                index + 1 === rowId ? { ...row, brandId: selectedOption.value } : row
            )
        }));
    };

    const handleAddProductChange = (rowId, selectedOption) => {
        if (totalAddBrandRows.some(row => row.id === rowId)) {
            setTotalAddBrandRows(prevRows =>
                prevRows.map(row =>
                    row.id === rowId ? { ...row, selectedOption } : row
                )
            );
        } else {
            const newRow = { id: totalAddBrandRows.length + 1, selectedOption };
            setTotalAddBrandRows(prevRows => [...prevRows, newRow]);
        }
        setFormData(prevState => ({
            ...prevState,
            programBrands: prevState.programBrands.map((row, index) =>
                index + 1 === rowId ? { ...row, productCategoryId: selectedOption.value } : row
            )
        }));
    };



    // const handleAddBrandChange = (rowId, selectedOption) => {
    //     const newRow = selectedOption;
    //     setTotalAddBrandRows([newRow]);
    //     setFormData(prevState => ({
    //         ...prevState,
    //         addBrandDetails: prevState.addBrandDetails.map((row, index) =>
    //             index + 1 === rowId ? { ...row, brandId: selectedOption.value } : row
    //         )
    //     }));
    // };


    const addBrandAddRow = () => {
        const newBrandRow = { id: totalAddBrandRows.length + 1 };
        setTotalAddBrandRows([...totalAddBrandRows, newBrandRow]);
        setFormData(prevState => ({
            ...prevState,
            programBrands: [
                ...prevState.programBrands,
                { brandId: '', productCategoryId: '', discountToDaqi: '', discountToMerchant: '' }
            ]
        }));
    };

    const addBrandDeleteRow = (id) => {
        const updatedBrandRows = totalAddBrandRows.filter(row => row.id !== id);
        setTotalAddBrandRows(updatedBrandRows);
        setFormData(prevState => ({
            ...prevState,
            programBrands: prevState.programBrands.filter((_, i) => i + 1 !== id)
        }));
    };



    const addbrandDetails = () => {
        const newTaxesRow = { id: brandDetailsRows.length + 1 };
        setbrandDetailsRows([...brandDetailsRows, newTaxesRow]);
        setFormData(prevState => ({
            ...prevState,
            emailMobileDetails: [
                ...prevState.emailMobileDetails,
                { brandEmailId: '', brandMobileNo: '', bankUserName: '', bankUserEmailId: '', bankUserMobileNo: '' }
            ]
        }));
    };

    const validateForm = () => {
        const errors = {};

        if (!formData.distributorId) {
            errors.distributorId = 'Please select Distributor';
        }
        // if (formData.programBrands.length > 0) {
        //     const addBrand = formData.programBrands[0];
        //     if (!addBrand.brandId) {
        //         errors.brandId = 'Please select brand.';
        //     }
        //     else if (!addBrand.discountToMerchant) {
        //         errors.discountToMerchant = 'Please Enter Discount %';
        //     }
        //     else if (!addBrand.discountToDaqi) {
        //         errors.discountToDaqi = 'Please Enter Discount % ';
        //     }
        //     else if (!addBrand.productCategoryId) {
        //         errors.productCategoryId = 'Please Enter Product Name';
        //     }
        // }
        if (!formData.name) {
            errors.name = 'Please Enter Program Name.';
        }
        // if (!formData.segment) {
        //     errors.segment = 'Please Enter Segment.';
        // }
        // if (!formData.eligibility) {
        //     errors.eligibility = 'Please Enter Eligibillty.';
        // }
        // if (!formData.totalProgramLimit) {
        //     errors.totalProgramLimit = 'Please Enter Total Program Limit.';
        // }
        // if (!formData.limitExpiryDate) {
        //     errors.limitExpiryDate = 'Please Enter Limit Expiry Date.';
        // }
        // if (!formData.requestAutoFinance) {
        //     errors.requestAutoFinance = 'Please Enter Request Auto Finance.';
        // }
        // if (!formData.staleInvoicePeriod) {
        //     errors.staleInvoicePeriod = 'Please Enter Stale Invoice Period.';
        // }
        // if (!formData.defaultPaymentTerms) {
        //     errors.defaultPaymentTerms = 'Please Enter Default Payment Terms.';
        // }
        if (!formData.interestFreeDays) {
            errors.interestFreeDays = 'Please Enter Interest Free Period';
        }
        if (formData.initiationFees == null || formData.initiationFees === '') {
            errors.initiationFees = 'Please Enter Initiation Fees';
        }
        if (formData.valueAddedService == null || formData.valueAddedService === '') {
            errors.valueAddedService = 'Please Enter Value Added Service';
        }
        if (formData.facilitationFees == null || formData.facilitationFees === '') {
            errors.facilitationFees = 'Please Enter Facilitation Fees';
        }
        if (formData.tenure == null || formData.tenure === '') {
            errors.tenure = 'Please Enter tenure';
        }
        if (!formData.processingFees) {
            errors.processingFees = 'Please Enter Processing Fees';
        }
        if (formData.rateOfInterest == null || formData.rateOfInterest === '') {
            errors.rateOfInterest = 'Please Enter Rate Of Interest ';
        }
        if (!formData.CDForFundMyInvoice) {
            errors.CDForFundMyInvoice = 'Please Enter CD for Fund My Invoice ';
        }
        // if (!formData.approvedDate) {
        //     errors.approvedDate = 'Please Enter Approved Date';
        // }
        // if (!formData.recourse) {
        //     errors.recourse = 'Please Enter Recourse';
        // }
        // if (!formData.invoiceAttachmentMandatory) {
        //     errors.invoiceAttachmentMandatory = 'Please Select Pdf file';
        // }
        if (formData.interestDetails.length > 0) {
            const feeDetail = formData.interestDetails[0];
            console.log("feeDetail0---", feeDetail)
        }

        // if (formData.emailMobileDetails.length > 0) {
        //     const feeDetail = formData.emailMobileDetails[0];
        //     if (!feeDetail.bankUserName) {
        //         errors.bankUserName = 'Please Enter Bank User Name';
        //     }
        // }
        if (!formData.bankAccountNumber) {
            errors.bankAccountNumber = 'Please Enter Account Number';
        }
        if (!formData.bankName) {
            errors.bankName = 'Please Enter Bank Name';
        }
        if (!formData.bankBranch) {
            errors.bankBranch = 'Please Enter Branch';
        }
        if (!formData.bankIfscCode) {
            errors.bankIfscCode = 'Please Enter Branch/IFSC Code';
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };


    // const deletebrandDetails = (id, index) => {
    //     const updatedbrandDetailsRows = brandDetailsRows.filter(row => row.id !== id);
    //     setbrandDetailsRows(updatedbrandDetailsRows);
    //     setFormData(prevState => ({
    //         ...prevState,
    //         emailMobileDetails: prevState.emailMobileDetails.filter((row, innerIndex) => innerIndex !== index)
    //     }));
    // };

    const deletebrandDetails = (id, index) => {
        const updatedbrandDetailsRows = brandDetailsRows.filter(row => row.id !== id);
        setbrandDetailsRows(updatedbrandDetailsRows);

        setFormData(prevState => ({
            ...prevState,
            emailMobileDetails: prevState.emailMobileDetails.filter((row, innerIndex) => innerIndex !== index)
        }));
    };


    const handelUpdateSubmit = async (event) => {
        event.preventDefault();

        const filteredData = Object.fromEntries(
            Object.entries(formData).filter(([key, value]) => value !== null)
        );
        // console.log("Testing202020202220=====",filteredData);
        sendData();
        if (validateForm()) {
            setIsLoadingOn(true);
            const res = await postData(`users/insertProgram`, filteredData);
            setIsLoadingOn(false);
            if (res.statusCode == 200) {
                history.push('/ProgramList');
                setShowTopMessage(true);
                setTimeout(() => {
                    setShowTopMessage(false);
                }, 3000);
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Data inserted successfully!",
                });
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Somthing went wrong!",
            });
        }
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64URL = reader.result;
                const pdfUrl = base64URL.split(',')[1];
                setFormData(prevState => ({
                    ...prevState,
                    companyBoardResolutionAttachment: pdfUrl
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpdateChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleUpdateRowChange = (event, rowIndex, arrayName) => {
        const { name, value } = event.target;
        const numericValue = value.match(/^-?\d*\.?\d*$/);
        setFormData(prevState => {
            const updatedArray = [...prevState[arrayName]];
            updatedArray[rowIndex] = {
                ...updatedArray[rowIndex],
                [name]: value
            };
            return {
                ...prevState,
                [arrayName]: updatedArray
            };
        });
        if (!value) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: 'Please Enter Discount %'
            }));
        } else if (!numericValue) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: 'Please enter a valid number'
            }));
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        }
    };




    async function DistributorGetData() {
        setIsLoadingOn(true);
        const tempData = await getData("users/distributorUser/1", {})
        setIsLoadingOn(false);
        const tempDistData = [];
        if (tempData.statusCode == 200) {
            for (let i = 0; i < tempData.data.records.length; i++) {
                const element = tempData.data.records[i];
                tempDistData.push({ value: element.id, label: element.name + "-" + element.id })
            }
            // console.log("tempDistData----",tempDistData)
            setDistributorUserData(tempDistData);
        }
        else {
            Swal.fire({
                title: "Error!",
                text: tempData?.data?.errors.msg || "Something went wrong!!!"
            })
        }
    }

    useEffect(() => {
        DistributorGetData();
    }, [])

    const statusValue = [
        { value: 1, label: 'Active' },
        { value: 2, label: 'Inactive' },
    ]



    const dummyOption = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
    ]





    async function DistributorGetBrands() {
        setIsLoadingOn(true);
        const tempData = await getData("users/getBrands/1");
        // console.log("tempData---",tempData.data)
        setIsLoadingOn(false);
        if (tempData.statusCode === 200 && tempData.data.records) {
            const tempDistData = tempData.data.records.map(element => ({
                value: element.id,
                label: element.name
            }));
            setDistributorUserBrands(tempDistData);
        } else {
            console.error("Error fetching distributor user brands.");
            Swal.fire({
                title: "Error!",
                text: tempData?.data?.errors.msg || "Something went wrong!!!"
            })
        }
    }

    async function getProductType() {
        setIsLoadingOn(true);
        const tempData = await getData("users/getProductCategory/nothing");
        setIsLoadingOn(false);
        if (tempData.statusCode === 200 && tempData.data.records) {
            const tempDistData = tempData.data.records.map(element => ({
                value: element.id,
                label: element.name,
            }));
            setDistributorProductType(tempDistData);
        } else {
            console.error("Error fetching distributor Product Type");
            Swal.fire({
                title: "Error!",
                text: tempData?.data?.errors.msg || "Something went wrong!!!"
            })
        }
    }

    useEffect(() => {
        DistributorGetBrands();
        getProductType();
    }, [])

    const haldleSearchAddress = async (options) => {
        if (options) {
            setIsLoadingOn(true);
            const tempData = await getData(`users/getDistributorBankDetail/nothing?distributorId=${options.value}`);
            setIsLoadingOn(false);
            if (tempData.statusCode === 200) {
                setFormData((prevData) => ({
                    ...prevData,
                    bankIfscCode: tempData.data[0] && tempData.data[0].ifscCode || '',
                    bankName: tempData.data[0] && tempData.data[0].name || '',
                    bankBranch: tempData.data[0] && tempData.data[0].branchName || '',
                    bankAccountNumber: tempData.data[0] && tempData.data[0].accountNumber || '',
                }));
            }
            else {
                Swal.fire({
                    title: "Error!",
                    text: tempData?.data?.errors.msg || "Something went wrong!!!"
                })
            }
        }
    }



    return (
        <>
            {isLoadingOn ? <Loader /> : ""}
            <div className="productData">
                {/* <h3 className='tableHeaderMsg'>Add Program</h3> */}

                <h4 className="mb-3"><b>Add Program</b></h4>

                <form onSubmit={handelUpdateSubmit} class="formsec">
                    <div className="row">
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="distributor" className="form-label">
                                    Distributor<span>*</span>
                                </label>
                                <Select
                                    placeholder="Select Distributor"
                                    name="distributorId"
                                    options={distributorUserData}
                                    value={selectedDistributor}
                                    onChange={(selectedOption) => {
                                        haldleSearchAddress(selectedOption)
                                        setSelectedDistributor(selectedOption)
                                        handleSelectChange('distributorId', selectedOption)

                                    }
                                    }
                                // is // required={true}
                                />
                                {errors.distributorId && (
                                    <div className="text-danger">{errors.distributorId}</div>
                                )}
                            </div>
                        </div>

                        {/* <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="creditLimit" className="form-label">
                                    Product Type<span>*</span>
                                </label>
                                <Select
                                    placeholder="Select"
                                    name="productType"
                                    options={distributorProductType}
                                    value={selectedProductType}
                                    onChange={
                                        (selectedOption) => {
                                            setSelectedProductType(selectedOption);
                                            handleSelectChange('productType', selectedOption)
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                productCode: selectedOption.productCode || '',
                                            }));
                                        }
                                    }
                                />
                                {errors.productType && (
                                    <div className="text-danger">{errors.productType}</div>
                                )}
                            </div>
                        </div> */}

                        {/* <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="creditLimit" className="form-label">
                                    Product Code
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter product code"
                                    className="form-control"
                                    id="productCode"
                                    name="productCode"
                                    value={formData.productCode}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                // required={true}
                                />
                            </div>
                        </div> */}
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="creditLimit" className="form-label">
                                    Program Name<span>*</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter Program Name"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                // required={true}
                                />
                                {errors.name && (
                                    <div className="text-danger">{errors.name}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="transactionFees" className="form-label">
                                    Segment
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter Segment"
                                    className="form-control"
                                    id="segment"
                                    name="segment"
                                    value={formData.segment}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                // required={true}
                                />
                                {/* {errors.segment && (
                                    <div className="text-danger">{errors.segment}</div>
                                )} */}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="eligibility" className="form-label">
                                    Eligibillty(%)
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Eligibillty %"
                                    className="form-control"
                                    id="eligibility"
                                    name="eligibility"
                                    value={formData.eligibility}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                // required={true}
                                />
                                {/* {errors.eligibility && (
                                    <div className="text-danger">{errors.eligibility}</div>
                                )} */}
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="totalProgramLimit" className="form-label">
                                    Total Program Limit
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Program Limit"
                                    className="form-control"
                                    id="totalProgramLimit"
                                    name="totalProgramLimit"
                                    value={formData.totalProgramLimit}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                // required={true}
                                />
                                {/* {errors.totalProgramLimit && (
                                    <div className="text-danger">{errors.totalProgramLimit}</div>
                                )} */}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="approvedDate" className="form-label">
                                    Program Approved Date
                                </label>
                                <input
                                    type="date"
                                    placeholder="Enter Program Approved Date"
                                    className="form-control"
                                    id="approvedDate"
                                    name="approvedDate"
                                    value={formData.approvedDate}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"

                                //  // required={true}
                                />
                                {/* {errors.approvedDate && (
                                    <div className="text-danger">{errors.approvedDate}</div>
                                )} */}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="limitExpiryDate" className="form-label">
                                    Limit Expiry Date
                                </label>
                                <input
                                    type="date"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="limitExpiryDate"
                                    name="limitExpiryDate"
                                    value={formData.limitExpiryDate}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                // required={true}
                                />
                                {/* {errors.limitExpiryDate && (
                                    <div className="text-danger">{errors.limitExpiryDate}</div>
                                )} */}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="maxLimitPerAccount" className="form-label">
                                    Maximum Limit per Account
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="maxLimitPerAccount"
                                    name="maxLimitPerAccount"
                                    value={formData.maxLimitPerAccount}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {/* {errors.maxLimitPerAccount && (
                                    <div className="text-danger">{errors.maxLimitPerAccount}</div>
                                )} */}
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="requestAutoFinance" className="form-label">
                                    Request Auto Finance
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter Auto Finance"
                                    className="form-control"
                                    id="requestAutoFinance"
                                    name="requestAutoFinance"
                                    value={formData.requestAutoFinance}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                // required={true}
                                />
                                {/* {errors.requestAutoFinance && (
                                    <div className="text-danger">{errors.requestAutoFinance}</div>
                                )} */}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="staleInvoicePeriod" className="form-label">
                                    Stale Invoice Period (Days)
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="staleInvoicePeriod"
                                    name="staleInvoicePeriod"
                                    value={formData.staleInvoicePeriod}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                // required={true}
                                />
                                {/* {errors.staleInvoicePeriod && (
                                    <div className="text-danger">{errors.staleInvoicePeriod}</div>
                                )} */}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="stopSupply" className="form-label">
                                    Stop Supply (Days)
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="stopSupply"
                                    name="stopSupply"
                                    value={formData.stopSupply}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {/* {errors.stopSupply && (
                                    <div className="text-danger">{errors.stopSupply}</div>
                                )} */}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="FLDG" className="form-label">
                                    FLDG (Days)
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter FLDG"
                                    className="form-control"
                                    id="FLDG"
                                    name="FLDG"
                                    value={formData.FLDG}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {/* {errors.FLDG && (
                                    <div className="text-danger">{errors.FLDG}</div>
                                )} */}
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="defaultPaymentTerms" className="form-label">
                                    Default Payment Terms (Days)
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="defaultPaymentTerms"
                                    name="defaultPaymentTerms"
                                    value={formData.defaultPaymentTerms}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                // required={true}
                                />
                                {/* {errors.defaultPaymentTerms && (
                                    <div className="text-danger">{errors.defaultPaymentTerms}</div>
                                )} */}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="invoiceAttachmentMandatory" className="form-label">
                                    Invoice Attachment Mandatory?
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="invoiceAttachmentMandatory"
                                    name="invoiceAttachmentMandatory"
                                    value={formData.invoiceAttachmentMandatory}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                // required={true}
                                />
                                {/* {errors.invoiceAttachmentMandatory && (
                                    <div className="text-danger">{errors.invoiceAttachmentMandatory}</div>
                                )} */}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="partner" className="form-label">
                                    Partner
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter Partner"
                                    className="form-control"
                                    id="partner"
                                    name="partner"
                                    value={formData.partner}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {/* {errors.partner && (
                                    <div className="text-danger">{errors.partner}</div>
                                )} */}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="consolidateUTR" className="form-label">
                                    Consolidate UTR for Dealer?
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="consolidateUTR"
                                    name="consolidateUTR"
                                    value={formData.consolidateUTR}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {/* {errors.consolidateUTR && (
                                    <div className="text-danger">{errors.consolidateUTR}</div>
                                )} */}
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="recourse" className="form-label">
                                    Recourse
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="recourse"
                                    name="recourse"
                                    value={formData.recourse}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {/* {errors.recourse && (
                                    <div className="text-danger">{errors.recourse}</div>
                                )} */}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="companyBoardResolutionAttachment" className="form-label">
                                    Company Board Resolutation Attachment(s)
                                </label>
                                <input
                                    type="file"
                                    accept='pdf'
                                    placeholder="Enter"
                                    className="form-control"
                                    id="companyBoardResolutionAttachment"
                                    name="companyBoardResolutionAttachment"
                                    onChange={handleFileInputChange}
                                    aria-describedby="textHelp"
                                />
                                {/* {errors.companyBoardResolutionAttachment && (
                                    <div className="text-danger">{errors.companyBoardResolutionAttachment}</div>
                                )} */}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="creditLimit" className="form-label">
                                    Status
                                </label>
                                <Select
                                    placeholder="Select"
                                    name="status"
                                    options={statusValue}
                                    // value={{ label: formData.status, value: formData.status }}
                                    onChange={(selectedOption) => handleSelectChange('status', selectedOption)}
                                />
                                {errors.status && (
                                    <div className="text-danger">{errors.status}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="initiationFees" className="form-label">
                                    Initiation Fees<span>*</span>
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="initiationFees"
                                    name="initiationFees"
                                    value={formData.initiationFees}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.initiationFees && (
                                    <div className="text-danger">{errors.initiationFees}</div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        {/* <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="cashDiscount" className="form-label">
                                    Cash Discount<span>*</span>
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="cashDiscount"
                                    name="cashDiscount"
                                    value={formData.cashDiscount}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.cashDiscount && (
                                    <div className="text-danger">{errors.cashDiscount}</div>
                                )}
                            </div>
                        </div> */}

                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="valueAddedService" className="form-label">
                                    Value Added Service<span>*</span>
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="valueAddedService"
                                    name="valueAddedService"
                                    value={formData.valueAddedService}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.valueAddedService && (
                                    <div className="text-danger">{errors.valueAddedService}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="facilitationFees" className="form-label">
                                    Facilitation Fees<span>*</span>
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="facilitationFees"
                                    name="facilitationFees"
                                    value={formData.facilitationFees}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.facilitationFees && (
                                    <div className="text-danger">{errors.facilitationFees}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="rateOfInterest" className="form-label">
                                    Rate Of Interest<span>*</span>
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="rateOfInterest"
                                    name="rateOfInterest"
                                    value={formData.rateOfInterest}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.rateOfInterest && (
                                    <div className="text-danger">{errors.rateOfInterest}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="tenure" className="form-label">
                                    Tenure<span>*</span>
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="tenure"
                                    name="tenure"
                                    value={formData.tenure}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.tenure && (
                                    <div className="text-danger">{errors.tenure}</div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="processingFees" className="form-label">
                                    Processing Fees<span>*</span>
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="processingFees"
                                    name="processingFees"
                                    value={formData.processingFees}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.processingFees && (
                                    <div className="text-danger">{errors.processingFees}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="CDForFundMyInvoice" className="form-label">
                                    CD for fund my Invoice<span>*</span>
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="CDForFundMyInvoice"
                                    name="CDForFundMyInvoice"
                                    value={formData.CDForFundMyInvoice}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.CDForFundMyInvoice && (
                                    <div className="text-danger">{errors.CDForFundMyInvoice}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="transactionFees" className="form-label">
                                    Transaction Fees
                                </label>
                                <input
                                    type="number"
                                    placeholder="Transaction Fees"
                                    className="form-control"
                                    id="transactionFees"
                                    name="transactionFees"
                                    value={formData.transactionFees}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.transactionFees && (
                                    <div className="text-danger">{errors.transactionFees}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="gracePeroid" className="form-label">
                                    Grace Period
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="gracePeroid"
                                    name="gracePeroid"
                                    value={formData.gracePeroid}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.gracePeroid && (
                                    <div className="text-danger">{errors.gracePeroid}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-3 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="interestFreeDays" className="form-label">
                                Interest Free Period<span>*</span>
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="interestFreeDays"
                                    name="interestFreeDays"
                                    value={formData.interestFreeDays}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.interestFreeDays && (
                                    <div className="text-danger">{errors.interestFreeDays}</div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* ------------------------------------- Add Brand ------------------------------------ */}

                    {/* <>
                        <hr className='orgbrd' />
                        <h5 className='mt-3'><b>Add Brands</b></h5>
                        {totalAddBrandRows && totalAddBrandRows.map((row, index) => (
                            <div key={row.id} className="row">
                                <div className="col-md-3 changeProductPro">
                                    <div className="mb-3">
                                        <label htmlFor="creditLimit" className="form-label">
                                            Brand<span>*</span>
                                        </label>
                                        <Select
                                            placeholder="Select"
                                            name="brandId"
                                            options={distributorUserBrands}
                                            onChange={(selectedOption) => handleAddBrandChange(row.id, selectedOption, "programBrands")}
                                        />
                                        {errors.brandId && (
                                            <div className="text-danger">{errors.brandId}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-3 changeProductPro">
                                    <div className="mb-3">
                                        <label htmlFor="productCategoryId" className="form-label">
                                            Product Category<span>*</span>
                                        </label>
                                        <Select
                                            placeholder="Select"
                                            name="productCategoryId"
                                            options={distributorProductType}
                                            onChange={(selectedOption) => handleAddProductChange(row.id, selectedOption, "programBrands")}
                                        />
                                        {errors.productCategoryId && (
                                            <div className="text-danger">{errors.productCategoryId}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6 changeProductPro">
                                    <div className="row align-items-center">
                                        <div className="col-md-5">
                                            <div className="mb-3">
                                                <label htmlFor="discountToDaqi" className="form-label">
                                                    Discount to Daqi (%)<span>*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    step='any'
                                                    placeholder="Enter Details"
                                                    className="form-control"
                                                    id="discountToDaqi"
                                                    name="discountToDaqi"
                                                    value={formData.discountToDaqi}
                                                    onChange={(event) => handleUpdateRowChange(event, index, "programBrands")}
                                                    aria-describedby="textHelp"
                                                />
                                                {errors.discountToDaqi && (
                                                    <div className="text-danger">{errors.discountToDaqi}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="mb-3">
                                                <label htmlFor="discountToMerchant" className="form-label">
                                                    Discount to Merchant (%)<span>*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    step='any'
                                                    placeholder="Enter Details"
                                                    className="form-control"
                                                    id="discountToMerchant"
                                                    name="discountToMerchant"
                                                    value={formData.discountToMerchant}
                                                    onChange={(event) => handleUpdateRowChange(event, index, "programBrands")}
                                                    aria-describedby="textHelp"
                                                />
                                                {errors.discountToMerchant && (
                                                    <div className="text-danger">{errors.discountToMerchant}</div>
                                                )}
                                            </div>
                                        </div>
                                        {index === 0 ?
                                            (
                                                <div className="col-md-1">
                                                    <div className="proPlusIconGreen" onClick={addBrandAddRow}>
                                                        <FaPlusCircle />
                                                    </div>
                                                </div>
                                            )
                                            :
                                            (
                                                <div className="col-md-2">
                                                    <div className="deletedIcon" onClick={() => addBrandDeleteRow(row.id)}>
                                                        <FaTrash />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </> */}

                    {/* ------------------------------------- Interest & Fee Details ------------------------------------ */}
                    <hr className='orgbrd' />
                    <h5 className='mt-3'><b>Interest & Fee Details</b></h5>
                    <div className="row">
                        <div className="col-md-4 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="benchmarkTitle" className="form-label">
                                    Benchmark Title (Maturity)
                                </label>
                                <Select
                                    placeholder="Select"
                                    name="benchmarkTitle"
                                    options={dummyOption}
                                    value={{ label: formData.benchmarkTitle, value: formData.benchmarkTitle }}
                                    onChange={(selectedOption) => handleSelectChange('benchmarkTitle', selectedOption)}
                                />
                            </div>
                        </div>

                        <div className="col-md-4 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="currentBenchmarkRate" className="form-label">
                                    Current Benchmark Rate (%)
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="currentBenchmarkRate"
                                    name="currentBenchmarkRate"
                                    value={formData.currentBenchmarkRate}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />

                            </div>
                        </div>

                        <div className="col-md-4 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="resetFrequency" className="form-label">
                                    Reset Frequency (Days)
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="resetFrequency"
                                    name="resetFrequency"
                                    value={formData.resetFrequency}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                            </div>
                        </div>

                    </div>
                    {totalRoiRows && totalRoiRows.map((row, index) => (
                        <div key={row.id} className="row">
                            <div className="col-md-4 changeProductPro">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="mb-3">
                                            <label htmlFor="fromDay" className="form-label">
                                                From Day
                                            </label>
                                            <input
                                                type="date"
                                                placeholder="Enter Details"
                                                className="form-control"
                                                id="fromDay"
                                                name="fromDay"
                                                value={formData.fromDay}
                                                onChange={(event) => handleUpdateRowChange(event, index, "interestDetails")}
                                                aria-describedby="textHelp"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mb-3">
                                            <label htmlFor="toDay" className="form-label">
                                                To Day
                                            </label>
                                            <input
                                                type="date"
                                                placeholder="Enter Details"
                                                className="form-control"
                                                id="toDay"
                                                name="toDay"
                                                value={formData.toDay}
                                                onChange={(event) => handleUpdateRowChange(event, index, "interestDetails")}
                                                aria-describedby="textHelp"
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="creditSpread" className="form-label">
                                                Credit Spread (%)
                                            </label>
                                            <input
                                                type="number"
                                                placeholder="Enter Details"
                                                className="form-control"
                                                id="creditSpread"
                                                name="creditSpread"
                                                value={formData.creditSpread}
                                                onChange={(event) => handleUpdateRowChange(event, index, "interestDetails")}
                                                aria-describedby="textHelp"
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-md-5 changeProductPro">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="businessStrategySpread" className="form-label">
                                                Business Strategy Sperad (%)
                                            </label>
                                            <input
                                                type="number"
                                                placeholder="Enter Details"
                                                className="form-control"
                                                id="businessStrategySpread"
                                                name="businessStrategySpread"
                                                value={formData.businessStrategySpread}
                                                onChange={(event) => handleUpdateRowChange(event, index, "interestDetails")}
                                                aria-describedby="textHelp"
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="totalSpread" className="form-label">
                                                Total Spread (%)
                                            </label>
                                            <input
                                                type="number"
                                                placeholder="Enter Details"
                                                className="form-control"
                                                id="totalSpread"
                                                name="totalSpread"
                                                value={formData.totalSpread}
                                                onChange={(event) => handleUpdateRowChange(event, index, "interestDetails")}
                                                aria-describedby="textHelp"
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-md-3 changeProductPro">
                                <div className="row" style={{ alignItems: "center" }}>
                                    <div className="col-md-10">
                                        <div className="mb-3">
                                            <label htmlFor="totalRoi" className="form-label">
                                                Total ROI
                                            </label>
                                            <input
                                                type="number"
                                                placeholder="Enter Total ROI"
                                                className="form-control"
                                                id="totalRoi"
                                                name="totalRoi"
                                                value={formData.totalRoi}
                                                onChange={(event) => handleUpdateRowChange(event, index, "interestDetails")}
                                                aria-describedby="textHelp"
                                            />
                                        </div>
                                    </div>
                                    {index === 0 ?
                                        (
                                            <div className="col-md-2">
                                                <div className="proPlusIconGreen" onClick={totalRoiAddRow}>
                                                    <FaPlusCircle />
                                                </div>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="col-md-2">
                                                <div className="deletedIcon" onClick={() => totalRoiDeleteRow(row.id, index)}>
                                                    <FaTrash />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                        </div>
                    ))}


                    <div className="row">
                        <div className="col-md-4 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="penalInterestOnPrincipal" className="form-label">
                                    Panel Interest on Principal (%)
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="penalInterestOnPrincipal"
                                    name="penalInterestOnPrincipal"
                                    value={formData.penalInterestOnPrincipal}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />

                            </div>
                        </div>

                        <div className="col-md-4 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="InterestOnPostedInterest" className="form-label">
                                    Interest on Posted Interest (%)
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="InterestOnPostedInterest"
                                    name="InterestOnPostedInterest"
                                    value={formData.InterestOnPostedInterest}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />

                            </div>
                        </div>

                        <div className="col-md-4 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="gracePeriod" className="form-label">
                                    Grace Period (Days)
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter Grace Period"
                                    className="form-control"
                                    id="gracePeriod"
                                    name="gracePeriod"
                                    value={formData.gracePeriod}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />

                            </div>
                        </div>
                    </div>

                    {totalTaxesRows && totalTaxesRows.map((row, index) => (
                        <div key={row.id} className="row">
                            <div className="col-md-4 changeProductPro">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="feeName" className="form-label">
                                                Fee Name
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Details"
                                                className="form-control"
                                                id="feeName"
                                                name="feeName"
                                                value={formData.feeName}
                                                onChange={(event) => handleUpdateRowChange(event, index, "feeDetails")}
                                                aria-describedby="textHelp"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="feeType" className="form-label">
                                                Type
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Details"
                                                className="form-control"
                                                id="feeType"
                                                name="feeType"
                                                value={formData.feeType}
                                                onChange={(event) => handleUpdateRowChange(event, index, "feeDetails")}
                                                aria-describedby="textHelp"
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-md-4 changeProductPro">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="feeValue" className="form-label">
                                                Value
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Details"
                                                className="form-control"
                                                id="feeValue"
                                                name="feeValue"
                                                value={formData.feeValue}
                                                onChange={(event) => handleUpdateRowChange(event, index, "feeDetails")}
                                                aria-describedby="textHelp"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="feeDealerBearing" className="form-label">
                                                Dealer Bearing (%)
                                            </label>
                                            <input
                                                type="number"
                                                placeholder="Enter Details"
                                                className="form-control"
                                                id="feeDealerBearing"
                                                name="feeDealerBearing"
                                                value={formData.feeDealerBearing}
                                                onChange={(event) => handleUpdateRowChange(event, index, "feeDetails")}
                                                aria-describedby="textHelp"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 changeProductPro">
                                <div className="row" style={{ alignItems: "center" }}>
                                    <div className="col-md-10">
                                        <div className="mb-3">
                                            <label htmlFor="feeTaxes" className="form-label">
                                                Taxes
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Taxes"
                                                className="form-control"
                                                id="feeTaxes"
                                                name="feeTaxes"
                                                value={formData.feeTaxes}
                                                onChange={(event) => handleUpdateRowChange(event, index, "feeDetails")}
                                                aria-describedby="textHelp"
                                            />
                                        </div>
                                    </div>
                                    {index === 0 ?
                                        (
                                            <div className="col-md-2">
                                                <div className="proPlusIconGreen" onClick={totalTaxesAddRow}>
                                                    <FaPlusCircle />
                                                </div>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="col-md-2">
                                                <div className="deletedIcon" onClick={() => totalTaxesDeleteRow(row.id)}>
                                                    <FaTrash />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                    <div>
                        <hr className='orgbrd' />
                        <h5 className='mt-3'><b>Email & Mobile Details</b></h5>
                        {brandDetailsRows && brandDetailsRows.map((row, index) => (
                            <div key={row.id} className="row">
                                <div className="col-md-4 changeProductPro">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="brandEmailId" className="form-label">
                                                    brand Email Id
                                                </label>
                                                <input
                                                    type="email"
                                                    placeholder="Enter Details"
                                                    className="form-control"
                                                    id="brandEmailId"
                                                    name="brandEmailId"
                                                    value={formData.brandEmailId}
                                                    onChange={(event) => handleUpdateRowChange(event, index, "emailMobileDetails")}
                                                    aria-describedby="textHelp"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row" style={{ alignItems: "center" }}>
                                                <div className="col-md-10">
                                                    <div className="mb-3">
                                                        <label htmlFor="brandMobileNo" className="form-label">
                                                            brand Mobile No.
                                                        </label>
                                                        <input
                                                            type="number"
                                                            placeholder="Enter brand Mobile No."
                                                            className="form-control"
                                                            id="brandMobileNo"
                                                            name="brandMobileNo"
                                                            value={formData.brandMobileNo}
                                                            onChange={(event) => handleUpdateRowChange(event, index, "emailMobileDetails")}
                                                            aria-describedby="textHelp"
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-2">
                                            <div className="proPlusIcon">
                                                <FaPlusCircle />
                                            </div>
                                        </div> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-4 changeProductPro">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="bankUserName" className="form-label">
                                                    Bank User Name
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Bank Name"
                                                    className="form-control"
                                                    id="bankUserName"
                                                    name="bankUserName"
                                                    value={formData.bankUserName}
                                                    onChange={(event) => handleUpdateRowChange(event, index, "emailMobileDetails")}
                                                    aria-describedby="textHelp"
                                                />
                                                {/* {errors.bankUserName && (
                                                    <div className="text-danger">{errors.bankUserName}</div>
                                                )} */}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="bankUserEmailId" className="form-label">
                                                    Bank User Email Id
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Details"
                                                    className="form-control"
                                                    id="bankUserEmailId"
                                                    name="bankUserEmailId"
                                                    value={formData.bankUserEmailId}
                                                    onChange={(event) => handleUpdateRowChange(event, index, "emailMobileDetails")}
                                                    aria-describedby="textHelp"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 changeProductPro">
                                    <div className="row" style={{ alignItems: "center" }}>
                                        <div className="col-md-10">
                                            <div className="mb-3">
                                                <label htmlFor="bankUserMobileNo" className="form-label">
                                                    Bank User Mobile No.
                                                </label>
                                                <input
                                                    type="number"
                                                    placeholder="Enter Bank User Mobile No."
                                                    className="form-control"
                                                    id="bankUserMobileNo"
                                                    name="bankUserMobileNo"
                                                    value={formData.bankUserMobileNo}
                                                    onChange={(event) => handleUpdateRowChange(event, index, "emailMobileDetails")}
                                                    aria-describedby="textHelp"
                                                />
                                            </div>
                                        </div>
                                        {index === 0 ?
                                            (
                                                <div className="col-md-2">
                                                    <div className="proPlusIconGreen" onClick={addbrandDetails}>
                                                        <FaPlusCircle />
                                                    </div>
                                                </div>
                                            )
                                            :
                                            (
                                                <div className="col-md-2">
                                                    <div className="deletedIcon" onClick={() => deletebrandDetails(row.id)}>
                                                        <FaTrash />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>

                    <hr className='orgbrd' />
                    <h5 className='mt-3'><b>Bank Details</b></h5>
                    <div className="row">
                        <div className="col-md-4 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="nameAsPerBank" className="form-label">
                                    Name As Per Bank
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="nameAsPerBank"
                                    name="nameAsPerBank"
                                    value={formData.nameAsPerBank}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                            </div>
                        </div>

                        <div className="col-md-4 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="bankAccountNumber" className="form-label">
                                    Account Number<span>*</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="bankAccountNumber"
                                    name="bankAccountNumber"
                                    value={formData.bankAccountNumber}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.bankAccountNumber && (
                                    <div className="text-danger">{errors.bankAccountNumber}</div>
                                )}
                            </div>
                        </div>

                        <div className="col-md-4 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="bankName" className="form-label">
                                    Bank Name<span>*</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter Bank Name"
                                    className="form-control"
                                    id="bankName"
                                    name="bankName"
                                    value={formData.bankName}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.bankName && (
                                    <div className="text-danger">{errors.bankName}</div>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-4 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="bankBranch" className="form-label">
                                    Branch<span>*</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="bankBranch"
                                    name="bankBranch"
                                    value={formData.bankBranch}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.bankBranch && (
                                    <div className="text-danger">{errors.bankBranch}</div>
                                )}
                            </div>
                        </div>

                        <div className="col-md-4 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="bankIfscCode" className="form-label">
                                    Branch/IFSC Code<span>*</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter Details"
                                    className="form-control"
                                    id="bankIfscCode"
                                    name="bankIfscCode"
                                    value={formData?.bankIfscCode || ''}
                                    onChange={handleUpdateChange}
                                    aria-describedby="textHelp"
                                />
                                {errors.bankIfscCode && (
                                    <div className="text-danger">{errors.bankIfscCode}</div>
                                )}
                            </div>
                        </div>

                        <div className="col-md-4 changeProductPro">
                            <div className="mb-3">
                                <label htmlFor="bankAccountType" className="form-label">
                                    Account Type
                                </label>
                                <Select
                                    placeholder="Select"
                                    name="bankAccountType"
                                    options={[
                                        { label: 'Saving', value: 'Saving' },
                                        { label: 'Current', value: 'Current' },
                                    ]}
                                    value={{ label: formData.bankAccountType, value: formData.bankAccountType }}
                                    onChange={(selectedOption) => handleSelectChange('bankAccountType', selectedOption)}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="text-left">
                        <button type="submit" className="btn btn-success text-center">
                            Submit
                        </button>
                    </div>
                </form>

            </div>
        </>
    )
}

export default ProgramsAddData