import React, { useEffect, useState } from "react";
import { getData, postData } from "../../store/utils";
import Loader from "../../component/Loader";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        overflow: "none",
    },
};

const OrderInfo = () => {
    const [isLoadingOn, setIsLoadingOn] = useState(false);
    const [errors, setErrors] = useState({});
    const searchParams = new URLSearchParams(window.location.search);
    const orderId = searchParams.get("orderId");
    const userId = searchParams.get("userId");
    const orderType = searchParams.get("orderType");
    const [shipmentInfo, setShipmentInfo] = useState({})
    // const [orderShipmentInfoId, setOrderShipmentInfoId] = useState('')
    const [modalImageURl, setModalImageURl] = useState();
    const [devliveredStatus, setDevliveredStatus] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false);


    const [formEntries, setFormEntries] = useState([
        {
            mobileNumber: "",
            pickupPersonName: "",
            pickupWarehouse: "",
            pincode: "",
            nameOfTransport: "",
            pickupDateTime: null,
            deliveredDate: null,
            LRNumber: "",
            transportType: "",
            address: "",
            vehicleNumber: "",
            vehicleType: "",
            registrationDate: null,
            ownerName: "",
            ownerAddress: "",
            LRFile: null,
            shipmentPhoto: null,
            deliveryReceipt: null
        },
    ]);

    const staticOptions = [
        { label: "Local", value: "Local" },
        { label: "Intercity", value: "Intercity" }
    ];

    const shipmentStatus = [
        { label: "Not Dispatched", value: '39' },
        { label: "In-transit", value: '40' },
        { label: "Delivered", value: '41' }
    ];

    const imageViewModal = (filePath) => {
        setModalImageURl(filePath);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleUpdateChange = (index, event) => {
        const { name, value } = event.target;  // 'event.target' refers to the input element
        const updatedEntries = [...formEntries];
        updatedEntries[index][name] = value;
        setFormEntries(updatedEntries);
    };

    const handleSelectChange = (index, selectedOption) => {
        const updatedEntries = [...formEntries];
        updatedEntries[index].transportType = selectedOption.value; // Update transportType
        setFormEntries(updatedEntries);
    };

    const handleStatusSelectChange = (index, selectedOption) => {
        console.log("selectedOption12126+", index, selectedOption)
        if (selectedOption.value == '41') {
            setDevliveredStatus(true)
        } else {
            setDevliveredStatus(false)
        }
        const updatedEntries = [...formEntries];
        updatedEntries[index].status = selectedOption.value; // Track the selected value
        setFormEntries(updatedEntries);
    }

    const handleAdd = () => {
        setFormEntries([
            ...formEntries,
            {
                mobileNumber: "",
                pickupPersonName: "",
                pickupWarehouse: "",
                pincode: "",
                nameOfTransport: "",
                pickupDateTime: null,
                deliveredDate: null,
                LRNumber: "",
                transportType: "",
                address: "",
                vehicleNumber: "",
                vehicleType: "",
                registrationDate: null,
                ownerName: "",
                ownerAddress: "",
                LRFile: null,
                shipmentPhoto: null,
                deliveryReceipt: null
            },
        ]);
    };

    const handleRemove = (index) => {
        setFormEntries(formEntries.filter((_, i) => i !== index));
    };

    const handledeliveryDateChange = (index, date) => {
        // Create a copy of the formEntries to update the specific form entry
        const updatedEntries = [...formEntries];

        if (date) {
            // Convert the date to UTC, this will prevent timezone issues
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

            // Format the UTC date to 'YYYY-MM-DD'
            const formattedDate = utcDate.toISOString().split('T')[0];  // Converts to 'yyyy-MM-dd'

            // Update the pickupDateTime for the specific index with the formatted date
            updatedEntries[index].deliveredDate = formattedDate;
        } else {
            updatedEntries[index].deliveredDate = null; // Handle empty date selection
        }

        // Set the updated form entries to state
        setFormEntries(updatedEntries);
    };


    const handlepickupDateChange = (index, date) => {
        // Create a copy of the formEntries to update the specific form entry
        const updatedEntries = [...formEntries];

        if (date) {
            // Convert the date to UTC, this will prevent timezone issues
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

            // Format the UTC date to 'YYYY-MM-DD'
            const formattedDate = utcDate.toISOString().split('T')[0];  // Converts to 'yyyy-MM-dd'

            // Update the pickupDateTime for the specific index with the formatted date
            updatedEntries[index].pickupDateTime = formattedDate;
        } else {
            updatedEntries[index].pickupDateTime = null; // Handle empty date selection
        }

        // Set the updated form entries to state
        setFormEntries(updatedEntries);
    };

    const handleRegistrationDateChange = (index, date) => {
        // Create a copy of the formEntries to update the specific form entry
        const updatedEntries = [...formEntries];

        if (date) {
            // Convert the date to UTC, this will prevent timezone issues
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

            // Format the UTC date to 'YYYY-MM-DD'
            const formattedDate = utcDate.toISOString().split('T')[0];  // Converts to 'yyyy-MM-dd'

            // Update the pickupDateTime for the specific index with the formatted date
            updatedEntries[index].registrationDate = formattedDate;
        } else {
            updatedEntries[index].registrationDate = null; // Handle empty date selection
        }

        // Set the updated form entries to state
        setFormEntries(updatedEntries);
    };

    const handleFileChange = (index, fileKey, event) => {
        const file = event.target.files[0];
        if (file) {
            const validExtensions = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
            if (validExtensions.includes(file.type)) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const base64WithPrefix = e.target.result;
                    // Remove the MIME type prefix (e.g., "data:image/jpeg;base64,")
                    const base64String = base64WithPrefix.split(',')[1];

                    const updatedFormEntries = [...formEntries];
                    updatedFormEntries[index] = {
                        ...updatedFormEntries[index],
                        [fileKey]: base64String, // Store the Base64 string under the respective key
                    };
                    setFormEntries(updatedFormEntries);
                };

                reader.readAsDataURL(file); // Convert file to Base64
            } else {
                alert('Only image files (JPG, PNG, GIF) and PDFs are allowed.');
            }
        }
    };




    const getShipmentData = async () => {
        setIsLoadingOn(true);
        let apiUrl = `shipment/getShipment?orderId=${orderId}`;

        const tempData = await getData(apiUrl);
        setIsLoadingOn(false);

        if (tempData.statusCode === 200) {
            const shipmentData = tempData?.data?.records || [];

            setShipmentInfo(shipmentData[0] || {})

            // const orderShipmentId = tempData.data.records.map()

            // setOrderShipmentInfoId(orderShipmentId)

            // Check if data exists; if not, initialize with a blank form
            if (shipmentData.length > 0) {
                setFormEntries(shipmentData.map((item) => ({
                    mobileNumber: item.mobileNumber || '',
                    pickupPersonName: item.pickupPersonName || '',
                    pickupWarehouse: item.pickupWarehouse || '',
                    pincode: item.pincode || '',
                    nameOfTransport: item.nameOfTransport || '',
                    pickupDateTime: item.pickupDateTime || null,
                    deliveredDate: item.deliveredDate || null,
                    LRNumber: item.LRNumber || '',
                    transportType: item.transportType || '',
                    address: item.address || '',
                    vehicleNumber: item.vehicleNumber || '',
                    vehicleType: item.vehicleType || '',
                    registrationDate: item.registrationDate || null,
                    ownerName: item.ownerName || '',
                    ownerAddress: item.ownerAddress || '',
                    status: item.status || '',
                    orderShipmentInfoId: item.id || '',
                    LRFile: item.LRFile || '',
                    LRFilePath: item.LRFilePath || null,
                    LRFileType: item.LRFileType || null,
                    shipmentPhoto: item.shipmentPhoto || '',
                    shipmentPhotoFilePath: item.shipmentPhotoFilePath || null,
                    shipmentPhotoType: item.shipmentPhotoType || null,
                    deliveryReceipt: '',
                    deliveryReceiptPath: item.deliveryReceiptPath || null,
                    deliveryReceiptType: item.deliveryReceiptType || null,
                })));
            } else {
                // No data found, initialize with one blank form entry
                setFormEntries([{
                    mobileNumber: '',
                    pickupPersonName: '',
                    pickupWarehouse: '',
                    pincode: '',
                    nameOfTransport: '',
                    pickupDateTime: null,
                    deliveredDate: null,
                    LRNumber: '',
                    transportType: '',
                    address: '',
                    vehicleNumber: '',
                    vehicleType: '',
                    registrationDate: null,
                    ownerName: '',
                    ownerAddress: '',
                    LRFile: null,
                    shipmentPhoto: null,
                    deliveryReceipt: null
                }]);
            }
        } else {
            setShipmentInfo({}); // Set default value on error

            Swal.fire({
                title: "Error!",
                text: tempData?.message || "Something went wrong!!!"
            });

            // Handle error by initializing with a blank form
            setFormEntries([{
                mobileNumber: '',
                pickupPersonName: '',
                pickupWarehouse: '',
                pincode: '',
                nameOfTransport: '',
                pickupDateTime: null,
                deliveredDate: null,
                LRNumber: '',
                transportType: '',
                address: '',
                vehicleNumber: '',
                vehicleType: '',
                registrationDate: null,
                ownerName: '',
                ownerAddress: '',
                LRFile: null,
                shipmentPhoto: null,
                deliveryReceipt: null
            }]);
        }
    };

    useEffect(() => {
        getShipmentData();
    }, []);

    useEffect(() => {
        if (shipmentInfo.status == 41) {
            setDevliveredStatus(true)
        }
    }, [shipmentInfo])



    const handleSubmit = async (event) => {
        event.preventDefault();

        const validationErrors = {};
        let hasErrors = false;

        // Iterate through formEntries and validate each entry
        formEntries.forEach((entry, index) => {
            if (!entry.mobileNumber) {
                validationErrors[`mobileNumber_${index}`] = "Mobile Number is required";
                hasErrors = true;
            }
            if (!entry.pickupPersonName) {
                validationErrors[`pickupPersonName${index}`] = "Pickup Person Name is required";
                hasErrors = true;
            }
        });

        setErrors(validationErrors);

        // If there are validation errors, stop the submit
        if (hasErrors) return;

        const updatedFormEntries = formEntries.map((entry) => ({
            ...entry,
            orderId, // Include orderId
            userId,  // Include userId
            orderType
        }));

        const dataToSend = {
            shipmentDetails: updatedFormEntries
        };

        setIsLoadingOn(true);
        const res = await postData(`shipment/saveShipment`, dataToSend);
        setIsLoadingOn(false);
        if (res.statusCode === 200) {
            Swal.fire({
                icon: "success",
                title: "Success!",
                text: "Data updated successfully!",
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Something went wrong!",
            });
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();

        const validationErrors = {};
        let hasErrors = false;

        // Iterate through formEntries and validate each entry
        formEntries.forEach((entry, index) => {
            if (!entry.mobileNumber) {
                validationErrors[`mobileNumber_${index}`] = "Mobile Number is required";
                hasErrors = true;
            }
            if (!entry.pickupPersonName) {
                validationErrors[`pickupPersonName${index}`] = "Pickup Person Name is required";
                hasErrors = true;
            }
        });

        setErrors(validationErrors);

        // If there are validation errors, stop the submit
        if (hasErrors) return;

        // Include orderShipmentInfoId in the payload
        const updatedEntries = formEntries.map((entry) => ({
            ...entry,
            orderShipmentInfoId: entry.orderShipmentInfoId, // Include the ID for update
            orderId, // Include orderId
            userId,  // Include userId
            orderType,
        }));

        const dataToSend = {
            shipmentDetails: updatedEntries,
        };
        setIsLoadingOn(true);
        const res = await postData(`shipment/saveShipment`, dataToSend);
        setIsLoadingOn(false);
        if (res.statusCode === 200) {
            Swal.fire({
                icon: "success",
                title: "Success!",
                text: "Data updated successfully!",
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Something went wrong!",
            });
        }
    }

    return (
        <>
            {isLoadingOn && <Loader />}
            <form>
                <div className="productData orderinfosec mt-4">
                    <div className="col-md-12 mb-4">
                        <div className="brd">
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <div><b>Approval Date:</b>	{shipmentInfo?.approvalDate}</div>
                                </div>
                                <div className="col-md-3">
                                    <div><b>Disbursment Date :</b>	{shipmentInfo?.disbursedDate}</div>
                                </div>
                                <div className="col-md-6">
                                    <div><b>Distributor Name:</b>	{shipmentInfo?.distributorNames}</div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-8">
                                    <div><b>Address of Delivery:</b>	{shipmentInfo?.merchantAddress}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {formEntries.map((entry, index) => {
                        console.log("entry++++", entry)
                        return (
                            <div key={index} className="formsec mb-3">
                                <div className="row">
                                    <div className="d-flex">
                                        <h5>Personal Details</h5>
                                        {formEntries.length > 1 && (
                                            <button type="button" className="btn btn-danger ms-auto" onClick={() => handleRemove(index)}>
                                                Remove
                                            </button>
                                        )}
                                    </div>
                                    <div className="col-md-3 changeProductPro">
                                        <div className="mb-3">
                                            <label htmlFor="transportType" className="form-label">
                                                Transport Type
                                            </label>
                                            {/* <select
                                            className="form-control"
                                            id="transportType"
                                            name="transportType" // Ensure 'name' is set
                                            value={formEntries[index]?.transportType || ""} // Bind to the correct entry
                                            onChange={(e) => handleUpdateChange(index, e)} // Pass the event correctly
                                        >
                                            <option value="">Select Transport Type</option>
                                            <option value="Local">Local</option>
                                            <option value="InterCity">InterCity</option>
                                        </select> */}
                                            <Select
                                                id="transportType"
                                                name="transportType"
                                                value={staticOptions.find((opt) => opt.value === entry.transportType) || null} // Bind current value
                                                onChange={(selectedOption) => handleSelectChange(index, selectedOption)} // Handle selection
                                                options={staticOptions} // Static options
                                                placeholder="Select Transport Type"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 changeProductPro">
                                        <div className="mb-3">
                                            <label htmlFor="mobileNumber" className="form-label">
                                                Mobile Number<span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Mobile Number"
                                                className={`form-control ${errors[`mobileNumber_${index}`] ? "is-invalid" : ""}`}
                                                id="mobileNumber"
                                                name="mobileNumber"
                                                maxLength={10}
                                                value={entry.mobileNumber}
                                                onChange={(e) => handleUpdateChange(index, e)}  // Correctly pass index
                                            />
                                            {errors[`mobileNumber_${index}`] && (
                                                <div className="invalid-feedback">{errors[`mobileNumber_${index}`]}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3 changeProductPro">
                                        <div className="mb-3">
                                            <label htmlFor="pickupPersonName" className="form-label">
                                                Pickup Person Name<span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Pickup Person Name"
                                                className={`form-control ${errors[`pickupPersonName${index}`] ? "is-invalid" : ""}`}
                                                id="pickupPersonName"
                                                name="pickupPersonName"
                                                value={entry.pickupPersonName}
                                                onChange={(e) => handleUpdateChange(index, e)}  // Pass index and event
                                            />
                                            {errors[`pickupPersonName${index}`] && (
                                                <div className="invalid-feedback">{errors[`pickupPersonName${index}`]}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-3 changeProductPro">
                                        <div className="mb-3">
                                            <label htmlFor="pickupWarehouse" className="form-label">
                                                Pickup Warehouse
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Pickup Warehouse"
                                                className="form-control"
                                                id="pickupWarehouse"
                                                name="pickupWarehouse"
                                                value={entry.pickupWarehouse}
                                                onChange={(e) => handleUpdateChange(index, e)}  // Pass index and event
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 changeProductPro">
                                        <div className="mb-3">
                                            <label htmlFor="pincode" className="form-label">
                                                Pincode
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Pincode"
                                                className="form-control"
                                                id="pincode"
                                                name="pincode"
                                                value={entry.pincode}
                                                onChange={(e) => handleUpdateChange(index, e)}  // Pass index and event
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 changeProductPro">
                                        <div className="mb-3">
                                            <label htmlFor="nameOfTransport" className="form-label">
                                                Name Of Transport
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Name Of Transport"
                                                className="form-control"
                                                id="nameOfTransport"
                                                name="nameOfTransport"
                                                value={entry.nameOfTransport}
                                                onChange={(e) => handleUpdateChange(index, e)}  // Pass index and event
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 changeProductPro">
                                        <div className="mb-3 position-relative">
                                            <label htmlFor="pickupDateTime" className="form-label">
                                                Pickup Date Time
                                            </label>
                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                selected={entry.pickupDateTime ? new Date(entry.pickupDateTime) : null}  // Use entry for each form
                                                onChange={(date) => handlepickupDateChange(index, date)}  // Pass index and date to the handler
                                                placeholderText="Select Pickup Date"
                                                maxDate={new Date()}
                                                className="form-control"
                                            />


                                        </div>
                                    </div>
                                    <div className="col-md-3 changeProductPro">
                                        <div className="mb-3">
                                            <label htmlFor="LRNumber" className="form-label">
                                                LR Number
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter LR Number"
                                                className="form-control"
                                                id="LRNumber"
                                                name="LRNumber"
                                                value={entry.LRNumber}
                                                onChange={(e) => handleUpdateChange(index, e)}  // Pass index and event
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 changeProductPro">
                                        <label htmlFor="LRFilePath" className="form-label">
                                            LR File Path
                                        </label>
                                        <input
                                            type="file"
                                            onChange={(e) => handleFileChange(index, "LRFile", e)} // Pass fileKey as "LRFile"
                                            accept="image/jpeg, image/png, image/gif, application/pdf"
                                        />
                                        {/* {fileType == "image" ? ( */}
                                        {/* <div
                                                style={{ textAlign: "center", cursor: "pointer" }}
                                                onClick={() => imageViewModal(cell.row.original)}
                                            > */}
                                        {entry.LRFilePath &&
                                            <div>
                                                {entry.LRFileType == "pdf" ?

                                                    <a href={entry.LRFilePath} download>
                                                        <img style={{ maxHeight: 50, marginTop: '10px', marginBottom: "20px" }} src="../assets/img/pdf.png" />
                                                    </a>
                                                    :
                                                    <img
                                                        style={{ maxHeight: 50, border: '1px solid #ccc', marginTop: '10px', marginBottom: "20px" }}
                                                        src={entry.LRFilePath}
                                                        onClick={() => imageViewModal(entry.LRFilePath)}
                                                    />
                                                }


                                            </div>
                                        }
                                        {/* </div> */}
                                        {/* ) : (
                                            <a href={cell.row.original.filePath} download>
                                                <img style={{ maxHeight: 50 }} src="../assets/img/pdf.png" />
                                            </a>
                                        )} */}
                                    </div>
                                    <div className="col-md-6 changeProductPro">
                                        <div className="mb-3">
                                            <label htmlFor="address" className="form-label">
                                                Address of Delivery
                                            </label>
                                            <textarea
                                                type="text"
                                                placeholder="Enter Address of Delivery"
                                                className="form-control"
                                                id="address"
                                                name="address"
                                                value={entry.address}
                                                onChange={(e) => handleUpdateChange(index, e)}  // Pass index and event
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 changeProductPro">
                                        <div className="mb-3">
                                            <label htmlFor="shipmentStatus" className="form-label">
                                                Status
                                            </label>
                                            <Select
                                                key={index}
                                                id={`shipmentStatus-${index}`}
                                                name={`shipmentStatus-${index}`}
                                                value={shipmentStatus.find((opt) => opt.value == entry.status) || null}
                                                onChange={(selectedOption) => handleStatusSelectChange(index, selectedOption)}
                                                options={shipmentStatus}
                                                placeholder="Select Status"
                                            />
                                        </div>
                                    </div>
                                    {
                                        devliveredStatus && entry.status == '41' &&
                                        <>
                                            <div className="col-md-3 changeProductPro">
                                                <div className="mb-3 position-relative">
                                                    <label htmlFor="deliveredDate" className="form-label">
                                                        Delivered Date Time
                                                    </label>
                                                    <DatePicker
                                                        dateFormat="dd-MM-yyyy"
                                                        selected={entry.deliveredDate ? new Date(entry.deliveredDate) : null}  // Use entry for each form
                                                        onChange={(date) => handledeliveryDateChange(index, date)}  // Pass index and date to the handler
                                                        placeholderText="Select Delivery Date"
                                                        maxDate={new Date()}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 changeProductPro">
                                                <label htmlFor="shipmentPhoto" className="form-label">
                                                    Shipment Photo
                                                </label>
                                                <input
                                                    type="file"
                                                    onChange={(e) => handleFileChange(index, "shipmentPhoto", e)} // Pass fileKey as "shipmentPhoto"
                                                    accept="image/jpeg, image/png, image/gif, application/pdf"
                                                />
                                                {entry.shipmentPhotoFilePath &&
                                                    <div>
                                                        {entry.shipmentPhotoType == "pdf" ?

                                                            <a href={entry.shipmentPhotoFilePath} download>
                                                                <img style={{ maxHeight: 50, marginTop: '10px', marginBottom: "20px" }} src="../assets/img/pdf.png" />
                                                            </a>
                                                            :
                                                            <img
                                                                style={{ maxHeight: 50, border: '1px solid #ccc', marginTop: '10px', marginBottom: "20px" }}
                                                                src={entry.shipmentPhotoFilePath}
                                                                onClick={() => imageViewModal(entry.shipmentPhotoFilePath)}
                                                            />
                                                        }


                                                    </div>
                                                }
                                            </div>
                                            <div className="col-md-3 changeProductPro">
                                                <label htmlFor="deliveryReceipt" className="form-label">
                                                    Delivered Receipt
                                                </label>
                                                <input
                                                    type="file"
                                                    onChange={(e) => handleFileChange(index, "deliveryReceipt", e)} // Pass fileKey as "deliveryReceipt"
                                                    accept="image/jpeg, image/png, image/gif, application/pdf"
                                                />

                                                {entry.deliveryReceiptPath &&
                                                    <div>
                                                        {entry.deliveryReceiptType == "pdf" ?

                                                            <a href="#" download>
                                                                <img style={{ maxHeight: 50, marginTop: '10px', marginBottom: "20px" }} src="../assets/img/pdf.png" />
                                                            </a>
                                                            :
                                                            <img
                                                                style={{ maxHeight: 50, border: '1px solid #ccc', marginTop: '10px', marginBottom: "20px" }}
                                                                src={entry.deliveryReceiptPath}
                                                                onClick={() => imageViewModal(entry.deliveryReceiptPath)}
                                                            />
                                                        }

                                                    </div>
                                                }

                                            </div>
                                        </>

                                    }


                                </div>
                                <hr />
                                <div className="row mt-3">
                                    <h5>Vehicle Details</h5>
                                    <div className="col-md-3 changeProductPro">
                                        <div className="mb-3">
                                            <label htmlFor="vehicleNumber" className="form-label">
                                                Vehicle Number
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Vehicle Number"
                                                className="form-control"
                                                id="vehicleNumber"
                                                name="vehicleNumber"
                                                value={entry.vehicleNumber}
                                                onChange={(e) => handleUpdateChange(index, e)}  // Pass index and event
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3 changeProductPro">
                                        <div className="mb-3">
                                            <label htmlFor="vehicleType" className="form-label">
                                                Vehicle Type
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Vehicle Class"
                                                className="form-control"
                                                id="vehicleType"
                                                name="vehicleType"
                                                value={entry.vehicleType}
                                                onChange={(e) => handleUpdateChange(index, e)}  // Pass index and event
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 changeProductPro">
                                        <div className="mb-3 position-relative">
                                            <label htmlFor="addressDelivery" className="form-label">
                                                Registration Date
                                            </label>
                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                selected={entry.registrationDate ? new Date(entry.registrationDate) : null}  // Use entry for each form
                                                onChange={(date) => handleRegistrationDateChange(index, date)}  // Pass index and date to the handler
                                                placeholderText="Select Registration Date"
                                                maxDate={new Date()}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 changeProductPro">
                                        <div className="mb-3">
                                            <label htmlFor="ownerName" className="form-label">
                                                Owner Name
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Owner Name"
                                                className="form-control"
                                                id="ownerName"
                                                name="ownerName"
                                                value={entry.ownerName}
                                                onChange={(e) => handleUpdateChange(index, e)}  // Pass index and event
                                            />
                                            {errors.ownerName && (
                                                <div className="invalid-feedback">{errors.ownerName}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 changeProductPro">
                                        <div className="mb-3">
                                            <label htmlFor="ownerAddress" className="form-label">
                                                Owner Address
                                            </label>
                                            <textarea
                                                type="text"
                                                placeholder="Enter Owner Address"
                                                className="form-control"
                                                id="ownerAddress"
                                                name="ownerAddress"
                                                value={entry.ownerAddress}
                                                onChange={(e) => handleUpdateChange(index, e)}  // Pass index and event
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div>
                    {shipmentInfo?.id ? (<button type="submit" onClick={handleUpdate} className="btn btn-success">Update</button>) : (<button type="submit" onClick={handleSubmit} className="btn btn-success">Submit</button>)}
                    <button type="button" className="btn btn-primary ms-3" onClick={handleAdd}>
                        Add Another Form
                    </button>
                </div>
            </form>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <button className="modalCloseBtn" onClick={closeModal}>
                    <FaRegTimesCircle />
                </button>
                {modalImageURl && (
                    <div className="modalUserImage">
                        <img
                            src={modalImageURl}
                            alt="modalImage"
                        />
                    </div>
                )}
            </Modal>
        </>
    );

};

export default OrderInfo;