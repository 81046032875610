import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  FaUsers,
  FaFileInvoiceDollar,
  FaList,
  FaFileInvoice,
  FaCreditCard,
  FaFileAlt,
  FaHome,
  FaGraduationCap,
  FaUserCog,
  FaArrowAltCircleRight,
  FaArrowAltCircleLeft,
  FaBars,
} from "react-icons/fa";
import { TbDeviceDesktopSearch } from "react-icons/tb";
import { GiMoneyStack } from "react-icons/gi";
import { GrDatabase } from "react-icons/gr";
import { LuLogOut } from "react-icons/lu";
import { FiMenu } from "react-icons/fi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaUserTie } from "react-icons/fa6";

const Lefbar = ({ handleLogout, toggleSidebar, isSidebarCollapsed }) => {

  const [openSubMenu, setOpenSubMenu] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(false);

  const handleClick = () => {
    setIsOpened(!isOpened);
    toggleSidebar();
  };

  const handleSubMenuClick = (menuName) => {
    setOpenSubMenu((prevOpenSubMenu) =>
      prevOpenSubMenu === menuName ? null : menuName
    );
  };

  const handleItemClick = (MenuItem) => {
    if (isSidebarCollapsed) {
      setOpenSubMenu(MenuItem);
    }
  };

  // console.log("openSubMenu+++++",openSubMenu)

  useEffect(() => {
    let pageTitle = "Daqi";
    const path = location.pathname;
    if (path === "/") {
      history.push("/");
      setOpenSubMenu("Users");
      pageTitle = "Home";
    } else if (
      path.startsWith("/DisbursementAndRepaymentSummary") ||
      path.startsWith("/OnboardingStatusSummary") ||
      path.startsWith("/OrderSummary") ||
      path.startsWith("/OrderStatus")
    ) {
      setOpenSubMenu("Dashboard");
    }
    else if (
      path.startsWith("/OrderList") ||
      path.startsWith("/InvoiceList")
    ) {
      setOpenSubMenu("Orders");
    } else if (
      path.startsWith("/Financier") ||
      path.startsWith("/DistributorBrandList") ||
      path.startsWith("/ProductCategory") ||
      path.startsWith("/ProductSubCategory") ||
      path.startsWith("/Size") ||
      path.startsWith("/Color") ||
      path.startsWith("/CouponList")
    ) {
      setOpenSubMenu("Masters");
    } else if (
      path.startsWith("/DisbursementFile") ||
      path.startsWith("/LoanTape") ||
      path.startsWith("/OrderSubMenuFile") ||
      path.startsWith("/OrderReport") ||
      path.startsWith("/MerchantReport")
    ) {
      setOpenSubMenu("Reports");
    } else if (
      path.startsWith("/Distributor") ||
      path.startsWith("/LeadDistributorList") ||
      path.startsWith("/LeadMerchantList") ||
      path.startsWith("/Electrician") ||
      path.startsWith("/EmployeeList")
    ) {
      setOpenSubMenu("Users");
    } else if (
      path.startsWith("/LoanRepayment") ||
      path.startsWith("/LoanAmountStatus") ||
      path.startsWith("/BulkDisbursement") ||
      path.startsWith("/RepaymentExport") ||
      path.startsWith("/FileUpload") || 
      path.startsWith("/ImportedFile") 
    ) {
      setOpenSubMenu("LMS");
    } else if (
      path.startsWith("/ProgramList") ||
      path.startsWith("/ProgramAddData") ||
      path.startsWith("/ProductAddData") ||
      path.startsWith("/ProductList") ||
      path.startsWith("/ProgramMaster")
    ) {
      setOpenSubMenu("Program");
    } else if (
      path.startsWith("/hr-tools/attendance") ||
      path.startsWith("/hr-tools/leave-requests")
    ) {
      setOpenSubMenu("HR_TOOLS");
    } else if (
      path.startsWith("/AddNotification") ||
      path.startsWith("/DocumentsDetails") ||
      path.startsWith("/shopRewards") ||
      path.startsWith("/MerchantSettlements") ||
      path.startsWith("/SMSQuotation")
    ) {
      setOpenSubMenu("Miscllaneous");
    } else {
      setOpenSubMenu("");
    }

    // Default title
    if (path.startsWith("/Distributor")) {
      pageTitle = "Distributor";
    } else if (path.startsWith("/LeadDistributorList")) {
      pageTitle = "Lead Distributor List";
    } else if (path.startsWith("/LeadMerchantList")) {
      pageTitle = "Lead Merchant List";
    } else if (path.startsWith("/Electrician")) {
      pageTitle = "Electrician";
    } else if (path.startsWith("/EmployeeList")) {
      pageTitle = "Employee List";
    } else if (path.startsWith("/DisbursementAndRepaymentSummary")) {
      pageTitle = "Disbursement And Repayment Summary";
    } else if (path.startsWith("/OnboardingStatusSummary")) {
      pageTitle = "Onboarding Status Summary";
    } else if (path.startsWith("/OrderSummary")) {
      pageTitle = "Order Summary";
    }else if (path.startsWith("/OrderStatus")) {
      pageTitle = "Order Status";
    } else if (path.startsWith("/OrderList")) {
      pageTitle = "Order List";
    } else if (path.startsWith("/InvoiceList")) {
      pageTitle = "Invoice";
    } else if (path.startsWith("/Financier")) {
      pageTitle = "Financier";
    } else if (path.startsWith("/DistributorBrandList")) {
      pageTitle = "Brands";
    } else if (path.startsWith("/ProductCategory")) {
      pageTitle = "Product Category";
    } else if (path.startsWith("/ProductSubCategory")) {
      pageTitle = "Product Sub Category";
    } else if (path.startsWith("/Size")) {
      pageTitle = "Size";
    } else if (path.startsWith("/Color")) {
      pageTitle = "Color";
    }else if (path.startsWith("/CouponList")) {
      pageTitle = "Coupon";
    } else if (path.startsWith("/DisbursementFile")) {
      pageTitle = "Disbursement";
    } else if (path.startsWith("/LoanTape")) {
      pageTitle = "Loan Tape";
    } else if (path.startsWith("/OrderSubMenuFile")) {
      pageTitle = "Orders";
    } else if (path.startsWith("/LoanAmountStatus")) {
      pageTitle = "Repayment";
    } else if (path.startsWith("/BulkDisbursement")) {
      pageTitle = "Bulk Disbursement";
    } else if (path.startsWith("/ProgramList")) {
      pageTitle = "Program List";
    } else if (path.startsWith("/ProgramMaster")) {
      pageTitle = "Program Master";
    } else if (path.startsWith("/ProgramMasterList")) {
      pageTitle = "Program Master List";
    } else if (path.startsWith("/ProgramAddData")) {
      pageTitle = "Add Program";
    } else if (path.startsWith("/ProductAddData")) {
      pageTitle = "Add Product";
    } else if (path.startsWith("/ProductList")) {
      pageTitle = "Product List";
    } else if (path.startsWith("/AddNotification")) {
      pageTitle = "Notification";
    } else if (path.startsWith("/DocumentsDetails")) {
      pageTitle = "Documents Details";
    } else if (path.startsWith("/MerchantSettlements")) {
      pageTitle = "Merchant Settlements";
    } else if (path.startsWith("/SMSQuotation")) {
      pageTitle = "SMS Quotation";
    } else if (path.startsWith("/CreditLimit")) {
      pageTitle = "Credits";
    } else if (path.startsWith("/LoanRepayment")) {
      pageTitle = "Repayment Details";
    }
    else if (path.startsWith('/RepaymentExport')) {
      pageTitle = 'Repayment Export';
    }
    else if (path.startsWith('/FileUpload')) {
      pageTitle = 'FileUpload';
    }
    else if (path.startsWith('/ImportedFile')) {
      pageTitle = 'ImportedFile';
    }
    else if (path.startsWith('/hr-tools/attendance')) {
      pageTitle = 'Attendance';
    }
    else if (path.startsWith('/hr-tools/leave-requests')) {
      pageTitle = 'Leave Requests';
    }
    else if (path.startsWith('/OrderReport')) {
      pageTitle = 'Order Report';
    }
    else if (path.startsWith('/MerchantReport')) {
      pageTitle = 'Merchant Report';
    }
    document.title = `${pageTitle} - Daqi Platform`;
  }, [location.pathname, history]);


  useEffect(() => {
    const path = location.pathname;
    if (path === "/") {
      history.push("/");
      setOpenSubMenu("Users");
    } else if (
      path.startsWith("/OisbursementAndRepaymentSummary") ||
      path.startsWith("/OnboardingStatusSummary") ||
      path.startsWith("/OrderSummary") ||
      path.startsWith("/OrderStatus")
    ) {
      setOpenSubMenu("Dashboard");
    } else if (
      path.startsWith("/OrderList") ||
      path.startsWith("/InvoiceList")
    ) {
      setOpenSubMenu("Orders");
    } else if (
      path.startsWith("/Financier") ||
      path.startsWith("/DistributorBrandList") ||
      path.startsWith("/ProductCategory") ||
      path.startsWith("/ProductSubCategory") ||
      path.startsWith("/Size") ||
      path.startsWith("/Color") ||
      path.startsWith("/CouponList")
    ) {
      setOpenSubMenu("Masters");
    } else if (
      path.startsWith("/DisbursementFile") ||
      path.startsWith("/LoanTape") || 
      path.startsWith("/OrderSubMenuFile") ||
      path.startsWith("/FosVisit") ||
      path.startsWith("/RevenueFile")
    ) {
      setOpenSubMenu("Reports");
    } else if (
      path.startsWith("/Distributor") ||
      path.startsWith("/LeadDistributorList") ||
      path.startsWith("/LeadMerchantList") ||
      path.startsWith("/Electrician") ||
      path.startsWith("/EmployeeList")
      
    ) {
      setOpenSubMenu("Users");
    } else if (
      path.startsWith("/RepaymentExport") ||
      path.startsWith("/LoanAmountStatus") ||
      path.startsWith("/BulkDisbursement") ||
      path.startsWith("/FileUpload") || 
      path.startsWith("/ImportedFile") 
    ) {
      setOpenSubMenu("LMS");
    } else if (
      path.startsWith("/ProgramList") ||
      path.startsWith("/ProgramAddData") ||
      path.startsWith("/ProductAddData") ||
      path.startsWith("/ProductList") ||
      path.startsWith("/ProgramMaster") ||
      path.startsWith("/ProgramMasterList")
    ) {
      setOpenSubMenu("Program");
    } else if (
      path.startsWith("/hr-tools/attendance") ||
      path.startsWith("/hr-tools/leave-requests")
    ) {
      setOpenSubMenu("HR_TOOLS");
    } else if (
      path.startsWith("/AddNotification") ||
      path.startsWith("/DocumentsDetails") ||
      path.startsWith("/shopRewards") ||
      path.startsWith("/MerchantSettlements") ||
      path.startsWith("/SMSQuotation")
    ) {
      setOpenSubMenu("Miscllaneous");
    }
 else {
      setOpenSubMenu("");
    }
  }, [location.pathname, history]);

  const isActive = (path) => {
    return location.pathname === path;
  };

  const activeStyle = {
    backgroundColor: "#f7674c",
  };
  return (
    <div className="sidebarMainMenu">
      <Sidebar collapsed={isSidebarCollapsed}>
        <div className="hamburgerMenuMain">
          <button
            className={`menu ${isOpened ? 'opened' : ''}`}
            onClick={handleClick}
            aria-label="Main Menu"
          >
            <svg width={35} height={35} viewBox="0 0 100 100">
              <path
                className="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              />
              <path className="line line2" d="M 20,50 H 80" />
              <path
                className="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              />
            </svg>
          </button> 
        </div>

        <div className="logo">
          <img src="../assets/img/Daqi_logo.png" />
          <h1
            style={{
              color: "#fff",
              fontWeight: "bold",
              marginBottom: 4,
              fontSize: "30px",
              marginLeft: 20,
            }}
          >
            DAQI
          </h1>
        </div>
        <Menu>
          <SubMenu
            icon={<FaHome />}
            label="Dashboard"
            open={openSubMenu === "Dashboard"}
            onClick={() => handleSubMenuClick("Dashboard")}
          >
            <MenuItem
              component={<Link to="/DisbursementAndRepaymentSummary" />}
              style={isActive("/DisbursementAndRepaymentSummary") ? activeStyle : {}}
            >
              &#9702; &nbsp;Disbursement And Repayment Summary
            </MenuItem>
            <MenuItem
              component={<Link to="/OnboardingStatusSummary" />}
              style={isActive("/OnboardingStatusSummary") ? activeStyle : {}}
            >
              &#9702; &nbsp;Onboarding Status Summary
            </MenuItem>
            <MenuItem
              component={<Link to="/OrderSummary" />}
              style={isActive("/OrderSummary") ? activeStyle : {}}
            >
              &#9702; &nbsp;Order Summary
            </MenuItem>
            <MenuItem
              component={<Link to="/OrderStatus" />}
              style={isActive("/OrderStatus") ? activeStyle : {}}
            >
              &#9702; &nbsp;Order Status
            </MenuItem>
          </SubMenu>
          <SubMenu
            icon={<FaList />}
            label="Users"
            open={openSubMenu === "Users"}
            onClick={() => handleSubMenuClick("Users")}
          >
            <MenuItem
              component={<Link to="/" />}
              style={isActive("/") ? activeStyle : {}}
              onClick={()=>handleItemClick('Users')}
            >
              &#9702; &nbsp;Merchant
            </MenuItem>
            <MenuItem
              component={<Link to="/Distributor" />}
              style={isActive("/Distributor") ? activeStyle : {}}
            >
              &#9702; &nbsp; Distributor
            </MenuItem>
            <MenuItem
              component={<Link to="/Electrician" />}
              style={isActive("/Electrician") ? activeStyle : {}}
            >
              &#9702; &nbsp; Electrician
            </MenuItem>
            <MenuItem
              component={<Link to="/LeadDistributorList" />}
              style={isActive("/LeadDistributorList") ? activeStyle : {}}
            >
              &#9702; &nbsp; Lead Distributor
            </MenuItem>
            <MenuItem
              component={<Link to="/LeadMerchantList" />}
              style={isActive("/LeadMerchantList") ? activeStyle : {}}
            >
              &#9702; &nbsp; Lead Merchant
            </MenuItem>
            <MenuItem
              component={<Link to="/EmployeeList" />}
              style={isActive("/EmployeeList") ? activeStyle : {}}
            >
              &#9702; &nbsp; Employees
            </MenuItem>
          </SubMenu>
         
          <SubMenu
            icon={<FaFileInvoiceDollar />}
            label="Orders"
            open={openSubMenu === "Orders"}
            onClick={() => handleSubMenuClick("Orders")}
          >
            <MenuItem
              component={<Link to="/OrderList" />}
              style={isActive("/OrderList") ? activeStyle : {}}
            >
              &#9702; &nbsp;Order List
            </MenuItem>
            <MenuItem
              component={<Link to="/InvoiceList" />}
              style={isActive("/InvoiceList") ? activeStyle : {}}
            >
              &#9702; &nbsp;Fund My Invoice
            </MenuItem>
          </SubMenu>
          <SubMenu
            label="Masters"
            icon={<FaFileAlt />}
            open={openSubMenu === "Masters"}
            onClick={() => handleSubMenuClick("Masters")}
          >
            <MenuItem
              component={<Link to="/Financier" />}
              style={isActive("/Financier") ? activeStyle : {}}
            >
              &#9702; &nbsp; Financier
            </MenuItem>
            <MenuItem
              component={<Link to="/DistributorBrandList" />}
              style={isActive("/DistributorBrandList") ? activeStyle : {}}
            >
              &#9702; &nbsp;Brands
            </MenuItem>
            <MenuItem
              component={<Link to="/ProductCategory" />}
              style={isActive("/ProductCategory") ? activeStyle : {}}
            >
              &#9702; &nbsp;Product Category
            </MenuItem>
            <MenuItem
              component={<Link to="/ProductSubCategory" />}
              style={isActive("/ProductSubCategory") ? activeStyle : {}}
            >
              &#9702; &nbsp;Product Sub Category
            </MenuItem>
             <MenuItem
              component={<Link to="/CouponList" />}
              style={isActive("/CouponList") ? activeStyle : {}}
            >
              &#9702; &nbsp;Coupon
            </MenuItem>
            <MenuItem
              component={<Link to="/Size" />}
              style={isActive("/Size") ? activeStyle : {}}
            >
              &#9702; &nbsp;Size
            </MenuItem><MenuItem
              component={<Link to="/Color" />}
              style={isActive("/Color") ? activeStyle : {}}
            >
              &#9702; &nbsp;Color
            </MenuItem>
          </SubMenu>
          <SubMenu
            label="Reports"
            icon={<FaFileInvoice />}
            open={openSubMenu === "Reports"}
            onClick={() => handleSubMenuClick("Reports")}
          >
            <MenuItem
              component={<Link to="/DisbursementFile" />}
              style={isActive("/DisbursementFile") ? activeStyle : {}}
            >
              &#9702; &nbsp;Disbursement
            </MenuItem>
            <MenuItem
              component={<Link to="/LoanTape" />}
              style={isActive("/LoanTape") ? activeStyle : {}}
            >
              &#9702; &nbsp;Loan Tape
            </MenuItem>
            <MenuItem
              component={<Link to="/QcReport" />}
              style={isActive("/QcReport") ? activeStyle : {}}
            >
              &#9702; &nbsp;QC Report
            </MenuItem>
            <MenuItem
              component={<Link to="/OrderSubMenuFile" />}
              style={isActive("/OrderSubMenuFile") ? activeStyle : {}}
            >
              &#9702; &nbsp;Orders
            </MenuItem>  
            <MenuItem
              component={<Link to="/FosVisit" />}
              style={isActive("/FosVisit") ? activeStyle : {}}
            >
              &#9702; &nbsp;Visit
            </MenuItem>
           <MenuItem
              component={<Link to="/RevenueFile" />}
              style={isActive("/RevenueFile") ? activeStyle : {}}
            >
              &#9702; &nbsp;Revenue File
            </MenuItem>
          </SubMenu>
          <SubMenu
            label="LMS"
            icon={<GiMoneyStack />}
            open={openSubMenu === "LMS"}
            onClick={() => handleSubMenuClick("LMS")}
          >
            {/* <MenuItem
              component={<Link to="/LoanRepayment" />}
              style={isActive("/LoanRepayment") ? activeStyle : {}}
            >
              &#9702; &nbsp;Repayment Details
            </MenuItem> */}
            <MenuItem
              component={<Link to="/BulkDisbursement" />}
              style={isActive("/BulkDisbursement") ? activeStyle : {}}
            >
              &#9702; &nbsp;Bulk Disbursement
            </MenuItem>
            <MenuItem
              component={<Link to="/LoanAmountStatus" />}
              style={isActive("/LoanAmountStatus") ? activeStyle : {}}
            >
              &#9702; &nbsp;Repayment
            </MenuItem>

            <MenuItem
              component={<Link to="/RepaymentExport" />}
              style={isActive('/RepaymentExport') ? activeStyle : {}}
            >
              &#9702; &nbsp;Repayment Export 
            </MenuItem>
          </SubMenu>
          <SubMenu
            label="Program"
            icon={<TbDeviceDesktopSearch />}
            open={openSubMenu === "Program"}
            onClick={() => handleSubMenuClick("Program")}
          >
            {/* <MenuItem
              component={<Link to="/ProgramMasterList" />}
              style={isActive("/ProgramMasterList") ? activeStyle : {}}
            >
              &#9702; &nbsp;Program Master
            </MenuItem> */}
            <MenuItem
              component={<Link to="/ProgramList" />}
              style={isActive("/ProgramList") ? activeStyle : {}}
            >
              &#9702; &nbsp;Program List
            </MenuItem>
            <MenuItem
              component={<Link to="/ProgramAddData" />}
              style={isActive("/ProgramAddData") ? activeStyle : {}}
            >
              &#9702; &nbsp;Add Program
            </MenuItem>
            <MenuItem
              component={<Link to="/ProductList" />}
              style={isActive("/ProductList") ? activeStyle : {}}
            >
              &#9702; &nbsp;Product List
            </MenuItem> 
            <MenuItem
              component={<Link to="/ProductAddData" />}
              style={isActive("/ProductAddData") ? activeStyle : {}}
            >
              &#9702; &nbsp;Add Product
            </MenuItem>
          </SubMenu>
          <SubMenu
            label="HR Tools"
            icon={<FaUserCog />}
            open={openSubMenu === "HR_TOOLS"}
            onClick={() => handleSubMenuClick("HR_TOOLS")}
          >
            <MenuItem
              component={<Link to="/hr-tools/attendance" />}
              style={isActive("/hr-tools/attendance") ? activeStyle : {}}
            >
              &#9702; &nbsp;Attendance
            </MenuItem>
            <MenuItem
              component={<Link to="/hr-tools/leave-requests" />}
              style={isActive("/hr-tools/leave-requests") ? activeStyle : {}}
            >
              &#9702; &nbsp;Leave Requests
            </MenuItem>
          </SubMenu>
          <SubMenu
            label="Miscllaneous"
            icon={<FaFileAlt />}
            open={openSubMenu === "Miscllaneous"}
            onClick={() => handleSubMenuClick("Miscllaneous")}
          >
            <MenuItem
              component={<Link to="/AddNotification" />}
              style={isActive("/AddNotification") ? activeStyle : {}}
            >
              &#9702; &nbsp;Notification
            </MenuItem>
            {/* <MenuItem
              component={<Link to="/DocumentsDetails" />}
              style={isActive("/DocumentsDetails") ? activeStyle : {}}
            >
              &#9702; &nbsp;Documents
            </MenuItem> */}
            {/*<MenuItem
              component={<Link to="/shopRewards" />}
              style={isActive("/shopRewards") ? activeStyle : {}}
            >
              &#9702; &nbsp; Shop Rewards
            </MenuItem>
            <MenuItem
              component={<Link to="/MerchantSettlements" />}
              style={isActive("/MerchantSettlements") ? activeStyle : {}}
            >
              &#9702; &nbsp;Merchant Settlements
            </MenuItem>*/}
            {/* <MenuItem
              component={<Link to="/SMSQuotation" />}
              style={isActive("/SMSQuotation") ? activeStyle : {}}
            >
              &#9702; &nbsp;SMSQuotation
            </MenuItem> */}
          </SubMenu>

          <MenuItem
            component={<Link to="/CreditLimit" />}
            style={isActive("/CreditLimit") ? activeStyle : {}}
            icon={<FaCreditCard />}
          >
            &nbsp;Credits
          </MenuItem>
          <MenuItem
            component={<Link to="/login" />}
            style={isActive("/login") ? activeStyle : {}}
            icon={<LuLogOut />}
            onClick={handleLogout}
          >
            Logout
          </MenuItem>
        </Menu>


      </Sidebar>
    // </div>
  );
};

export default Lefbar;
