import React, { useState, useMemo, useRef, useEffect } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import Loader from "../component/Loader";
import "../css/makeTable.css";
import { getData, postData } from "../store/utils";
import Swal from "sweetalert2";
import { FaSave, FaFilePdf } from "react-icons/fa";
import { FaDownload } from "react-icons/fa6";
import { useHistory } from "react-router-dom";
import {
  formatDateString,
  formatNumberWithCommasAndDecimals,
} from "../component/common";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import Select from "react-select";
import { FaFileInvoice } from "react-icons/fa";

const FundInvoiceList = () => {
  const userRole = localStorage.getItem("USER_ROLE");
  const [invoiceData, setInvoiceData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [merchantUserData, setMerchantUserData] = useState();

  const history = useHistory();
  const inputRef = useRef();
  const getLeadDistributorList = async () => {
    setIsLoadingOn(true);
    const tempData = await getData(
      "invoice/getInvoices?referenceTable=MERCHANT"
    );
    console.log("ActualData", tempData);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setInvoiceData(tempData.data.records);
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors.msg || "Something went wrong!!!",
      });
    }
  };
  useEffect(() => {
    getLeadDistributorList();
  }, []);

  const handleInsertLoan = async (row) => {
    const invoiceAmount = Number(row.amount);
    const gstRate = 18;
    const invoiceAmountWithoutGst =
      invoiceAmount / (1 + gstRate / 100).toFixed(2);

    const filterData = {
      userId: row.userId,
      merchantInvoiceId: row.id,
      distributorId: row.distributorId,
      invoiceAmount: invoiceAmountWithoutGst,
      invoiceAmountWithGst: invoiceAmount,
    };
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to Fund this Invoice Data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, insert it!",
      cancelButtonText: "No, cancel!",
    });

    try {
      if (result.isConfirmed) {
        setIsLoadingOn(true);
        const response = await postData(
          "invoice/insertLoanAndRepaymentAmount",
          filterData
        );
        setIsLoadingOn(false);
        if (response.statusCode == 200) {
          getLeadDistributorList();
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Data Inserted successfully!",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: response?.data?.errors?.msg || "Somthing went wrong",
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const gotoMerchantPage = (userId) => {
    const newTab = window.open("", "_blank");
    if (newTab) {
      newTab.location = "/merchantDetails?userId=" + userId;
    } else {
      history.push({
        pathname: "/merchantDetails",
        state: { userId: userId },
      });
    }
  };

  const displayMerchantInfo = (user) => {
    const newTab = window.open(
      "/FundInvoiceDetails?invoiceId=" +
        user.id +
        "&distributorId=" +
        user.distributorId +
        "&referenceId=" +
        user.referenceId,
      "_blank"
    );
    if (!newTab) {
      history.push({
        pathname: "/FundInvoiceDetails",
        state: {
          invoiceId: user.id,
          referenceId: user.referenceId,
          distributorId: user.distributorId,
        },
      });
    }
  };
  const resetFilters = () => {
    inputRef.current.value = "";
    setMerchantRefrenceType("");
    setSearchBoxValue("");
    getLeadDistributorList();
    setSelectedOptionStatus(null);
  };
  const searchManualData = async () => {
    let filterData = {};

    if (merchantRefrenceType === "Invoice Id") {
      filterData = {
        invoiceId: searchBoxValue,
      };
    } else if (merchantRefrenceType === "Distributor Id") {
      filterData = {
        distributorId: searchBoxValue,
      };
    } else if (merchantRefrenceType === "Distributor Name") {
      filterData = {
        distributorName: searchBoxValue,
      };
    } else if (merchantRefrenceType === "Shop Name") {
      filterData = {
        shopName: searchBoxValue,
      };
    } else {
      alert("Please select any one option");
    }

    setIsLoadingOn(true);

    const queryParams = new URLSearchParams(filterData).toString();

    const tempData = await getData(
      `invoice/getInvoices?referenceTable=MERCHANT&${queryParams}`
    );

    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setInvoiceData(tempData.data.records);
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData.data.errors.msg || "Something went wrong",
      });
    }
  };
  const handleInputChange = (e) => {
    if (merchantRefrenceType === "Distributor Id" || "Invoice Id") {
      const value = e.target.value.replace(/[^0-5]/g, "").slice(0, 10);
      setSearchBoxValue(value);
    } else {
      setSearchBoxValue(e.target.value);
    }
  };

  const formatter = new Intl.NumberFormat("en-IN");

  const merchantOption = [
    { value: "Invoice Id", label: "Invoice Id" },
    { value: "Distributor Id", label: "Distributor Id" },
    { value: "Distributor Name", label: "Distributor Name" },
    { value: "Shop Name", label: "Shop Name" },
  ];
  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
            {cell.row.index + 1}.
          </span>
        ),
      },
      {
        Header: "Invoice ID",
        accessor: "id",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="loanIdDownloadBtnDiv">
                <button
                  className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                  title="View details"
                  value={cell.row.original.id}
                  onClick={() => {
                    if (userRole === "Admin" || userRole === "Operation") {
                      displayMerchantInfo(cell.row.original);
                    }
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {cell.row.original.id}
                  </span>
                </button>
              </div>
            </>
          );
        },
      },
      {
        Header: "Merchant ID",
        accessor: "userId",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="loanIdDownloadBtnDiv">
                <button
                  className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                  title="View details"
                  value={cell.row.original.userId}
                  onClick={() => gotoMerchantPage(cell.row.original.userId)}
                >
                  <span style={{ fontWeight: "600" }}>
                    {cell.row.original.userId}
                  </span>
                </button>
              </div>
            </>
          );
        },
      },
      {
        Header: "Distributor ID",
        accessor: "distributorId",
      },
      {
        Header: "Distributor Name",
        accessor: "distributorName",
      },
      {
        Header: "Shop Name",
        accessor: "shopName",
      },
      {
        Header: "Invoice Number",
        accessor: "invoiceNumber",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => {
          const numericValue = Number(value);
          if (!isNaN(numericValue)) {
            return formatNumberWithCommasAndDecimals(numericValue);
          } else {
            return value || "-";
          }
        },
      },
      {
        Header: "Invoice Date",
        accessor: "invoiceDate",
        Cell: ({ cell }) => {
          const invoiceDate = cell.row.original.invoiceDate;
          return <span>{invoiceDate && formatDateString(invoiceDate)}</span>;
        },
      },
      {
        Header: "Created At Date",
        accessor: "createdDate",
        Cell: ({ cell }) => {
          const createdDate = cell.row.original.createdDate;
          return <span>{createdDate && formatDateString(createdDate)}</span>;
        },
      },
      {
        Header: "Disbursement Approval",
        accessor: "isDisbursementApproved",
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original.isDisbursementApproved
                ? cell.row.original.isDisbursementApproved
                : "Pending"}
            </>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => {
          const status = row.original.status;
          // console.log("row.original.agreement+++",row.original)
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {row.original.agreement && (
                <button
                  title="Agreement"
                  style={{
                    fontSize: 22,
                    color: "#dc3545",
                    border: "none",
                    background: "none",
                    marginRight: "10px",
                  }}
                  onClick={() => window.open(row.original.agreement, "_blank")}
                >
                  <IoDocumentTextOutline />
                </button>
              )}

              {row.original.proformaInvoice && (
                <button
                  title="Proforma Invoice"
                  style={{
                    fontSize: 22,
                    color: "#dc3545",
                    border: "none",
                    background: "none",
                    marginRight: "10px",
                  }}
                  onClick={() =>
                    window.open(row.original.proformaInvoice, "_blank")
                  }
                >
                  <FaFilePdf />
                </button>
              )}
              {row.original.invoicePath && (
                <button
                  title="Invoice"
                  style={{
                    fontSize: 22,
                    color: "#dc3545",
                    border: "none",
                    background: "none",
                    marginRight: "10px",
                  }}
                  onClick={() =>
                    window.open(row.original.invoicePath, "_blank")
                  }
                >
                  <FaFilePdf />
                </button>
              )}
              {status !== 5 &&
                (!row.original.agreement ||
                  (status === 4 &&
                    row.original.orderedBy === "DISTRIBUTOR")) && (
                  <button
                    style={{
                      fontSize: 22,
                      color: "#198754",
                      border: "none",
                      background: "none",
                      marginRight: "10px",
                    }}
                    title="Fund this invoice"
                    onClick={() => handleInsertLoan(row.original)}
                  >
                    <FaSave />
                  </button>
                )}

              {row.original.camFile && (
                <button
                  title="CAM File"
                  style={{
                    fontSize: 22,
                    color: "#226eb3",
                    border: "none",
                    background: "none",
                    marginRight: "10px",
                  }}
                  onClick={() => window.open(row.original.camFile, "_blank")}
                >
                  <FaFileInvoice />
                </button>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">Invoice List</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-3">
              <Select
                placeholder="Select Search"
                name="role"
                options={merchantOption}
                value={selectedOptionStatus}
                onChange={(e) => {
                  setMerchantRefrenceType(e.value);
                  setSelectedOptionStatus(e);
                }}
              />
            </div>
            <div className="col-md-3">
              <input
                type={
                  merchantRefrenceType === "Invoice Id" || "Distributor Id"
                    ? "tel"
                    : "text"
                }
                className="searchInputField"
                ref={inputRef}
                onChange={(e) => {
                  setSearchBoxValue(e.target.value);
                }}
                // onChange={handleInputChange}
                placeholder="Enter Details"
                value={searchBoxValue}
              />
            </div>
            <div className="col-md-2 text-center">
              <button className="searchTableData" onClick={searchManualData}>
                <FaSearch /> Search
              </button>
            </div>

            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="orderListDataFooter">
        {invoiceData && <Table columns={columns} data={invoiceData} />}
      </div>
    </>
  );
};

export default FundInvoiceList;

function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  return (
    <>
      <div>
        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot className="orderTableFooter">
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined);
        }}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}
