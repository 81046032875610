import React, { useState, useMemo, useEffect } from "react";
import Table from "../pages/ReactTable"
import Loader from "../component/Loader";
import "../css/makeTable.css";
import { getData } from "../store/utils";
import Swal from "sweetalert2";
import { FaFilePdf, FaRegEye } from 'react-icons/fa';
import { useLocation } from "react-router-dom/cjs/react-router-dom.js";

const FileUploadView = () => {
  const [fileData, setFileData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState(""); 
  const [orderBy, setOrderBy] = useState("");

  const location = useLocation();

  // Check if the current path contains "BulkDisbursement"
  const isBulkDisbursement = location.pathname.includes("BulkDisbursement");
  const oprationUrl = isBulkDisbursement ? "operation=disbursement" : "operation=repayment"

  const getFileUploadList = async (page = 0, pageSize = 10, sortBy = "", orderBy = "") => {
    setIsLoadingOn(true);

    let apiUrl = `filesUpload?page=${page + 1}&pageSize=${pageSize}&${oprationUrl}`;

    if (sortBy && orderBy) {
      apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
    }
    const tempData = await getData(apiUrl);
    console.log("ActualData", tempData);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
        setErrorMessage("");
        if(isBulkDisbursement){
          const filteredData = tempData.data.records?.filter(row => row?.operation === "DISBURSEMENT");
          console.log(filteredData.length, "filteredDatafilteredData")
          setFileData(filteredData);
          setPageCount(tempData.data.pagination.totalPagesCount);
          setTotalRecords(filteredData.length);
        }else{
          const filteredData = tempData.data.records?.filter(row => row?.operation === "REPAYMENT");
          setPageCount(tempData.data.pagination.totalPagesCount);
          setTotalRecords(filteredData.length);
          setFileData(filteredData);
        }
        
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong!!!"
      })
    }
  };


  const redirectToFileUploadPage = (fileUploadId, oprationType) => {
     window.open(`/ImportedFile?fileUploadId=${fileUploadId}&operation=${oprationType}`, "_blank");
  };


  const handleGotoPage = (page) => {
    if (page < 0 || page >= pageCount) {
        setErrorMessage(`No data to display`);
    } else {
        setPageIndex(page);
    }
  };

  const handleSort = (column) => {
    let newOrderBy = "asc";
  
  if (sortBy === column.id) {
    newOrderBy = orderBy === "asc" ? "desc" : "asc";
  }
  setSortBy(column.id);
  setOrderBy(newOrderBy);
  getFileUploadList(pageIndex, pageSize, column.id, newOrderBy);
  };
  
  useEffect(() => {
    getFileUploadList(pageIndex, pageSize, sortBy, orderBy);
  }, [pageIndex, pageSize, sortBy, orderBy]);

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1 + pageIndex * pageSize}.</span>
        ),
      },
      {
        Header: "File Path",
        accessor: "filePath",
        Cell: ({ cell }) => {
            const filePath = cell.row.original.filePath;
            const fileName = filePath.split('/').pop(); 
    
            return (
                <div className="loanIdDownloadBtnDiv">
                    <a 
                        href={filePath} 
                        download={fileName} 
                        style={{ fontWeight: "600", textDecoration: "none", color: "#007bff" }} 
                    >
                        <center><FaFilePdf style={{color:"red",fontSize:25}}/></center>
                    </a>
                </div>
            );
        },
      },
      {
        Header: "Operation",
        accessor: "operation"
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Total Records",
        accessor: "totalRecords",
      },
      {
        Header: "Total Success Records",
        accessor: "totalSuccessRecords",
      },
      {
        Header: "Total Failed Records",
        accessor: "totalFailedRecords",
      },
      {
        Header:"Action",
        accessor:"",
        Cell: ({ cell }) => {
          const fileUploadId = cell.row.original.id;
          const oprationType = cell.row.original.operation
          function capitalizeFirstLetter(str) {
            if (!str) return ''; // Handle empty or null strings
            str = str.toLowerCase(); // Convert the whole string to lowercase
            return str.charAt(0).toUpperCase() + str.slice(1);
          }
          const oprationTypeStr = capitalizeFirstLetter(oprationType);

          return (
              <>
                 <button
                 style={{ fontSize: 22, color: "#f56f49", border: "none", background: "none" }}
                 onClick={()=>redirectToFileUploadPage(fileUploadId, oprationTypeStr)}
                 ><FaRegEye /></button>
              </>
          );
      },
      }
    ],
    [pageIndex,pageSize]
  );

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">File Upload List</h3>
      {/* <button className="searchTableData" style={{float:"right",marginTop:"-35px",marginBottom:"10px",marginLeft:"20px"}}
        onClick={()=>redirectToFileUploadPage()}
      >View</button> */}
      <div className="orderListDataFooter">
        {fileData && <Table 
        columns={columns} 
        data={fileData} 
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
        totalRecords={totalRecords}
        handleGotoPage={handleGotoPage}
        errorMessage={errorMessage} 
        handleSort={handleSort}
        sortBy={sortBy}
        orderBy={orderBy}
        />}
      </div>
    </>
  );
};

export default FileUploadView;



